import {
  Box,
  CardMedia,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import ScrollToTopOnRoute from "../components/ScrollToTopOnRoute";
import officeVideo from "../videos/office-video.mp4";

const LidoBanner = (props) => {
  const { image, height, width, text, subText, top, bgPosition } = props;
  const baseHeight = 340;

  return (
    <>
      <Grid
        height={height || baseHeight}
        position="relative"
        zIndex={0}
        top={top || 0}
      >
        <ScrollToTopOnRoute />
        <Box
          position="absolute"
          display="flex"
          justifyContent="center"
          flexDirection="column"
          width={width || "100%"}
          height={height || "340px"}
          zIndex={1}
          sx={{
            backgroundImage:
              "linear-gradient(1.55deg, rgba(141, 31, 31, 0.46) 10.62%, rgba(0, 0, 0, 0.1) 90.61%)",
          }}
        >
          <Container>
            <Box display="flex" justifyContent="center">
              <Stack>
                <Typography
                  color="white"
                  variant="h1"
                  textTransform="uppercase"
                  sx={{
                    mb: subText ? "-0.5px" : 0,
                    borderRadius: "5px",
                    borderBottomRightRadius: subText ? "0px" : "5px",
                    borderBottomLeftRadius: subText ? "0px" : "5px",
                  }}
                >
                  {text && (
                    <span
                      style={{
                        textShadow: "0px 6px 6px rgba(0, 0, 0, 0.4)",
                        borderTopRightRadius: subText ? "0px" : "5px",
                        borderTopLeftRadius: subText ? "0px" : "5px",
                        borderBottomRightRadius: "5px",
                        borderBottomLeftRadius: subText ? "0px" : "5px",
                      }}
                    >
                      {text}
                    </span>
                  )}
                </Typography>
                {subText && (
                  <Typography
                    color="white"
                    variant="h5"
                    sx={{
                      pl: 0.5,
                      borderRadius: "5px",
                      borderTopLeftRadius: "0px",
                      borderTopRightRadius: "0px",
                    }}
                  >
                    {subText}
                  </Typography>
                )}
              </Stack>
            </Box>
          </Container>
        </Box>
        <CardMedia
          image={image}
          sx={{
            height: height || "340px",
            objectFit: "none",
            position: "relative",
            backgroundPosition: bgPosition || "center",
          }}
        />
      </Grid>
    </>
  );
};

LidoBanner.propTypes = {
  image: PropTypes.any,
  height: PropTypes.number,
  width: PropTypes.number,
  text: PropTypes.string,
  subText: PropTypes.string,
  top: PropTypes.number,
};

export default LidoBanner;
