import {
  Box,
  Button,
  Typography,
  Dialog,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { rateEmployee, switchModal } from '../app/slices/property';
import { useDispatch, useSelector } from '../app/store';
import '../App.css';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Formik } from 'formik';
import AOCTextfield from '../utils/AOCTextfield';
import { PropTypes } from 'prop-types';

const RateModal = ({ employeeId }) => {
  const { t } = useTranslation();
  const { openModals } = useSelector((state) => state.property);
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleModalClose = () => {
    dispatch(switchModal('rateModal', false));
  };

  const handleReCaptchaVerify = useCallback(async (values) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const newToken = await executeRecaptcha('yourAction');
    const sendData = {
      ...values,
      googleVerificationToken: newToken,
    };
    // Do whatever you want with the token
    dispatch(rateEmployee(employeeId, sendData));
  }, [executeRecaptcha]);
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={handleModalClose}
      open={openModals.includes('rateModal')}
    >
      <Formik
        initialValues={{
          email: '',
          name: '',
          rating: '',
        }}
        validationSchema={Yup
          .object()
          .shape({
            name: Yup.string().required('Mező kitöltése kötelező'),
            email: Yup.string().required('Mező kitöltése kötelező').email('Az e-mail cím formátuma nem megfelelő'),
            rating: Yup.string().required('Mező kitöltése kötelező'),
          })}
        onSubmit={(values) => handleReCaptchaVerify(values)}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ p: 3 }}>
              <Typography
                variant="h6"
                textAlign="left"
                fontWeight="bold"
                color="primary"
              >
                Fontos számunkra a véleményed.
              </Typography>
              <Typography
                variant="h7"
                mt={1}
              >
                Értékeld kollégánk munkáját, hogy tovább fejlődhessünk!
              </Typography>
              <AOCTextfield
                mt={2}
                onBlur={handleBlur}
                onChange={handleChange}
                values={values}
                touched={touched}
                errors={errors}
                name="name"
                label={t('Name')}
              />
              <AOCTextfield
                mt={2}
                onBlur={handleBlur}
                onChange={handleChange}
                values={values}
                touched={touched}
                errors={errors}
                name="email"
                label={t('Email')}
              />
              <AOCTextfield
                mt={2}
                rows={6}
                onBlur={handleBlur}
                onChange={handleChange}
                values={values}
                touched={touched}
                multiline
                errors={errors}
                name="rating"
                label={t('rating')}
              />
              <Box
                mt={2}
                display="flex"
                justifyContent="space-between"
              >
                <Button
                  variant="outlined"
                  onClick={handleModalClose}
                >
                  Bezárás
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  variant="contained"
                  type="submit"
                >
                  Küldés
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

RateModal.propTypes = {
  employeeId: PropTypes.string.isRequired,
};

export default RateModal;
