import {
  Box,
  Button,
  Container,
  Grid,
  Pagination,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "../app/store";
import PropertyCard from "./PropertyCard";
import { createSearchParams, Link } from "react-router-dom";
import { clearProperties, searchProperty } from "../app/slices/property";
import propertyBrowseBackground from "../images/propertyDetails/propertyBrowseBackground.png";

const PropertyBrowse = ({
  text,
  TextComponent,
  minusCard,
  searchParam,
  sellAndRent,
  noBackground,
}) => {
  const minCard = minusCard || 0;
  const breakPoints = { xl: 1600, lg: 1170, md: 768, sm: 576, xs: 0 };
  const { properties } = useSelector((state) => state.property);
  const smDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const getPageSize = () => {
    if (window.innerWidth > breakPoints.xl) {
      return 3 - minCard;
    }
    if (
      window.innerWidth < breakPoints.xl &&
      window.innerWidth > breakPoints.lg
    ) {
      return 3 - minCard;
    }
    if (
      window.innerWidth < breakPoints.lg &&
      window.innerWidth > breakPoints.md
    ) {
      return 2;
    }
    if (
      window.innerWidth < breakPoints.md &&
      window.innerWidth > breakPoints.sm
    ) {
      return 1;
    }

    return 1;
  };
  const [pageSize, setPageSize] = useState(getPageSize);
  const dispatch = useDispatch();
  const searchData = searchParam || {};
  const topRef = useRef(null);

  const forceScroll = () => {
    if (topRef !== null) {
      topRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  useEffect(() => {
    setPageSize(getPageSize);
  }, [window.innerWidth]);

  useEffect(() => {
    setPageSize(getPageSize);
    dispatch(searchProperty(searchData, 0, pageSize));
    return () => {
      dispatch(clearProperties()); // NOTE: this is needed to reset the properties state when i leave the page
    };
  }, []);
  const [propertyArray, setPropertyArray] = useState(
    properties?.data || [...Array(pageSize)]
  );
  const [page, setPage] = useState(1);
  useEffect(() => {
    setPropertyArray(properties?.data || [...Array(pageSize)]);
  }, [properties]);

  const handlePageChange = (_, nextPage) => {
    setPage(nextPage);
    dispatch(searchProperty(searchData, nextPage - 1, pageSize));
  };

  return (
    <>
      {properties?.totalPages > 0 && (
        <Grid
          display="flex"
          flexDirection="column"
          alignItems="center"
          mb={5}
          py={5}
          sx={
            !noBackground && {
              backgroundSize: "cover",
              backgroundImage: `url(${propertyBrowseBackground})`,
            }
          }
        >
          <Container>
            <Stack direction="row" flexWrap="wrap" gap={2}>
              {TextComponent ? (
                <>
                  <TextComponent>{text || "Ez is érdekelhet"}</TextComponent>
                </>
              ) : (
                <Typography variant="h6" fontWeight="bold" color="primary">
                  {text || "Ez is érdekelhet"}
                </Typography>
              )}
              {searchParam && !sellAndRent ? (
                <Button
                  component={Link}
                  to={`/properties?${createSearchParams(
                    searchParam
                  )}&pageNumber=1`}
                  target="_blank"
                  variant="outlined"
                >
                  Összes ingatlan
                </Button>
              ) : (
                <Stack direction="row" gap={2} flexWrap="wrap">
                  <Button
                    component={Link}
                    to={`/properties?${createSearchParams(
                      searchParam
                    )}&pageNumber=1&transactionType=1`}
                    target="_blank"
                    variant="outlined"
                  >
                    Összes eladó ingatlan
                  </Button>
                  <Button
                    component={Link}
                    to={`/properties?${createSearchParams(
                      searchParam
                    )}&pageNumber=1&transactionType=2`}
                    target="_blank"
                    variant="outlined"
                  >
                    Összes kiadó ingatlan
                  </Button>
                </Stack>
              )}
            </Stack>
            <Box
              minHeight={lgDown ? 600 : 550}
            >
              <Grid // TODO: responsiveness
                container
                mt={2}
                spacing={2}
                justifyContent="center"
              >
                {propertyArray.map((property, i) => (
                  <Grid item key={property?.id || i}>
                    <PropertyCard
                      mwidth={smDown ? "300px" : "390px"}
                      property={property}
                      loading={!properties.data}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: smDown ? 0 : 0,
              }}
              ref={topRef}
            >
              <Pagination
                count={properties?.totalPages}
                boundaryCount={0}
                page={page}
                showFirstButton={false}
                showLastButton={false}
                onChange={(e, nextPage) => {
                  handlePageChange(e, nextPage);
                  forceScroll();
                }}
              />
            </Box>
          </Container>
        </Grid>
      )}
    </>
  );
};

PropertyBrowse.propTypes = {
  text: PropTypes.string,
  TextComponent: PropTypes.func,
  minusCard: PropTypes.number,
  searchParam: PropTypes.object,
  sellAndRent: PropTypes.bool,
  noBackground: PropTypes.bool,
};

export default PropertyBrowse;
