import React, { Fragment } from 'react';
import { Box, IconButton, Toolbar, Grid, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Logo from '../Logo';
import DashboardNavItem from './DashboardNavItem';
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import { switchModal } from '../../app/slices/property';
import { useDispatch } from '../../app/store';
import useAuth from '../../hooks/useAuth';
import { toast } from 'react-hot-toast';

const DashboardNavbarToolbar = (props) => {
  const { onSidebarMobileOpen, leftNavigationData, rightNavigationData } = props;
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const { user } = useAuth();
  const handleModalOpen = () => {
    if (!user) {
      dispatch(switchModal('LoginModal', true));
    } else {
      toast.error('Már be vagy jelentkezve.');
    }
  };

  return (
    <Toolbar sx={{
      height: 80,
      borderBottom: '1px solid #962F32'
    }}
    >
      <IconButton
        onClick={onSidebarMobileOpen}
        sx={{
          display: {
            lg: 'none'
          }
        }}
      >
        <MenuIcon fontSize="small" />
      </IconButton>
      <Grid
        container
        display="flex"
        justifyContent="center"
      >
        <>
          <RouterLink to="/">
            <Logo sx={{
              mr: '20px',
              mt: 0.5,
              display: {
                lg: 'inline',
                xs: 'none'
              },
              height: 45,
              width: 150
            }}
            />
          </RouterLink>
          {leftNavigationData.map((sections) => (
            <Fragment key={sections}>
              {sections.items.map((item) => (
                <Fragment key={item.title}>
                  <Box sx={{
                    px: 1,
                    display: {
                      lg: 'flex',
                      xs: 'none'
                    }
                  }}
                  >
                    <DashboardNavItem navigationData={item} />
                  </Box>
                </Fragment>
              ))}
            </Fragment>
          ))}
          {rightNavigationData.map((sections) => (
            <Fragment key={sections}>
              {sections.items.map((item) => (
                <Fragment key={item.title}>
                  <Box sx={{
                    px: 1,
                    display: {
                      lg: 'flex',
                      xs: 'none'
                    }
                  }}
                  >
                    <DashboardNavItem navigationData={item} />
                  </Box>
                </Fragment>
              ))}
            </Fragment>
          ))}
        </>
        {user ? (
          <Button // TODO: go to profile
            component={RouterLink}
            to="/profile"
            sx={{
              ml: '8px',
              pr: '8px',
              py: '12px',
              textTransform: 'uppercase',
              display: {
                lg: 'flex',
                xs: 'none'
              }
            }}
          >
            {`${user.name} `}
            {t('profile')}
          </Button>
        ) : (
          <Button
            onClick={handleModalOpen}
            sx={{
              ml: '8px',
              pr: '8px',
              py: '12px',
              textTransform: 'uppercase',
              display: {
                lg: 'flex',
                xs: 'none'
              }
            }}
          >
            {t('navigation.signIn')}
          </Button>
        )}
        <RouterLink to="/">
          <Logo sx={{
            mr: '50px',
            display: {
              lg: 'none',
              xs: 'inline'
            },
            height: 45,
            width: 150
          }}
          />
        </RouterLink>
      </Grid>
    </Toolbar>
  );
};

DashboardNavbarToolbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
  leftNavigationData: PropTypes.array,
  rightNavigationData: PropTypes.array,
};

export default DashboardNavbarToolbar;
