import { combineReducers } from '@reduxjs/toolkit';
import loadingReducer from './slices/loading';
import { reducer as helperSliceReducer } from './slices/helperSlice';
import { reducer as webPresentationReducer } from './slices/webPresentation';
import { reducer as propertySliceReducer } from './slices/property';
import { reducer as blogSliceReducer } from './slices/blog';

const rootReducer = combineReducers({
  loading: loadingReducer,
  helperSlice: helperSliceReducer,
  webPresentation: webPresentationReducer,
  property: propertySliceReducer,
  blog: blogSliceReducer,
});

export default rootReducer;
