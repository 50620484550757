import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Card, CardMedia, Grid, Typography,
  Skeleton,
  Stack,
} from '@mui/material';
import NumberFormatCustom from '../utils/NumberFormatCustom';
import { Link } from 'react-router-dom';
import ImageIcon from '@mui/icons-material/Image';
import { useTranslation } from 'react-i18next';

const { REACT_APP_FILE_URL } = process.env;

const ProjectCard = (props) => {
  const { project, loading, mwidth } = props;
  const { t } = useTranslation();
  const pictureHeight = 250;
  const skeletonHeight = 16;

  return (
    <Grid
      component={Link}
      to={`/projects/details/${project?.id}`}
      style={{
        textDecoration: 'none',
      }}
    >
      <Card
        sx={{
          width: mwidth && mwidth,
          boxShadow: 'rgba(0, 0, 0, 0.20) 0px 10px 20px, rgba(0, 0, 0, 0.13) 0px 6px 6px',
          height: '100%',
          transition: 'all 0.2s ease-in-out',
          border: '2px transparent solid',
          '&:hover': {
            border: '2px solid #962F32'
          }
        }}
      >
        {!loading ? (
          <Grid>
            <Box>
              {project?.image && project?.image?.source?.thumb ? (
                <CardMedia
                  image={`${REACT_APP_FILE_URL}${project?.image?.source?.thumb}`}
                  style={{
                    backgroundColor: 'background.default',
                    height: pictureHeight,
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                  }}
                />
              ) : (
                <Box
                  sx={{
                    alignItems: 'center',
                    backgroundColor: 'background.default',
                    display: 'flex',
                    width: '100%',
                    border: 2,
                    borderColor: 'background.paper',
                    minWidth: 'auto',
                    minHeight: pictureHeight,
                    justifyContent: 'center',
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                  }}
                >
                  <ImageIcon
                    fontSize="medium"
                    color="action"

                  />
                </Box>
              )}
            </Box>
          </Grid>
        ) : (
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{
              m: 0.5,
              borderRadius: 1,
            }}
            height={pictureHeight}
          />
        )}
        <Box
          p={2}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            mb={3}
          >
            {!loading ? (
              <Stack>
                <Typography
                  fontWeight="bold"
                  variant="h6"
                >
                  {project?.title}
                </Typography>
                <Typography
                  variant="h7"
                >
                  {project?.address}
                </Typography>
              </Stack>
            ) : (
              <>
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  sx={{
                    borderRadius: 0.5,
                  }}
                  width={130}
                  height={skeletonHeight}
                />
              </>
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            gap={1}
          >
            {!loading ? (
              <>
                <Typography
                  variant="h7"
                  color="primary"
                  fontWeight="bold"
                >
                  {t('price')}
                  {': '}
                </Typography>
                {project && (
                  <Typography
                    variant="h7"
                  >
                    {project.minSalePrice && project.minSalePrice / 1000000 || ''}
                    {project.minSalePrice && '-'}
                    {project.maxSalePrice && project.maxSalePrice / 1000000 || ''}
                    {(project.minSalePrice || project.maxSalePrice) && ' Millió '}
                    {project?.currency && (project.minSalePrice || project.maxSalePrice) && 'Ft'}
                  </Typography>
                )}
              </>
            ) : (
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{
                  borderRadius: 0.5,
                }}
                width={330}
                height={skeletonHeight}
              />
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            mt={1}
          >
            {!loading ? (
              <>
                <Typography
                  variant="h7"
                  color="primary"
                  fontWeight="bold"
                >
                  {t('size')}
                  :
                </Typography>
                <Typography
                  variant="h7"
                >
                  {project?.minFloorArea || ''}
                  {project?.minFloorArea && '-'}
                  <NumberFormatCustom
                    value={project?.maxFloorArea || ''}
                    displayType="text"
                    suffix=" m&sup2;"
                  />
                </Typography>
              </>
            ) : (
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{
                  borderRadius: 0.5,
                }}
                width={120}
                height={skeletonHeight}
              />
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            mt={1}
          >
            {!loading ? (
              <>
                <Typography
                  variant="h7"
                  color="primary"
                  fontWeight="bold"
                >
                  {t('plannedRelease')}
                  :
                </Typography>
                <Typography
                  variant="h7"
                >
                  {project?.expectedDeliveryDate || ''}
                </Typography>
              </>
            ) : (
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{
                  borderRadius: 0.5,
                }}
                width={120}
                height={skeletonHeight}
              />
            )}
          </Box>
        </Box>
      </Card>
    </Grid>
  );
};

ProjectCard.propTypes = {
  project: PropTypes.object,
  loading: PropTypes.bool,
  mwidth: PropTypes.any,
};

export default ProjectCard;
