import { Box } from '@mui/material';
import React from 'react';
import { useSelector } from '../app/store';
import PropTypes from 'prop-types';

const BlurrBox = ({ children }) => {
  const { canBlur, openModals } = useSelector((state) => state.property);
  return (
    <Box
      sx={{
        filter: (canBlur || openModals.length > 0) && `blur(${openModals.length * 3}px)`,
      }}
    >
      {children}
    </Box>
  );
};

export default BlurrBox;

BlurrBox.propTypes = {
  children: PropTypes.node.isRequired,
};
