import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import {
  Box,
  CssBaseline,
  IconButton,
  ThemeProvider,
} from '@mui/material';
import './App.css';
import routes from './routes';
import useSettings from './hooks/useSettings';
import { createCustomTheme } from './theme';
import AOS from 'aos';
import 'aos/dist/aos.css';
import LoginModal from './components/LoginModal';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import StraightRoundedIcon from '@mui/icons-material/StraightRounded';
import { Helmet } from 'react-helmet-async';

// const tagManagerArgs = {
//   gtmId: 'GTM-PCDL86N',
//   dataLayerName: 'PageDataLayer'
// }

// TagManager.initialize(tagManagerArgs)

const App = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const lgDown = window.matchMedia('(max-width: 1280px)').matches;

  // window.dataLayer?.push({
  //   event: 'pageview'
  // });

  // TagManager.initialize({
  //   gtmId: 'GTM-PCDL86N',
  // });

  AOS.init({
    once: true,
    delay: 50,
    duration: 500,
    easing: 'ease-in-out',
  });

  const theme = createCustomTheme({
    responsiveFontSizes: true,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster
        position="top-center"
        duration="3000"
      />
      {/* <Helmet>
        <title>LidoHome</title>
        <meta name="description" content="LidoHome" />

      </Helmet> */}
      <GoogleReCaptchaProvider
        reCaptchaKey="6Lf-R5okAAAAAM3RQMPZ5tu3FfA-0FGiStkyC9RS"
      >
        {content}
        <LoginModal />
        {lgDown && (
          <Box
            data-aos="fade-left"
            data-aos-offset="1100"
            data-aos-once="false"
            sx={{
              position: 'fixed',
              right: 10,
              bottom: '5%',
              zIndex: 1000,
              borderRadius: '10px',
              backgroundColor: 'rgba(255, 255, 255, .7)',
              backdropFilter: 'blur(15px)',
              boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
              p: 0,
              // backgroundColor: '#000',
              // '&:hover': { backgroundColor: '#962F32' }
            }}
          >
            <IconButton
              onClick={scrollToTop}
              size="small"
            >
              <StraightRoundedIcon />
            </IconButton>
          </Box>
        )}
      </GoogleReCaptchaProvider>
    </ThemeProvider >
  );
};

export default App;
