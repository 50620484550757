import { Avatar, Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import picture1 from '../../images/home/homePic1New.jpg';
import picture2 from '../../images/home/homePic2New.jpg';
import picture3 from '../../images/home/homePic3New.jpg';
import picture4 from '../../images/home/homePic4New.jpg';
import PropTypes from 'prop-types';

const WhyAdvertiseWithus = () => {
  const { t } = useTranslation();
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  return (
    <Box
      backgroundColor="#EEEEEE"
      sx={{ mt: 8 }}
    >
      <Container>
        <Box
          pt={5}
        >
          <Box
            display="flex"
            justifyContent="flex-start"
            mb={10}
            data-aos={!lgDown && 'fade-right'}
          >
            <Typography
              variant="h4"
              color="primary"
              sx={{
                position: 'relative',
                '&:after': {
                  backgroundColor: 'primary.main',
                  bottom: '-8px',
                  content: '" "',
                  height: '3px',
                  left: 0,
                  position: 'absolute',
                  width: '288px',
                }
              }}
            >
              <span style={{ fontWeight: 800 }}>
                {t('whyAdvertise1')}
              </span>
              {t('whyAdvertise2')}
            </Typography>
          </Box>

          <Grid
            container
            spacing={5}
          >
            <Reason
              avatar={picture1}
              index={1}
            />
            <Reason
              avatar={picture2}
              index={2}
            />
            <Reason
              avatar={picture3}
              index={3}
            />
            <Reason
              avatar={picture4}
              index={4}
            />
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default WhyAdvertiseWithus;

const Reason = ({ avatar, index }) => {
  const { t } = useTranslation();
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={6}
      lg={3}
      display="flex"
      alignItems="center"
      flexDirection="column"
      mb={5}
      data-aos={!lgDown && 'fade-up'}
      data-aos-delay={100}
    >
      <Avatar
        src={avatar}
        sx={{
          height: 250,
          width: 250,
          mb: 5,
          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px'
        }}
      />
      <Typography
        textAlign="left"
        color="primary"
        variant="h6Bold"
        mb={4}
      >
        {t(`homeAvatar${index}Title`)}
      </Typography>
      <Typography textAlign="left">
        {t(`homeAvatar${index}Text`)}
      </Typography>
    </Grid>
  );
};

Reason.propTypes = {
  avatar: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired
};
