import React, { useCallback, useEffect, useRef } from "react";
import {
  Button,
  Grid,
  Box,
  CardMedia,
  Checkbox,
  Typography,
  FormControlLabel,
  Container,
  useMediaQuery,
  Link,
  Divider,
  FormHelperText,
} from "@mui/material";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import footerNewCroppedackground from "../../../images/footer/footerBackground.png";
import inda from "../../../images/footer/inda.png";
import reka2 from "../../../images/footer/reka2.jfif";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import * as Yup from "yup";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Logo from "../../Logo";
import { useLocation } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import AOCTextfield from "../../../utils/AOCTextfield";
import AOCAutocomplete from "../../../utils/AOCAutocomplete";
import { useDispatch } from "../../../app/store";
import { Message } from "../../../app/slices/property";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import AOCPhoneInput from "../../../utils/AOCPhoneInput";
import ScrollHere from "../../../utils/ScrollHere";
import imprint from "../../../images/pdfs/imprint.pdf";
import data_management from "../../../images/pdfs/adat-kezelesi-tajekoztato.pdf";

const Footer = () => {
  const { t } = useTranslation("translation");
  const location = useLocation();
  const mobileDevice = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const isReka =
    location.pathname.split("/").includes("partner") ||
    location.pathname.split("/").includes("career"); // TODO: check for Carreer and Job aw not just partner
  const formikRef = useRef();
  const useStyles = makeStyles()(() => ({
    // NOTE: This is the new v5 Material Version
    text: {
      color: "#ababab",
      "&:hover": {
        color: "#fff",
      },
    },
    noHoverText: {
      color: "#ababab",
    },
  }));

  const { classes } = useStyles();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit2 = (values, newToken) => {
    const sendData = {
      ...values,
      messageType: values?.messageType?.value,
      googleVerificationToken: newToken,
    };
    dispatch(Message(sendData));
  };

  const handleReCaptchaVerify = useCallback(
    async (values) => {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }

      const newToken = await executeRecaptcha("yourAction");
      // Do whatever you want with the token
      handleSubmit2(values, newToken);
    },
    [executeRecaptcha]
  );

  useEffect(() => {
    formikRef.current?.setFieldValue("dataManagementAccepted", false);
    formikRef.current?.resetForm();
  }, [location.pathname]);

  return (
    <>
      <Divider />
      <ScrollHere scrollName="scrolltToFooter" />
      <Box
        sx={{
          zIndex: 1,
          overflow: "hidden",
        }}
        display={location.pathname.split("/").includes("partnerform") && "none"}
      >
        {lgUp && (
          <>
            <Box
              sx={{
                position: "relative",
                zIndex: 0,
              }}
            >
              <img
                id="indaLeft"
                alt="indaLeft"
                style={{
                  height: "400px",
                  width: "auto",
                  top: -200,
                  left: -200,
                  position: "absolute",
                  zIndex: 0,
                  transform: "scaleX(-1)",
                }}
                src={inda}
              />
              <img
                id="indaLeft"
                alt="indaLeft"
                style={{
                  overflow: "hidden",
                  height: "400px",
                  width: "auto",
                  top: 600,
                  right: -200,
                  position: "absolute",
                  zIndex: 0,
                }}
                src={inda}
              />
            </Box>
          </>
        )}
        <Box
          sx={{
            backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),url(${footerNewCroppedackground})`,
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            padding: "20px",
            py: 10,
          }}
        >
          <Box
            sx={{
              position: "relative",
            }}
          />
          <Formik
            innerRef={formikRef}
            initialValues={{
              contactName: "",
              contactEmail: "",
              contactPhone: "",
              message: "",
              messageType: "",
              termsConditionsAccepted: true, // TODO: they do not want this
              dataManagementAccepted: false,
              dataTransferAccepted: true, // TODO: they do not want this
            }}
            validationSchema={Yup.object().shape({
              contactName: Yup.string()
                .min(6, "Legalább 6 karakter hosszúnak kell lennie")
                .required("Mező kitöltése kötelező"),
              contactPhone: Yup.string().required("Mező kitöltése kötelező"),
              contactEmail: Yup.string()
                .required("Mező kitöltése kötelező")
                .email("Az e-mail cím formátuma nem megfelelő"),
              message: Yup.string()
                .min(10, "Legalább 10 karakter hosszúnak kell lennie")
                .required("Mező kitöltése kötelező"),
              messageType: Yup.object()
                .required("Mező kitöltése kötelező")
                .nullable(),
              dataManagementAccepted: Yup.boolean().oneOf(
                [true],
                "A mező elfogadása kötelező"
              ),
            })}
            onSubmit={(values, { setSubmitting }) => {
              handleReCaptchaVerify(values);
              setTimeout(() => {
                setSubmitting(false);
              }, 400);
            }}
          >
            {({
              values,
              handleChange,
              setFieldValue,
              touched,
              errors,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Container>
                  <Grid
                    container
                    spacing={6}
                    display="flex"
                    justifyContent="center"
                  >
                    <Grid item md={6} xs={12}>
                      <Grid
                        container
                        flexDirection={isReka ? "row" : "column"}
                        spacing={2}
                      >
                        <Grid item xs={12}>
                          <Typography
                            color="black"
                            variant="h5"
                            sx={{
                              fontWeight: "bold",
                              position: "relative",
                              "&:after": {
                                backgroundColor: "#962F32",
                                bottom: "-8px",
                                content: "' '",
                                height: "3px",
                                left: "0px",
                                position: "absolute",
                                width: "150px",
                              },
                            }}
                          >
                            {t("footer.help")}
                          </Typography>
                        </Grid>
                        <Grid item display="flex" xs={12} mt={4}>
                          <AOCTextfield
                            onBlur={handleBlur}
                            onChange={handleChange}
                            values={values}
                            touched={touched}
                            errors={errors}
                            name="contactName"
                            label={t("footer.name")}
                            bold
                          />
                        </Grid>
                        <Grid item display="flex" xs={12}>
                          <Grid container spacing={2}>
                            <Grid item sm={6} xs={12}>
                              <AOCPhoneInput
                                values={values}
                                touched={touched}
                                errors={errors}
                                name="contactPhone"
                                setFieldValue={setFieldValue}
                                bold
                              />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <AOCTextfield
                                onBlur={handleBlur}
                                onChange={handleChange}
                                values={values}
                                touched={touched}
                                errors={errors}
                                name="contactEmail"
                                label={t("footer.email")}
                                bold
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <AOCAutocomplete
                            error={Boolean(
                              touched.messageType && errors.messageType
                            )}
                            helperText={
                              touched.messageType && errors.messageType
                            }
                            fullWidth
                            options={[
                              {
                                id: 1,
                                label: "Ingatlan vásárlás",
                                value: "PROPERTY",
                              },
                              {
                                id: 2,
                                label: "Ingatlan eladás",
                                value: "PROPERTY",
                              },
                              {
                                id: 3,
                                label: "Ingatlan kiadás",
                                value: "PROPERTY",
                              },
                              {
                                id: 4,
                                label: "Saját kivitelezésű projektek",
                                value: "OWN_PROJECT",
                              },
                              { id: 5, label: "Karrier", value: "CAREER" },
                              {
                                id: 6,
                                label: "Visszajelzés / vélemény",
                                value: "FEEDBACK",
                              },
                              {
                                id: 7,
                                label: "Partner program",
                                value: "PARTNER",
                              },
                              { id: 8, label: "Hitel", value: "LOAN" },
                            ]}
                            onChange={(_, newValue) => {
                              setFieldValue("messageType", newValue || null);
                            }}
                            noOptionsText="Nem tartoznak az irodához munkatársak"
                            value={values.messageType}
                            label={t("footer.help")}
                            isBold
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <AOCTextfield
                            rows={5}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            values={values}
                            touched={touched}
                            errors={errors}
                            name="message"
                            multiline
                            label={t("footer.message")}
                            bold
                          />
                        </Grid>
                        <Grid item display="flex" flexDirection="column">
                          {/* <FormControlLabel
                            label={t("footer.checkTermsAndConditions")}
                            sx={{ color: "black" }}
                            control={
                              <Checkbox
                                color="primary"
                                name="termsConditionsAccepted"
                                onChange={handleChange}
                                sx={{ color: "black" }}
                              />
                            }
                          /> */}
                          <FormControlLabel
                            label={t("footer.checkDataManagement")}
                            sx={{ color: "black" }}
                            control={
                              <Checkbox
                                color="primary"
                                name="dataManagementAccepted"
                                onChange={handleChange}
                                sx={{ color: "black" }}
                              />
                            }
                          />
                          {errors.dataManagementAccepted &&
                            touched.dataManagementAccepted && (
                              <Box sx={{ mt: 3 }}>
                                <FormHelperText error>
                                  {errors.dataManagementAccepted}
                                </FormHelperText>
                              </Box>
                            )}
                          {/* <FormControlLabel
                            label={t("footer.checkDataTransfer")}
                            sx={{ color: "black" }}
                            control={
                              <Checkbox
                                color="primary"
                                name="dataTransferAccepted"
                                onChange={handleChange}
                                sx={{ color: "black" }}
                              />
                            }
                          /> */}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-end"
                        >
                          <Button
                            disabled={isSubmitting}
                            type="submit"
                            variant="outlined"
                            size="large"
                            sx={{ px: 5 }}
                          >
                            Küldés
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    {isReka && (
                      <Grid item md={6} sm={12} xs={12}>
                        <Box
                          sx={{
                            border: "1px solid white",
                            p: 4,
                            borderRadius: "10px",
                            alignItems: "center",
                            justifyContent: "center",
                            mt: 5,
                          }}
                        >
                          <CardMedia
                            image={reka2}
                            sx={{
                              height: "380px",
                              mx: !mobileDevice && 10,
                              borderRadius: "10px",
                              mb: 4,
                              boxShadow:
                                "rgba(0, 0, 0, 0.56) 0px 22px 70px 4px",
                            }}
                          />
                          <Typography
                            textAlign="center"
                            color="primary"
                            variant="h5"
                          >
                            Harmat Réka
                          </Typography>
                          <Typography
                            textAlign="center"
                            color="primary"
                            variant="h6"
                          >
                            Marketing vezető / HR vezető
                          </Typography>
                          <Typography textAlign="center" color="primary">
                            +36 70 610 3922
                          </Typography>
                          <Typography textAlign="center" color="primary">
                            {location.pathname.split("/").includes("career")
                              ? "karrier@lidohome.hu"
                              : "lhpartner@lidohome.hu"}
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Container>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          background: "#2c3e50",
          backgroundSize: "cover",
          color: "white",
          position: "relative",
          zIndex: 1,
        }}
      >
        <Container>
          <Grid container spacing={2} sx={{ py: "80px" }}>
            <Grid
              item
              sm={4}
              xs={12}
              mt={-5}
              display="flex"
              justifyContent="center"
            >
              <Link href="/">
                <Logo
                  sx={{
                    mt: 1,
                    height: 88,
                    width: 170,
                  }}
                  home
                />
              </Link>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Grid container spacing={1}>
                {[
                  {
                    href: "/projects/investment",
                    text: t("footer.ownInvestments"),
                  },
                  {
                    href: "/projects/references",
                    text: t("footer.references"),
                  },
                  { href: "/aboutus", text: t("footer.aboutUs") },
                  { href: "/blog", text: t("footer.blog") },
                ].map(({ href, text }) => (
                  <Grid
                    key={href}
                    item
                    xs={12}
                    display="flex"
                    justifyContent={{ xs: "center", sm: "start" }}
                  >
                    <Link
                      href={href}
                      textAlign="left"
                      sx={{ textDecoration: "none" }}
                    >
                      <Typography textAlign="left" className={classes.text}>
                        {text}
                      </Typography>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Grid container spacing={1}>
                {[
                  { href: imprint, text: t("footer.TOS") },
                  {
                    href: data_management,
                    text: t("footer.dataProtectionInfomartion"),
                  },
                  // { href: "/cookie", text: t("footer.cookie") },
                ].map(({ href, text }) => (
                  <Grid
                    key={href}
                    item
                    xs={12}
                    display="flex"
                    justifyContent={{ xs: "center", sm: "start" }}
                  >
                    <>
                      <Link
                        href={href}
                        textAlign="left"
                        target="_blank"
                        rel="noreferrer"
                        sx={{ textDecoration: "none" }}
                      >
                        <Typography textAlign="left" className={classes.text}>
                          {text}
                        </Typography>
                      </Link>
                    </>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={2} display="flex" alignItems="center">
            <Grid item md={4} xs={12} display="flex" justifyContent="center">
              <a
                style={{ cursor: "pointer" }}
                href="https://www.facebook.com/LidoHomeIngatlan/"
                rel="noreferrer"
                target="_blank"
              >
                <Typography className={classes.text}>
                  <FacebookIcon sx={{ fontSize: 23 }} />
                </Typography>
              </a>
              <a
                style={{ cursor: "pointer" }}
                href="https://www.instagram.com/lidohome_ingatlanok/"
                rel="noreferrer"
                target="_blank"
              >
                <Typography className={classes.text}>
                  <InstagramIcon sx={{ ml: 1, fontSize: 23 }} />
                </Typography>
              </a>
              <a
                style={{ cursor: "pointer" }}
                href="https://www.youtube.com/@lidohomeingatlanok8122"
                rel="noreferrer"
                target="_blank"
              >
                <Typography className={classes.text}>
                  <YouTubeIcon sx={{ ml: 1, fontSize: 23 }} />
                </Typography>
              </a>
              <a
                style={{ cursor: "pointer" }}
                href="https://www.linkedin.com/company/lido-home/"
                rel="noreferrer"
                target="_blank"
              >
                <Typography className={classes.text}>
                  <LinkedInIcon sx={{ ml: 1, fontSize: 23 }} />
                </Typography>
              </a>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              display="flex"
              justifyContent={{ xs: "center", sm: "flex-start" }}
            >
              <Typography className={classes.noHoverText}>
                © {new Date().getFullYear()} Copyright LIDO HOME
              </Typography>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              display="flex"
              justifyContent={{ xs: "center", sm: "flex-start" }}
            >
              <Typography className={classes.text}>
                e-mail: info@lidohome.hu
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{ background: "#212c38" }} />
    </>
  );
};

export default Footer;
