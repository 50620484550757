import { useEffect, useState } from 'react';
import { Breadcrumbs, Link, CardMedia, Container, Grid, Typography, Skeleton } from '@mui/material';
import BlogMetaInfo from './BlogMetaInfo';
import { Link as RouterLink, useParams } from 'react-router-dom';
import axiosConfig from '../axiosConfig';
import toast from 'react-hot-toast';
import useInnerHTML from '../hooks/useInnerHTML';
import { useDispatch } from '../app/store';
import { setCategoryFilter } from '../app/slices/blog';
import BlogSection from './BlogSection';
import { Helmet } from 'react-helmet-async';

const { REACT_APP_FILE_URL } = process.env;

async function fetchBlogById(id) {
  return axiosConfig.get(`/blog/articles/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      toast.error('A blog poszt lekérése sikertelen');
      console.error(error);
    });
}

const BlogDetail = () => {
  const [blog, setBlog] = useState({});
  const refLead = useInnerHTML(blog?.lead);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);

    fetchBlogById(id)
      .then((data) => {
        setBlog(() => data);
      });
  }, []);

  function handleRouteChange() {
    dispatch(setCategoryFilter(blog?.category?.id));
  }

  return (
    <>
      <Helmet><title>{`LidoHome ${blog.title ? ` | ${blog.title}` : ''}`}</title></Helmet>
      <Container maxWidth="md">
        <Grid
          my={10}
          display="flex"
          flexDirection="column"
          gap={3}
        >
          {blog?.title
            ? (
              <>
                <Breadcrumbs
                  separator="›"
                  aria-label="breadcrumb"
                >
                  <Link
                    component={RouterLink}
                    underline="hover"
                    color="textPrimary"
                    to="/blog"
                  >
                    Blog
                  </Link>
                  {blog?.category?.name && (
                    <Link
                      component={RouterLink}
                      underline="hover"
                      color="textPrimary"
                      to="/blog"
                      onClick={handleRouteChange}
                    >
                      {blog?.category?.name}
                    </Link>
                  )}
                  <Typography color="textSecondary">{blog?.title}</Typography>
                </Breadcrumbs>

                <Typography
                  variant="h4"
                >
                  {blog?.title}
                </Typography>

                <BlogMetaInfo
                  publish={blog?.publish}
                  modified={blog?.modified}
                  author={blog?.creator?.name}
                  showEditDate
                />

                <Typography
                  ref={refLead}
                  variant="h6"
                  color="textSecondary"
                />

                {blog?.coverImage?.source?.large && (
                  <CardMedia
                    component="img"
                    src={`${REACT_APP_FILE_URL}/private${blog.coverImage.source.large}`}
                    alt={blog?.title}
                    sx={{
                      borderRadius: 1,
                      my: 4,
                    }}
                  />
                )}

                {blog?.sections
                  && blog.sections.map((section) => <BlogSection section={section} />)}

              </>
            )
            : <BlogSkeleton />}
        </Grid>
      </Container>
    </>
  );
};

export default BlogDetail;

const BlogSkeleton = () => (
  <>
    <Skeleton width={400} />
    <Skeleton height={60} />
    <Grid
      display="flex"
      gap={1}
      justifyContent="space-between"
    >
      <Skeleton width={400} />
      <Skeleton width={150} />
    </Grid>
    <Skeleton height={600} />
  </>
);
