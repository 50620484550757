import { Box, Card, CardMedia, Container, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/Email';
import lorinczi from '../images/loan/LorincziCsaba.png';
import berendias from '../images/loan/BerendiasJudit.png';
import Bolgovics from '../images/loan/Bolgovics.jpg';
import Molnar from '../images/loan/Molnar.jpeg';
import oldRedLHLogo from '../images/oldRedLHLogo.png';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';


const employeeArray = [
  {
    firstName: 'Csaba',
    lastName: 'Lőrinczi',
    phone: '+36 70 367 3131',
    email: 'csaba.lorinczi@bankmonitorpartner.hu',
    image: lorinczi
  },
  {
    firstName: 'Judit',
    lastName: 'Berendiás',
    phone: '+36 70 418 1391',
    email: 'judit.berendias@lidohome.hu',
    image: berendias
  },
  // {
  //   firstName: 'László',
  //   lastName: 'Bolgovics',
  //   phone: '+36 70 610 3923',
  //   email: 'laszlo.bolgovics@lidohome.hu',
  //   image: Bolgovics
  // },
  {
    firstName: 'Balázs',
    lastName: 'Molnár',
    phone: '+36 30 557 4957',
    email: 'molnar.balazs2@ovb.hu',
    image: Molnar
  }
];

const HitelEmployees = () => {
  const { t } = useTranslation();
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const pictureHeight = smDown ? 320 : 500;
  // const pictureHeight = 500;

  return (
    <>
      <Container>
        <Typography
          mt={10}
          variant="h4"
          color="primary"
        >
          <Typography
            component="span"
            variant="h4"
            color="inherit"
            sx={{
              position: 'relative',
              '&:after': {
                backgroundColor: '#962F32',
                bottom: 0,
                content: '" "',
                height: '2px',
                left: 0,
                position: 'absolute',
                width: '100%',
              }
            }}
          >
            {t('hitel.description.underlined')}
          </Typography>
          {t('hitel.description.text')}
        </Typography>
      </Container>
      <Container maxWidth="md">
        {employeeArray.map(({ firstName, lastName, phone, email, image }, i) => (
          <Grid
            container
            mt={5}
            flexDirection={i % 2 === 0 ? 'row' : 'row-reverse'}
          >
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
            >
              {image ? (
                <CardMedia
                  image={image}
                  style={{
                    backgroundColor: 'background.default',
                    minHeight: pictureHeight,
                    borderRadius: 18,
                    backgroundPosition: 'top',
                  }}
                />
              ) : (
                <>
                  <Box
                    sx={{
                      alignItems: 'center',
                      backgroundColor: 'background.default',
                      display: 'flex',
                      width: '100%',
                      border: 2,
                      borderColor: 'background.paper',
                      minWidth: 'auto',
                      minHeight: pictureHeight,
                      justifyContent: 'center',
                      borderTopLeftRadius: 18,
                      borderTopRightRadius: 18,
                    }}
                  />
                </>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Card data-aos={!lgDown && 'fade-right'}>
                <Box
                  py={3}
                  px={2}
                  display="flex"
                  flexDirection="column"
                >
                  <Stack
                    mb={3}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                    >
                      <CardMedia
                        image={oldRedLHLogo}
                        sx={{
                          height: '50px',
                          width: '51px',
                          mr: 2
                        }}
                      />
                      <Typography
                        variant="h4"
                        color="primary"
                        sx={{
                          fontWeight: 800,
                          position: 'relative',
                          textTransform: 'uppercase',
                          '&:after': {
                            backgroundColor: '#962F32',
                            bottom: 0,
                            content: '" "',
                            height: '2px',
                            left: 0,
                            position: 'absolute',
                            width: '100px',
                          }
                        }}
                      >
                        {lastName}
                      </Typography>
                    </Box>
                    <Typography
                      color="primary"
                      variant="h5"
                      fontWeight="bold"
                      sx={{
                        position: 'relative',
                        left: '170px'
                      }}
                    >
                      {firstName}
                    </Typography>
                  </Stack>

                  <Contact value={email} />
                  <Contact value={phone} />
                </Box>
              </Card>
            </Grid>
          </Grid>
        ))}
      </Container>
    </>
  );
};

export default HitelEmployees;

const Contact = ({ value }) => {
  const type = value.includes('@') ? 'mailto:' : 'tel:';
  const Icon = value.includes('@') ? EmailIcon : PhoneAndroidIcon;

  return (
    <Box
      display="flex"
      alignItems="center"
    >
      <Icon sx={{ opacity: 0.5, width: 18, height: 18, mr: 1 }} />
      <Typography>
        <a
          href={`${type}${value.replaceAll(' ', '')}`}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          {value}
        </a>
      </Typography>
    </Box>
  );
};

Contact.propTypes = {
  value: PropTypes.string.isRequired,
};
