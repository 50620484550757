import { Button, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { operateGeneralArray, registerConfirm, registerDelete } from '../app/slices/property';
import { useDispatch, useSelector } from '../app/store';
import PropTypes from 'prop-types';

const VerifyRegistration = ({ type }) => {
  const { uuid } = useParams();
  const { generalArray } = useSelector((state) => state.property);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  console.log(type, generalArray);

  useEffect(() => {
    if (type === 'registrationVerify') dispatch(registerConfirm(uuid));
    if (type === 'registrationDelete') dispatch(registerDelete(uuid));

    return () => dispatch(operateGeneralArray('', 'reset'));
  }, []);

  return (
    <Grid
      my={20}
    >
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography
            sx={{ mb: 2 }}
          >
            {t(type)}
            {generalArray?.includes('verify_success') && ' sikeres'}
            {generalArray?.includes('verify_failure') && ' sikertelen'}
          </Typography>
          {generalArray.includes('verify_success') || generalArray.includes('verify_failure') ? (
            <Button
              component={Link}
              to="/"
            >
              Vissza a főoldalra
            </Button>
          ) : (
            <CircularProgress />
          )}
        </Box>
      </Container>
    </Grid>
  );
};

export default VerifyRegistration;

VerifyRegistration.propTypes = {
  type: PropTypes.string.isRequired
};
