import { styled, TextField } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const AOCTextfield = (props) => {
  const {
    name,
    label,
    onBlur,
    onChange,
    values,
    touched,
    errors,
    rows,
    mt,
    margin,
    bold,
    multiline = false,
  } = props;

  return (
    <TextField
      fullWidth
      sx={{
        mt: mt || 0,
        "& .MuiOutlinedInput-root": {
          "& fieldset": { borderColor: bold && "black" },
        },
      }}
      InputLabelProps={{
        style: { color: bold && "black", fontWeight: bold && "bold" },
      }}
      SelectProps={{ style: { border: "none" } }}
      multiline={multiline}
      rows={rows || 1}
      error={Boolean(touched[name] && errors[name])}
      helperText={touched[name] && errors[name]}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={label}
      value={values[name]}
      name={`${name}`}
      margin={margin || "none"}
      label={label}
    />
  );
};

export default AOCTextfield;

AOCTextfield.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  rows: PropTypes.number,
  mt: PropTypes.number,
  margin: PropTypes.string,
  bold: PropTypes.bool,
};
