import { useEffect, useRef } from 'react';

const useInnerHTML = (html) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!html) return;
    ref.current.innerHTML = html;
  }, [html]);

  return ref;
};

export default useInnerHTML;
