// /* eslint-disable import/no-unresolved */ // NOTE: Somehow eslint think the swiper css import that thay are unresolved but no, they are good
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  Grid,
  Slide,
  Toolbar,
  useMediaQuery
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Navigation, Pagination, EffectFade, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/keyboard';
import '../styles/styles.scss';

const { REACT_APP_FILE_URL } = process.env;

const Transition = React.forwardRef((props, ref) => (
  <Slide
    direction="up"
    ref={ref}
    {...props}
  />
));

const FullScreenImageVideoSwiper = ({ images, videos, open, handleOnClose, currentIndex }) => {
  const mobileDevice = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    handleOnClose();
  }, [mobileDevice]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleOnClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: 'absolute',
          backgroundColor: '#020202'
        }}
      >
        <Toolbar>
          <Button
            startIcon={<CloseIcon fontSize="small" />}
            color="inherit"
            variant="outlined"
            onClick={handleOnClose}
            sx={{ mt: 2 }}
          >
            Kilépés
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          backgroundColor: 'black',
          height: '100%',
        }}
      >
        <Swiper
          modules={[Navigation, Pagination, EffectFade, Keyboard]}
          slidesPerView={1}
          style={{
            height: '100%',
            width: '100vw'
          }}
          fadeEffect={{ crossFade: true }}
          effect={!mobileDevice && 'fade'}
          navigation={!mobileDevice}
          keyboard
          initialSlide={currentIndex || 0}
          pagination={{
            type: 'fraction',
          }}
        >
          {images && images.map((image) => (
            <SwiperSlide key={image.id}>
              <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  height: '100%',
                }}
              >
                <Grid
                  item
                  display="flex"
                  justifyContent="center"
                >
                  <img
                    alt={image.description}
                    src={`${REACT_APP_FILE_URL}${image?.source?.large}`}
                    style={{
                      objectFit: 'contain',
                      maxHeight: '70vh',
                      pointerEvents: 'none',
                    }}
                  />
                </Grid>
              </Grid>
            </SwiperSlide>
          ))}
          {videos && videos.map((video) => (
            <SwiperSlide key={video.id}>
              <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  height: '100%',
                }}
              >
                <Grid
                  item
                  display="flex"
                  justifyContent="center"
                >
                  <video
                    controls
                    width="100%"
                    style={{
                      objectFit: 'contain',
                      maxHeight: '70vh',
                    }}
                  >
                    <source
                      src={`${REACT_APP_FILE_URL}${video?.source}`}
                      type="video/mp4"
                    />
                    <track kind="captions" />
                  </video>
                </Grid>
              </Grid>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Dialog>
  );
};

FullScreenImageVideoSwiper.propTypes = {
  images: PropTypes.array,
  videos: PropTypes.array,
  open: PropTypes.bool,
  handleOnClose: PropTypes.func,
  currentIndex: PropTypes.number
};

export default FullScreenImageVideoSwiper;
