import { Box, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import LidoBanner from '../utils/LidoBanner';
import { useTranslation } from 'react-i18next';
import hitelBanner from '../images/hitelBanner.png';
import loanBackgroundOg from '../images/loan/loanBackground-og.jpg';
import loanBackground from '../images/loan/loanBackground.png';
import HitelReasons from './HitelReasons';
import HitelEmployees from './HitelEmployees';
import { Helmet } from 'react-helmet-async';

// const tagManagerArgs = {
//   dataLayer: {
//     page: 'properties'
//   },
//   dataLayerName: 'PageDataLayer'
// }

const Hitel = () => {
  const { t } = useTranslation();

  // TagManager.dataLayer(tagManagerArgs)

  // TagManager.dataLayer({
  //   dataLayer: {
  //     event: 'pageview',
  //     pagePath: '/loan',
  //     pageTitle: 'Lidohome | Hitel',
  //   },
  // });

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'Pageview',
      pagePath: '/loan',
      pageTitle: 'LidoHome | Hitel',
    });
  }, []);

  return (
    <Grid
    // sx={{
    //   filter: 'grayscale(0.3) brightness(1.03)',
    //   backgroundSize: 'cover',
    //   backgroundImage: `url(${loanBackground})`,
    // }}
    >
      <Helmet><title>LidoHome | Hitel</title></Helmet>
      <Box
        sx={{
          backgroundImage: `url(${loanBackground})`,
          backgroundPosition: 'center',
          // filter: 'grayscale(0.3) brightness(0.8)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          width: '100%',
        }}
      >
        <LidoBanner
          text={t('hitel.title')}
          image={hitelBanner}
        />
        <HitelEmployees />
        <HitelReasons />
      </Box>
    </Grid>
  );
};

export default Hitel;
