import { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { experimentalStyled, useMediaQuery } from "@mui/material";
import DashboardNavbar from "./DashboardNavbar";
import Footer from "./Footer/Footer";
import DashboardSidebar from "./DashboardSidebar";
import BlurrBox from "../../utils/BlurrBox";

const DashboardLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
  overflowX: "hidden",
}));

const DashboardLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutWrapper = experimentalStyled("div")(() => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: "80px",
}));

const DashboardLayoutContent = experimentalStyled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflowY: "auto",
  overflowX: "hidden",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});
const DashboardLayout = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
  const location = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  return (
    <>
      {lgUp ? (
        <div
          style={{
            position: "relative",
            overflowX: location.pathname === '/' && 'hidden',
            paddingTop: location.pathname === "/" ? "0px" : "80px",
            minHeight: "100vh",
          }}
        >
          <DashboardNavbar
            onSidebarMobileOpen={() =>
              setIsSidebarMobileOpen(!isSidebarMobileOpen)
            }
          />
          <DashboardSidebar
            onMobileClose={() => setIsSidebarMobileOpen(false)}
            openMobile={isSidebarMobileOpen}
          />
          <BlurrBox>
            <Outlet />
            <Footer />
          </BlurrBox>
        </div>
      ) : (
        <div
          style={{
            position: "relative",
            overflowX: "hidden",
            paddingTop: location.pathname === "/" ? "0px" : "0px",
          }}
        >
          <DashboardLayoutRoot>
            <DashboardNavbar
              onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)}
            />
            <DashboardSidebar
              onMobileClose={() => setIsSidebarMobileOpen(false)}
              openMobile={isSidebarMobileOpen}
            />
            <DashboardLayoutWrapper>
              <DashboardLayoutContainer>
                <DashboardLayoutContent>
                  <BlurrBox>
                    <Outlet />
                    <Footer />
                  </BlurrBox>
                </DashboardLayoutContent>
              </DashboardLayoutContainer>
            </DashboardLayoutWrapper>
          </DashboardLayoutRoot>
        </div>
      )}
    </>
  );
};

export default DashboardLayout;
