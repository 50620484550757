import React from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';

const StyledTypographyUnderline = ({ children, style, color, sx, variant, mt }) => {
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  return (
    <Typography
      mt={mt}
      color={color || 'primary'}
      data-aos={!lgDown && 'fade-right'}
      sx={{
        position: 'relative',
        '&:after': {
          backgroundColor: 'primary.main',
          bottom: '-8px',
          content: '" "',
          height: '3px',
          left: 0,
          position: 'absolute',
          width: 200,
        }
      }
       || sx}
      fontWeight={600}
      variant={variant || 'h5'}
      style={style}
    >
      {children}
    </Typography>
  );
};

export default StyledTypographyUnderline;

StyledTypographyUnderline.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  color: PropTypes.string,
  sx: PropTypes.object,
  variant: PropTypes.string,
  mt: PropTypes.number,
};
