import React from 'react';
import {
  ClickAwayListener,
  Grow,
  Popper,
  MenuItem,
  MenuList,
  Stack,
  Button,
  Box
} from '@mui/material';
import { useLocation, NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import ChevronUpIcon from '@mui/icons-material/ExpandLessRounded';
import ChevronDown from '@mui/icons-material/ExpandMoreRounded';

const DashboardNavItem = ({ navigationData }) => {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const { title, children, disabled } = navigationData;
  const { pathname } = location;
  const anchorRef = React.useRef(null);
  const splittedPathName = pathname.split('/');

  const exactMatch = () => {
    if (navigationData.path && navigationData.path === `/${splittedPathName[1]}`) {
      return 'navigation.active';
    }
    if (!navigationData.path) {
      return children.map((item) => item.path).includes(pathname) ? 'navigation.active' : 'navigation.text';
    }
    return 'navigation.text';
  };

  const handleToggle = () => {
    if (!navigationData.path) {
      setOpen((prevOpen) => !prevOpen);
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <Stack
      direction="row"
      spacing={2}
    >
      <div>
        <Button
          endIcon={!navigationData.path && !open ? <ChevronDown fontSize="small" />
            : !navigationData.path && <ChevronUpIcon fontSize="small" />}
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          component={navigationData.path && RouterLink}
          to={RouterLink && navigationData.path}
          sx={{
            color: exactMatch,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            px: '15px',
            py: '16px',
            width: '100%',
          }}
          disabled={disabled}
        >
          {title}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              sx={{
                p: 1,
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'center top',
              }}
            >
              <div>
                <Box
                  borderRadius="10px"
                  sx={{
                    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                    m: 2,
                    backgroundColor: 'rgba(255, 255, 255, .7)',
                    backdropFilter: 'blur(15px)',
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      disabledItemsFocusable
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                      onClick={handleClose}
                    >
                      {children.map((item) => (
                        <MenuItem
                          key={item.title}
                          disabled={item.disabled}
                          component={RouterLink}
                          to={item.path}
                          disableGutters
                          disableRipple
                          sx={{
                            borderRadius: '10px',
                            m: 1
                          }}
                        >
                          <Button
                            disableTouchRipple
                            startIcon={item.icon}
                            variant="text"
                            sx={{
                              color: item.path === pathname ? 'navigation.active' : 'navigation.text',
                              width: '100%',
                              textTransform: 'uppercase',
                              '&:hover': { backgroundColor: 'transparent' }
                            }}
                            aria-label="Delete"
                          >
                            {item.title}
                          </Button>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Box>
              </div>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
};

DashboardNavItem.propTypes = {
  navigationData: PropTypes.any,
  title: PropTypes.any,
  children: PropTypes.any,
  home: PropTypes.bool
};

export default DashboardNavItem;
