import React, { useState } from "react";
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  TablePagination,
} from "@mui/material";
import { useSelector } from "../app/store";
import CustomerSearchesRow from "./CustomerSearchesRow";

const applyPagination = (clients, page, limit) =>
  clients && clients.slice(page * limit, page * limit + limit);

const ClientSearches = () => {
  const { presentation } = useSelector((state) => state.property);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(3);
  const [clientSearchesByClient] = useState(presentation?.client?.searches);

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const paginatedClients = applyPagination(clientSearchesByClient, page, limit);
  return (
    <Box>
      <Card>
        <Grid component="table" width="100%">
          <Grid component="tbody">
            {paginatedClients.length > 0 ? (
              paginatedClients
                ?.filter((search) => search?.status === 1)
                .map((search) => (
                  <CustomerSearchesRow key={search?.id} search={search} />
                ))
            ) : (
              <Grid
                container
                width="100%"
                justifyContent="center"
                alignContent="center"
                minHeight={200}
              >
                <CircularProgress />
              </Grid>
            )}
          </Grid>
        </Grid>
        <TablePagination
          component="div"
          count={clientSearchesByClient ? clientSearchesByClient.length : 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPageOptions={[3, 6, 25]}
          rowsPerPage={limit}
          labelDisplayedRows={({ from, to, count }) =>
            `Elem ${from}-${to} - Összesen: ${count}`
          }
          labelRowsPerPage=""
        />
      </Card>
    </Box>
  );
};

export default ClientSearches;
