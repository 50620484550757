import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import axios from 'axios';
import { toast } from 'react-hot-toast';

const { REACT_APP_BASE_LOGIN_URL } = process.env;

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext(
  {
    ...initialState,
    platform: 'JWT',
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    register: () => Promise.resolve()
  }
);

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const token = Cookies.get('token');

        if (token) {
          const user = JSON.parse(Cookies.get('user') || '{}');
          console.log('user', user);

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    await axios.post(REACT_APP_BASE_LOGIN_URL, {
      email,
      password
    }, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': '*',
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      const user = {
        id: response.data.id,
        name: response.data.name,
        email: response.data.email,
        phone: response.data.phone,
        // permissions: response.data.permissions,
        // roles: response.data.roles,
        images: response.data.images,
        office: response.data.office,
      };
      Cookies.set('token', response.headers.token);
      console.log('set user to cookie', user)
      Cookies.set('user', JSON.stringify(user));
      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      });
    }).catch((error) => {
      console.log('error');
      console.error(error);
      toast.error('Az e-mail cím vagy jelszó nem megfelelő');
      throw new Error('Az e-mail cím vagy jelszó nem megfelelő');
    });
  };

  const logout = async () => {
    Cookies.remove('token', { path: '/', domain: 'localhost' });
    Cookies.remove('token', { path: '/', domain: 'lidohome.intranet' });
    Cookies.remove('token', { path: '/' });
    Cookies.set('user', JSON.stringify({
      id: '',
      name: '',
      email: '',
      avatar: '',
      plan: ''
    }));

    dispatch({ type: 'LOGOUT' });
  };

  /* eslint-disable */
  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
