import { Container } from "@mui/system";
import React, { useEffect } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import employeeBg from "../images/office.jpg";
import LidoBanner from "../utils/LidoBanner";
import {
  Box,
  Breadcrumbs,
  Grid,
  useMediaQuery,
  Link,
  Typography,
  Button,
} from "@mui/material";
import loanBackground from "../images/propertiesParallaxWhite.webp";
import { useSelector } from "../app/store";
import {
  getEmployeeById,
  switchScrollLocation,
  resetSelectedEmployee,
  selectOffice,
  clearOffice,
} from "../app/slices/property";
import { useDispatch } from "react-redux";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import lidoRedLogo from "../images/Lido_Logo_red_noText.svg";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import EmailIcon from "@mui/icons-material/Email";
import ScrollHere from "../utils/ScrollHere";
import ContactUs from "./ContactUs";
import PropertyBrowse from "./PropertyBrowse";
import StyledTypographyUnderline from "../utils/StyledTypographyUnderline";
import RateModal from "./RateModal";
import EmployeeRatings from "./EmployeeRatings";
import { Helmet } from "react-helmet-async";
import ScrollToTopOnRoute from "./ScrollToTopOnRoute";

const { REACT_APP_FILE_URL } = process.env;

const Employee = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { selectedEmployee, selectedOffice, openModals } = useSelector(
    (state) => state.property
  );
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const smDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(getEmployeeById(id));

    return () => {
      dispatch(resetSelectedEmployee([]));
    };
  }, []);

  useEffect(() => {
    if (
      !selectedEmployee ||
      !selectedEmployee.office ||
      !selectedEmployee.office.id
    )
      return;
    dispatch(selectOffice(selectedEmployee?.office?.id));

    return () => {
      dispatch(clearOffice());
    };
  }, [selectedEmployee]);

  function breakTitle(title) {
    if (title && title.includes("/")) {
      return title.split("/").join(" / ");
    }

    return title;
  }

  return (
    <>
      <Helmet>
        <title>{`LidoHome ${selectedEmployee.name ? ` | ${selectedEmployee.name}` : ""
          }`}</title>
      </Helmet>
      <ScrollToTopOnRoute />
      {selectedOffice.videos?.length > 0 ? (
        <Box sx={{ zIndex: 1, position: "relative", height: 400 }}>
          <video
            playsInline
            autoPlay
            muted
            loop
            style={{
              zIndex: 1,
              position: "relative",
              objectFit: "cover",
              width: "100%",
              height: "100%",
            }}
          >
            <source
              src={`${REACT_APP_FILE_URL}/private${selectedOffice.videos[0]?.source}`}
              type="video/mp4"
            />
          </video>
          <Typography
            color="white"
            variant="h1"
            textTransform="uppercase"
            sx={{
              zIndex: 1,
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              width: "100%",
              textAlign: "center",
              textShadow: "0px 6px 6px rgba(0, 0, 0, 0.4)",
            }}
          >
            {selectedOffice?.name}
          </Typography>
        </Box>
      ) : (
        <LidoBanner height={375} bgPosition="50% 25%" text={selectedOffice?.name} />
      )}
      {/* : (
         <LidoBanner image={employeeBg} height={375} bgPosition="50% 25%" />
       )} */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          position: "relative",
          mt: -5,
          backgroundColor: "#962F32",
          py: "1",
          zIndex: 10,
          pb: 1
        }}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={
            <ChevronRightIcon fontSize="small" sx={{ color: "white" }} />
          }
          sx={{
            mt: 1,
            ml: !mdDown && 15,
          }}
          zIndex={11}
        >
          <Link
            component={RouterLink}
            to={`/offices/details/${selectedEmployee?.office?.id}`}
            sx={{ textDecoration: "none" }}
          >
            <Typography
              zIndex={11}
              variant="h7"
              sx={{ textTransform: "uppercase", color: "#D9D9D9" }}
            >
              Munkatársaink
            </Typography>
          </Link>
          <Typography
            color="white"
            variant="subtitle2"
            sx={{
              textTransform: "uppercase",
            }}
          >
            {selectedEmployee?.name}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          backgroundSize: "cover",
          backgroundImage: `url(${loanBackground})`,
        }}
        pb={10}
      >
        <Container maxWidth="lg">
          <Box sx={{ backgroundColor: "#fff" }} pb={5}>
            <Grid container spacing={4} mt={1}>
              <Grid item xs={12} md={5} mt={mdDown ? 2 : -8.5}>
                {selectedEmployee?.images &&
                  selectedEmployee?.images[0]?.source && (
                    <>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box
                          zIndex={1}
                          sx={{ maxWidth: "580px", width: "100%" }}
                          data-aos={!lgDown && "fade-right"}
                        >
                          <img
                            style={{
                              width: "100%",
                              zIndex: 1,
                              position: "relative",
                            }}
                            src={selectedEmployee?.images[0]?.source?.thumb ? `${REACT_APP_FILE_URL}${selectedEmployee?.images[0]?.source?.thumb}` : `${REACT_APP_FILE_URL}${selectedEmployee?.images[0]?.source?.profile}`}
                            alt={selectedEmployee?.name}
                          />
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="center">
                        <Button
                          variant="contained"
                          onClick={() => {
                            dispatch(
                              switchScrollLocation("contactEmployee", true)
                            );
                          }}
                          sx={{
                            border: "1px solid #fff",
                            ml: 1,
                            zIndex: 2,
                            fontSize: "0.9em",
                          }}
                          size="medium"
                        >
                          Kapcsolat
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => {
                            dispatch(
                              switchScrollLocation("gotoProperties", true)
                            );
                          }}
                          size="medium"
                          sx={{
                            border: "1px solid #fff",
                            ml: 1,
                            zIndex: 2,
                            fontSize: "0.9em",
                          }}
                        >
                          Ingatlanok
                        </Button>
                        <Button
                          variant="contained"
                          size="medium"
                          sx={{
                            border: "1px solid #fff",
                            ml: 1,
                            zIndex: 2,
                            fontSize: "0.9em",
                          }}
                          onClick={() => {
                            dispatch(switchScrollLocation("gotoRatings", true));
                          }}
                        >
                          {t("ratings")}
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          position: "relative",
                          "&:after": {
                            zIndex: 0,
                            backgroundColor: "primary.main",
                            bottom: "-46px",
                            content: '" "',
                            height: "140px",
                            right: lgDown ? "20px" : "106px",
                            position: "absolute",
                            width: "100vw",
                          },
                        }}
                      />
                    </>
                  )}
              </Grid>
              <Grid item xs={12} md={7}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={mdDown && "center"}
                  mt={mdDown ? 10 : 0}
                  flexWrap="wrap"
                  sx={{ backgroundColor: "#fff", position: "relative" }}
                  gap={2}
                >
                  <img
                    src={lidoRedLogo}
                    alt="Lido logo"
                    style={{ height: "100px" }}
                  />
                  <Grid
                    display="flex"
                    alignItems={smDown ? "center" : "flex-start"}
                    flexDirection="column"
                    gap={2}
                  >
                    <Typography
                      variant="h4"
                      color="primary"
                      fontWeight="bold"
                      textAlign={smDown && "center"}
                      sx={{
                        position: "relative",
                        "&:after": {
                          backgroundColor: "primary.main",
                          bottom: "-8px",
                          content: '" "',
                          height: "3px",
                          left: 0,
                          position: "absolute",
                          width: 200,
                        },
                      }}
                    >
                      {selectedEmployee?.name}
                    </Typography>
                    <Typography
                      textAlign={smDown && "center"}
                      variant="h5"
                      sx={{
                        opacity: 0.4,
                      }}
                    >
                      {mdDown
                        ? breakTitle(selectedEmployee?.title)
                        : selectedEmployee?.title}
                    </Typography>
                  </Grid>
                </Box>
                <Typography p={2} mt={6} sx={{ overflowWrap: "break-word" }}>
                  {selectedEmployee?.description &&
                    parse(selectedEmployee?.description)}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={lgDown ? 0 : 15}
              px={mdDown ? 2 : 5}
              display="flex"
              alignItems="center"
            >
              <Grid item xs={12} md={7}>
                <Typography>
                  A{" "}
                  <span>
                    {selectedEmployee?.office?.name}
                    (-i)
                  </span>{" "}
                  irodája az ingatlan eladáson és a bérbeadáson kívül, díjmentes
                  hitelügyintézéssel, energetikai tanúsítvány készítéssel,
                  kedvezményes jogi tanácsadással is ügyfelei rendelkezésére
                  áll. Lido Home ingatlan: &quot; Ingatlanok egy életen át&quot;
                </Typography>
                <Box mt={5}>
                  {selectedEmployee?.languages?.length > 0 && (
                    <Typography variant="h7" fontWeight="bold">
                      {t("langauge")}
                    </Typography>
                  )}
                  <Box display="flex" mt={2}>
                    {selectedEmployee?.languages?.map((language, i) => (
                      <Typography variant="h7" color="primary">
                        {i !== 0 && ", "}
                        {language.name}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <Box
                  data-aos={!lgDown && "fade-left"}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  py={12}
                  sx={{
                    position: "relative",
                    backgroundColor: (theme) => theme.palette.primary.main,
                    height: "100%",
                    width: lgDown ? "100%" : "100%",
                  }}
                >
                  <Typography
                    variant="h4"
                    color="white"
                    fontWeight="bold"
                    textAlign="center"
                  >
                    {selectedEmployee?.name}
                  </Typography>
                  <Typography
                    variant="h6"
                    color="white"
                    sx={{
                      position: "relative",
                      "&:after": {
                        backgroundColor: "white",
                        bottom: "-9px",
                        content: '" "',
                        height: "3px",
                        left: "15px",
                        position: "absolute",
                        width: 100,
                      },
                    }}
                  >
                    {selectedEmployee?.office?.name}
                  </Typography>
                  <Link
                    sx={{ textDecoration: "none" }}
                    href={`tel:${selectedEmployee?.phone || ""}`}
                  >
                    <Typography
                      variant="h6"
                      mt={5}
                      color="white"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PhoneAndroidIcon
                        sx={{
                          width: 18,
                          height: 18,
                          mr: 2,
                        }}
                      />
                      {selectedEmployee?.phone}
                    </Typography>
                  </Link>
                  <Link
                    sx={{ textDecoration: "none" }}
                    href={`mailto:${selectedEmployee?.email || ""}`}
                  >
                    <Typography
                      color="white"
                      variant="h6"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <EmailIcon
                        sx={{
                          width: 18,
                          height: 18,
                          mr: 2,
                        }}
                      />
                      {selectedEmployee?.email}
                    </Typography>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Container>
        <Box
          mt={-15}
          display="flex"
          justifyContent={mdDown ? "center" : "flex-start"}
          data-aos={!lgDown && "fade-up"}
        >
          <Box maxWidth={600}>
            <ScrollHere scrollName="contactEmployee" />
            <ContactUs employeeId={selectedEmployee?.id} borderRadius="0" />
          </Box>
        </Box>

        {selectedEmployee?.id && (
          <EmployeeRatings employeeId={selectedEmployee?.id} />
        )}
      </Container>
      <Grid mt={15}>
        <ScrollHere scrollName="gotoProperties" />
        {selectedEmployee?.office?.id && (
          <PropertyBrowse
            text={`${selectedEmployee?.name} ezeknél az ingatlanoknál tud neked segíteni:`}
            TextComponent={StyledTypographyUnderline}
            searchParam={{
              officeIds: [selectedEmployee?.office?.id],
              userId: selectedEmployee?.id,
            }}
          />
        )}
      </Grid>
      {openModals.includes("rateModal") && <RateModal employeeId={id} />}
    </>
  );
};

export default Employee;
