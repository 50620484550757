import { Box, Container, Grid, Typography } from '@mui/material';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PersonIcon from '@mui/icons-material/Person';
import LoopIcon from '@mui/icons-material/Loop';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SubjectIcon from '@mui/icons-material/Subject';
import PlayLessonIcon from '@mui/icons-material/PlayLesson';
import { useTranslation } from 'react-i18next';

function getId(index) {
  return `hitel-reason-${index}`;
}

const reasonIcons = [SavedSearchIcon, MoreTimeIcon, EmojiEventsIcon, PersonIcon, LoopIcon, AddReactionIcon, QuickreplyIcon, AccountBalanceIcon, SubjectIcon, PlayLessonIcon];

const HitelReasons = () => {
  const { t } = useTranslation();

  function translate(value) {
    return t(value) !== value ? t(value) : '';
  }

  return (
    <>
      <Container>
        <Box mt={20}>
          <Typography
            variant="h4"
            color="primary"
            textAlign="center"
          >
            {t('hitel.lidoReasons.description')}
          </Typography>
        </Box>
      </Container>
      <Grid mt={5}>
        <Box sx={{ backgroundColor: '#962F32', color: 'white', p: 5 }}>
          <Grid container>
            {reasonIcons.map((Icon, index) => (
              <Grid
                key={getId(index)}
                item
                xl={2.4}
                lg={2.4}
                md={4}
                sm={6}
                xs={12}
                p={4}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  ml={-5}
                  mb={3}
                >
                  <Icon style={{ height: 50, width: 50 }} />
                </Box>
                <Typography>
                  {t(`hitel.lidoReasons.${index + 1}`)}
                  {' '}
                  {translate(`hitel.lidoReasons.${index + 1},1`)}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default HitelReasons;
