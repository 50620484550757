import React, { useState } from 'react';
import { FormGroup, TextField, InputAdornment, Box } from '@mui/material';
import PropTypes from 'prop-types';
import NumberFormatCustom from './NumberFormatCustom';
import { styled } from '@mui/material/styles';

const StyledTextFieldLeft = styled(TextField)({
  background: 'white',
  borderTopLeftRadius: '10px',
  borderBottomLeftRadius: '10px',
  '& fieldset': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0,
  },
  "& input::placeholder": {
    fontSize: "13px",
  }
});

const StyledTextFieldRight = styled(TextField)({
  background: 'white',
  borderTopRightRadius: '10px',
  borderBottomRightRadius: '10px',
  '& fieldset': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: 0,
  },
  "& input::placeholder": {
    fontSize: "13px",
  }
});

const AOCMinMaxFormGroup = (
  {
    leftVariant,
    leftPlaceholder,
    leftLabel,
    rightVariant,
    rightPlaceholder,
    rightLabel,
    leftValue,
    rightValue,
    rightEndAndorment,
    leftEndAndorment,
    leftOnChange,
    rightOnChange,
    leftName,
    rightName,
    number,
    isLabelAnimation = true
  }
) => {
  const [focused, setFocused] = useState(false);
  // const [setHovered] = useState(false);

  const onFocus = () => {
    setFocused(true);
  };
  const onBlur = () => {
    setFocused(false);
  };

  return (
    <FormGroup
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        width: '100%'
      }}
      row
    >
      <StyledTextFieldLeft
        fullWidth
        onFocus={onFocus}
        onBlur={onBlur}
        focused={focused}
        // onMouseEnter={() => setHovered(true)}
        // onMouseLeave={() => setHovered(false)}
        variant={leftVariant}
        placeholder={isLabelAnimation ? leftPlaceholder : focused ? 'min' : leftLabel}
        name={leftName}
        label={isLabelAnimation ? leftLabel : ''}
        onChange={leftOnChange}
        value={leftValue}
        InputProps={{
          endAdornment: (
            <>
              {leftEndAndorment && (
                <InputAdornment
                  position="end"
                >
                  {leftEndAndorment && leftEndAndorment}
                </InputAdornment>
              )}
            </>
          ),
          inputComponent: number && NumberFormatCustom
        }}
      />
      <Box sx={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            color: 'black',
            bottom: -11,
            zIndex: 2
          }} // TODO: "-" should be visible on focus or if there is a value in one of the textfields
        >
          <>
            -
          </>
        </div>
      </Box>
      <StyledTextFieldRight
        fullWidth
        onFocus={onFocus}
        onBlur={onBlur}
        focused={focused}
        // onMouseEnter={() => setHovered(true)}
        // onMouseLeave={() => setHovered(false)}
        variant={rightVariant}
        name={rightName}
        placeholder={focused ? rightPlaceholder : ''}
        label={rightLabel}
        value={rightValue}
        onChange={rightOnChange}
        InputProps={rightEndAndorment && {
          endAdornment: (
            <>
              <InputAdornment
                position="end"
              >
                {rightEndAndorment}
              </InputAdornment>
            </>
          ),
          inputComponent: number && NumberFormatCustom
        }}
      />
    </FormGroup>
  );
};

/* NOTE: You can use it like this for example
                <AOCMinMaxFormGroup
                  leftVariant="outlined"
                  leftPlaceholder="min"
                  leftName="minPrice"
                  leftLabel="Ár"
                  leftOnChange={handleChange}
                  leftValue={values.minPrice}
                  rightOnChange={handleChange}
                  rightVariant="outlined"
                  rightName="maxPrice"
                  rightPlaceholder="max"
                  rightValue={values.maxPrice}
                  rightEndAndorment="Ft"
                  number
                />
 */

AOCMinMaxFormGroup.propTypes = {
  leftVariant: PropTypes.string,
  leftPlaceholder: PropTypes.string,
  leftLabel: PropTypes.string,
  rightVariant: PropTypes.string,
  rightPlaceholder: PropTypes.string,
  rightLabel: PropTypes.string,
  rightValue: PropTypes.any,
  leftValue: PropTypes.any,
  rightEndAndorment: PropTypes.any,
  leftEndAndorment: PropTypes.any,
  leftOnChange: PropTypes.func,
  rightOnChange: PropTypes.func,
  leftName: PropTypes.string,
  rightName: PropTypes.string,
  number: PropTypes.bool
};

export default AOCMinMaxFormGroup;
