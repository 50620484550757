import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import AnimatedNumber from "react-animated-numbers";
import { useTranslation } from "react-i18next";
import talkNumbersPic from "../../images/home/talkNumbersPic.png";
import PropTypes from "prop-types";
import Logo from "../../components/Logo";

const LetTheNumbersTalk = () => {
  const { t } = useTranslation("translation");
  const mobileDevice = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        mb: 10,
        backgroundImage: `url(${talkNumbersPic})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container>
        <Box py={mobileDevice ? 0 : 10}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            flexDirection="column"
          >
            <Box display="flex" alignItems="center">
              {!mobileDevice && <Logo home height={70} width={200} />}
              <Typography
                variant="h3"
                color="white"
                sx={{
                  position: "relative",
                  ml: mobileDevice ? 4 : 10,
                  my: mobileDevice && 2,
                  "&:after": {
                    backgroundColor: "white",
                    bottom: "-8px",
                    content: '" "',
                    height: "3px",
                    left: 0,
                    position: "absolute",
                    width: "200px",
                  },
                }}
              >
                <span style={{ fontWeight: 800 }}>{t("talk")}</span>{" "}
                {t("talkNumbers")}
              </Typography>
            </Box>
            <Grid container>
              <NumberWithDescription type="properties" />
              <NumberWithDescription type="colleges" />
              <NumberWithDescription type="experience" />
              <NumberWithDescription type="office" />
              <NumberWithDescription type="region" />
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default LetTheNumbersTalk;

const NumberWithDescription = ({ type }) => {
  const { t } = useTranslation("translation");

  return (
    <Grid
      item
      xs={6}
      md={2.3}
      my={10}
      pb={5}
      sx={{ borderRight: "1px solid white" }}
    >
      <Box
        textAlign="center"
        color="white"
        variant="h3"
        mb={5}
        sx={{
          borderRight: "1px solid white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h3" sx={{ pr: 1 }}>
          #
        </Typography>
        <AnimatedNumber
          animateToNumber={t(`${type}Numbers`)}
          fontStyle={{ fontSize: 40 }}
          configs={[
            { mass: 1, tension: 130, friction: 40 },
            { mass: 2, tension: 140, friction: 40 },
            { mass: 3, tension: 130, friction: 40 },
          ]}
        />
      </Box>
      <Typography textAlign="center" color="white">
        {t(`${type}Text`)}
      </Typography>
    </Grid>
  );
};

NumberWithDescription.propTypes = {
  type: PropTypes.string.isRequired,
};
