import { React, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, experimentalStyled, Collapse, Slide, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DashboardNavbarToolbar from './DashboarNavbarToolbar';

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.navigation.background,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: 1000
}));

const DashboardNavbar = (props) => {
  const location = useLocation();
  const { onSidebarMobileOpen, ...other } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'navigation' });
  const [isVisible, setIsVisible] = useState(false);
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const xlUp = useMediaQuery((theme) => theme.breakpoints.up('xl'));

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () => setIsVisible(window.pageYOffset > 100));
    }
  }, []);

  const leftNavigationData = [
    {
      title: 'LeftLido',
      items: [
        {
          title: t('properties'),
          path: '/properties',
        },
        {
          title: t('projects.projects'),
          children: [
            {
              title: t('projects.investment'),
              path: '/projects/investment'
            },
            {
              title: t('projects.allProjects'),
              path: '/projects/allProjects'
            },
            {
              title: t('projects.references'),
              path: '/projects/references'
            }
          ]
        },
        {
          title: t('loan'),
          path: '/loan',
        },
      ]
    }
  ];

  const rightNavigationData = [
    {
      title: 'rightLido',
      items: [
        {
          title: t('offices'),
          path: '/offices',
        },
        {
          title: t('career'),
          path: '/career',
        },
        {
          title: 'Blog',
          path: '/blog',
        },
        {
          title: t('other.industry'),
          children: [
            {
              title: t('other.invest'),
              path: '/other/invest'
            },
            {
              title: t('other.partner'),
              path: '/other/partner'
            },
            {
              title: t('other.resort'),
              path: '/other/resort',
              disabled: true
            },
            {
              title: t('other.property'),
              path: '/other/property',
              disabled: true
            },
          ]
        },
        {
          title: t('aboutUs'),
          path: '/aboutUs',
        },
      ]
    }
  ];
  return (
    <DashboardNavbarRoot {...other}>
      {location.pathname === '/' && lgUp ? (
        <Collapse in={isVisible}>
          <Slide
            direction="down"
            in={isVisible}
          >
            <div>
              <DashboardNavbarToolbar
                onSidebarMobileOpen={onSidebarMobileOpen}
                leftNavigationData={leftNavigationData}
                rightNavigationData={rightNavigationData}
              />
            </div>
          </Slide>
        </Collapse>
      ) : (
        <DashboardNavbarToolbar
          onSidebarMobileOpen={onSidebarMobileOpen}
          leftNavigationData={leftNavigationData}
          rightNavigationData={rightNavigationData}
        />
      )}
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
