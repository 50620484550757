import React, { useEffect, useCallback } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import AOCTextfield from '../utils/AOCTextfield';
import { useDispatch, useSelector } from '../app/store';
import { operateGeneralArray, passwordReset } from '../app/slices/property';
import AOCPasswordTextfield from '../utils/AOCPasswordTextfield';
import { Link, useParams } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const PasswordResetForm = () => {
  const { uuid } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { generalArray } = useSelector((state) => state.property);
  const { executeRecaptcha } = useGoogleReCaptcha();

  console.log(generalArray, 'generalArray');

  useEffect(() => dispatch(operateGeneralArray('', 'reset')), []);

  const handleReCaptchaVerify = useCallback(async (values) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const newToken = await executeRecaptcha('yourAction');
    const sendData = {
      email: values.email,
      password: values.password,
      googleVerificationToken: newToken,
    };

    // Do whatever you want with the token
    dispatch(passwordReset(uuid, sendData));
  }, [executeRecaptcha]);

  return (
    <Grid>
      <Formik
        initialValues={{
          email: '',
          password: '',
          confirmPassword: '',
        }}
        validationSchema={Yup
          .object()
          .shape({
            email: Yup.string().required('Mező kitöltése kötelező').email('Az e-mail cím formátuma nem megfelelő'),
            password: Yup.string().required('Jelszó megadása kötelező').min(8, 'A jelszónak minimum 8 karakter hosszúnak kell lennie'),
            confirmPassword: Yup.string().required('Jelszó megerősítése kötelező')
              .oneOf([Yup.ref('password'), null], 'A jelszavak nem egyeznek')
          })}
        onSubmit={(values) => {
          handleReCaptchaVerify(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <AOCTextfield
                  onBlur={handleBlur}
                  onChange={handleChange}
                  values={values}
                  touched={touched}
                  errors={errors}
                  name="email"
                  label={t('Email')}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <AOCPasswordTextfield
                  onBlur={handleBlur}
                  onChange={handleChange}
                  values={values}
                  touched={touched}
                  errors={errors}
                  name="password"
                  label={t('NewPassword')}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <AOCPasswordTextfield
                  onBlur={handleBlur}
                  onChange={handleChange}
                  values={values}
                  touched={touched}
                  errors={errors}
                  name="confirmPassword"
                  label={t('confirmPassword')}
                />
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
              >
                <Button
                  color="primary"
                  // fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {t('Send')}
                </Button>
              </Grid>
              {generalArray.length > 0 && (
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  py={5}
                >
                  <Typography>
                    {generalArray.includes('password_change_success') && t('password_change_success')}
                    {generalArray.includes('password_change_failure') && !generalArray.includes('password_change_success') && t('password_change_failure')}

                  </Typography>
                  <Button
                    component={Link}
                    to="/"
                    color="primary"
                    variant="outlined"
                    sx={{
                      mt: 2
                    }}
                  >
                    Vissza a főoldalra
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        )}
      </Formik>
    </Grid>
  );
};

export default PasswordResetForm;
