import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const AOCPasswordTextfield = (props) => {
  const { name, label, onBlur, onChange, values, touched, errors } = props;
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      fullWidth
      error={Boolean(touched[name] && errors[name])}
      helperText={touched[name] && errors[name]}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={label}
      value={values[name]}
      name={`${name}`}
      label={label}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        autoComplete: 'new-password',
        form: {
          autoComplete: 'off',
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={(event) => event.preventDefault()}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>)
      }}
    />
  );
};

export default AOCPasswordTextfield;

AOCPasswordTextfield.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
