import { Box, CardMedia, Grid, Skeleton, Stack, Typography, useMediaQuery } from '@mui/material';
import { selectLoading } from '../../app/slices/loading';
import { useDispatch, useSelector } from '../../app/store';
import HighlightedPropertyCard from '../../components/HighlightedPropertyCard';
import indaQuarterRight from '../../images/footer/indaQuarterRight.png';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { getHighlightedPropertiesPage } from '../../app/slices/property';
import oldRedLHLogo from '../../images/oldRedLHLogo.png';
import ParallaxSection from '../../components/ParallaxSection';
import featuredParallax from '../../images/home/featuredParallax.jpg';

const numberOfProperties = 6;

const HighlightedProperties = () => {
  const { t } = useTranslation();
  const isLoadingArray = useSelector(selectLoading);
  const dispatch = useDispatch();
  const { highlightedProperties } = useSelector((state) => state.property);
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  useEffect(() => {
    dispatch(getHighlightedPropertiesPage('', 1, numberOfProperties));
  }, []);

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          height: '50%',
          width: '50%',
          position: 'absolute',
          backgroundColor: '#962F32',
          opacity: 0 // TODO: doesn't work
        }}
      />

      {!lgDown && (
        <Box sx={{ position: 'relative', zIndex: 0 }}>
          <img
            id="indaLeft"
            alt="indaLeft"
            style={{
              height: '301px',
              width: 'auto',
              top: -150,
              left: 0,
              position: 'absolute',
              transform: 'scaleX(-1)',
            }}
            src={indaQuarterRight}
          />
        </Box>
      )}
      <ParallaxSection bg={featuredParallax}>
        <Box sx={{ pt: 5, mb: 5 }}>
          <Stack
            mb={3}
          >
            <Box
              display="flex"
              alignItems="center"
              sx={{
                position: 'relative',
                '&:after': {
                  backgroundColor: '#962F32',
                  bottom: '-8px',
                  content: '" "',
                  height: '3px',
                  left: '-20px',
                  position: 'absolute',
                  width: '150px',
                }
              }}
            >
              <CardMedia
                image={oldRedLHLogo}
                sx={{
                  height: '50px',
                  width: '50px',
                  mr: 2
                }}
              />
              <Typography
                variant="h4"
                color="primary"
                sx={{
                  fontWeight: 800,
                  position: 'relative',
                  textTransform: 'uppercase',
                }}
              >
                {t('navigation.highlightedProps.main')}
              </Typography>
            </Box>
            <Typography
              color="primary"
              variant="h5"
              fontWeight="bold"
              sx={{
                top: '-10px',
                position: 'relative',
                left: '140px'
              }}
            >
              {t('navigation.highlightedProps.sub')}
            </Typography>
          </Stack>
        </Box>
        <Grid
          container
          spacing={3}
        >
          {!isLoadingArray.includes('GET_HIGHLIGHTED_PROPERTIES_PAGE') ? (
            <>
              {highlightedProperties.data?.map((property, i) => (
                <Grid
                  item
                  key={property.id || i}
                  xs={12}
                  md={4}
                  data-aos={!lgDown && 'fade-zoom-in'}
                  data-aos-delay={i * 100}
                >
                  <HighlightedPropertyCard
                    property={property}
                    index={i}
                  />
                </Grid>
              ))}
            </>
          ) : (
            <>
              {[...Array(6)].map((_, i) => (
                <Grid
                  item
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  xs={12}
                  md={4}
                >
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height="280px"
                  />
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </ParallaxSection>
    </Box>
  );
};

export default HighlightedProperties;
