import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Card, CardContent, Grid, Pagination, Typography } from '@mui/material';
import axiosConfig from '../axiosConfig';
import StyledTypographyUnderline from '../utils/StyledTypographyUnderline';
import { useDispatch } from '../app/store';
import { switchModal } from '../app/slices/property';
import { useTranslation } from 'react-i18next';
import ScrollHere from '../utils/ScrollHere';

async function fetchEmployeeRatings(id, pageNumber) {
  const PAGESIZE = 3;

  return axiosConfig
    .get(`/users/${id}/ratings?pageSize=${PAGESIZE}&pageNumber=${pageNumber - 1}`)
    .then((response) => response.data);
}

const EmployeeRatings = ({ employeeId }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [ratings, setRatings] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();
  const customerRatingsRef = useRef(null);
  const { t } = useTranslation('translation');

  useEffect(() => {
    fetchEmployeeRatings(employeeId, pageNumber).then((response) => {
      setRatings(response.data);
      setTotalPages(response.totalPages);
    });
  }, [pageNumber]);

  function getHiddenName(name) {
    const nameArray = name.split(' ');
    return [nameArray[0].charAt(0), nameArray[1]].join('. ');
  }

  const handleModalOpen = () => dispatch(switchModal('rateModal', true));

  const forceScroll = () => {
    if (customerRatingsRef !== null) {
      customerRatingsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <ScrollHere scrollName="gotoRatings" />
      <Box ref={customerRatingsRef} />
      <StyledTypographyUnderline
        mt={5}
      >
        {t('customerRatings')}
      </StyledTypographyUnderline>

      <Grid mt={5}>
        <Grid
          gap={3}
          display="flex"
          flexDirection="column"
        >
          {ratings.map(({ name, rating }) => (
            <Grid
              display="flex"
              gap={2}
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems={{ xs: 'flex-start', md: 'center' }}
            >
              <Typography
                variant="body1"
                minWidth="150px"
                maxWidth="150px"
                textAlign={{ xs: 'left', md: 'right' }}
                sx={{ fontWeight: 'bold' }}
              >
                {getHiddenName(name)}
              </Typography>
              <Card sx={{ width: '100%' }}>
                <CardContent>
                  <Typography
                    variant="h6"
                    p={1}
                  >
                    {rating}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={5}
      >
        {totalPages > 1 && (
          <Pagination
            size="medium"
            count={totalPages}
            page={pageNumber}
            onChange={(_, nextPage) => {
              setPageNumber(nextPage);
              forceScroll();
            }}
          />
        )}

        <Button
          variant="contained"
          size="large"
          sx={{
            mt: 5,
            fontSize: '1.1em',
          }}
          onClick={handleModalOpen}
        >
          {t('rateOurColleaguesWork')}
        </Button>
      </Grid>
    </>
  );
};

export default EmployeeRatings;

EmployeeRatings.propTypes = {
  employeeId: PropTypes.number.isRequired,
};
