import React, { useEffect, useState, useRef } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import { Formik } from 'formik';
import { useDispatch, useSelector } from '../app/store';
import '../App.css';
import {
  clearPropertiesData,
  getLocationOptions,
  searchProperty,
  setInitialShapesValue,
  setPropertySearchData
} from '../app/slices/property';
import MapTest from './MapTest';
import { useNavigate } from 'react-router';
import { useLocation, createSearchParams, useSearchParams } from 'react-router-dom';
import AOCAutocomplete from '../utils/AOCAutocomplete';
import { selectLoading } from '../app/slices/loading';
import AOCMinMaxFormGroup from '../utils/AOCMinMaxFormGroup';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const checkboxWidth = 2;
const checkboxWidthPhone = 7;
const pageSize = 24;

const initialValues = {
  transactionType: '1',
  propertyType: null,
  cities: [],
  salePriceFrom: '',
  salePriceTo: '',
  remtPriceFrom: '',
  remtPriceTo: '',
  floorAreaFrom: '',
  floorAreaTo: '',
  roomCountFrom: '',
  roomCountTo: '',
  newConstruction: false,
  floorLevelFrom: '',
  floorLevelTo: '',
  constructionType: '',
  internalCondition: [],
  externalCondition: [],
  view: [],
  parking: [],
  accessibility: '',
  furnished: '',
  electricity: [],
  water: [],
  gas: [],
  sewer: [],
  heating: [],
  cooling: '',
  tv: '',
  internet: '',
  waterFront: false,
  movable: false,
  video: false,
  investment: false,
  privateGarden: false,
  energySave: false,
  exclusive: false,
};
const MainSearch = ({ setPage, page, setPropertyArray, selectedSort, scrollOnSubmit }) => {
  const { attributes } = useSelector((state) => state.property);
  const isLoadingArray = useSelector(selectLoading);
  const { locationOptions, initialShapesValue } = useSelector((state) => state.property);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [citySearchName, setCitySearchName] = useState();
  const [locationOpen, setLocationOpen] = useState(false);
  const [response, setResponse] = useState([]);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentPath = useLocation();
  const pathname = decodeURI(currentPath && currentPath.pathname);
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [hovered, setHovered] = useState(false);
  const formikRef = useRef();
  const [isNewCreated, setIsNewCrated] = useState(false);
  const mapRef = useRef(null);
  const isProperties = pathname === '/properties';
  const defaultBoxShadow = isProperties ? 'rgba(0, 0, 0, 0.22) 0px 1px 3px 0px, rgba(27, 31, 35, 0.11) 0px 0px 0px 1px' : 'rgba(0, 0, 0, 0.24) 0px 3px 8px';
  const bgColor = isProperties ? '#962F32' : 'white';

  const scrollToMap = () => {
    mapRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const getShapes = (value) => {
    let resultArray = [];
    if (value !== '') {
      const shapesArray = value?.split(',');
      if (shapesArray?.length <= 3 && shapesArray?.length > 0) {
        resultArray = [[Number(shapesArray[0]), Number(shapesArray[1])], Number(shapesArray[2])];
      }
      if (shapesArray?.length > 3) {
        shapesArray.forEach((_, i) => {
          if (i % 2 !== 0) {
            resultArray.push([shapesArray[i - 1], shapesArray[i]]);
          }
        });
      }
    }
    return resultArray && resultArray.length > 0 ? resultArray : [];
  };

  const [shapes, setShapes] = useState(getShapes(initialShapesValue || searchParams.get('shapes')));
  const [newShapes, setNewShapes] = useState([]);

  const propertyTypeList = attributes?.residential && attributes?.agricultural && attributes?.business && attributes?.industrial && [...attributes.residential, ...attributes.agricultural, ...attributes.business, ...attributes.industrial];

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (citySearchName) {
        dispatch(getLocationOptions(citySearchName));
      }
    }, 400);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [citySearchName]);

  const handleLocationOpen = () => setLocationOpen(true);
  const handleLocationClose = () => setLocationOpen(false);

  const handleSearchButton = () => (expanded || lgDown);

  const getPropertyTypeObject = (_, attributeValue) => {
    const returnValue = propertyTypeList?.find((option) => option.name === attributeValue);
    return returnValue || null;
  };

  const getAttributeOptionsObject = (attributeName, attributeValues) => {
    if (attributeName && attributeValues) {
      const attributeValuesArray = attributeValues.split(',');
      const returnValue = attributes[attributeName]?.filter((option) => attributeValuesArray.includes(option.name));
      return returnValue;
    }
    return [];
  };

  const getCitiesObject = (cityNames) => {
    if (cityNames) {
      let cityNamesArray = cityNames?.split(',');
      cityNamesArray = cityNamesArray.map((name) => (
        { name }
      ));
      return cityNamesArray;
    }
    return [];
  };

  const getBoolValue = (val) => !!JSON.parse(String(val).toLowerCase());

  const getInitialValues = (param) => {
    if (param === 'clear') {
      return initialValues;
    }
    if (searchParams) {
      return { // FIXME: it should just mix two object the paramValues with the initialValues probably there is a better solution for it
        transactionType: searchParams.get('transactionType') || '1',
        propertyType: getPropertyTypeObject('propertyType', searchParams.get('propertyType')),
        cities: getCitiesObject(searchParams.get('cities')),
        salePriceFrom: searchParams.get('salePriceFrom') || '',
        rentPriceFrom: searchParams.get('rentPriceFrom') || '',
        salePriceTo: searchParams.get('salePriceTo') || '',
        rentPriceTo: searchParams.get('rentPriceTo') || '',
        floorAreaFrom: searchParams.get('floorAreaFrom') || '',
        floorAreaTo: searchParams.get('floorAreaTo') || '',
        roomCountFrom: searchParams.get('roomCountFrom') || '',
        roomCountTo: searchParams.get('roomCountTo') || '',
        newConstruction: searchParams.get('newConstruction') || false,
        floorLevelFrom: searchParams.get('floorLevelFrom') || '',
        floorLevelTo: searchParams.get('floorLevelTo') || '',
        constructionType: searchParams.get('constructionType') || '',
        internalCondition: getAttributeOptionsObject('internalCondition', searchParams.get('internalCondition')),
        externalCondition: getAttributeOptionsObject('externalCondition', searchParams.get('externalCondition')),
        view: getAttributeOptionsObject('view', searchParams.get('view')),
        parking: getAttributeOptionsObject('parking', searchParams.get('parking')),
        accessibility: searchParams.get('accessibility') || '',
        furnished: searchParams.get('furnished') || '',
        electricity: getAttributeOptionsObject('electricity', searchParams.get('electricity')),
        water: getAttributeOptionsObject('water', searchParams.get('water')),
        gas: getAttributeOptionsObject('gas', searchParams.get('gas')),
        sewer: getAttributeOptionsObject('sewer', searchParams.get('sewer')),
        heating: getAttributeOptionsObject('heating', searchParams.get('heating')),
        cooling: searchParams.get('cooling') || '',
        tv: searchParams.get('tv') || '',
        internet: searchParams.get('internet') || '',
        officeIds: searchParams.get('officeIds') || null,
        waterFront: getBoolValue(searchParams.get('waterFront')) || false,
        movable: getBoolValue(searchParams.get('movable')) || false,
        video: getBoolValue(searchParams.get('video')) || false,
        investment: getBoolValue(searchParams.get('investment')) || false,
        privateGarden: getBoolValue(searchParams.get('privateGarden')) || false,
        energySave: getBoolValue(searchParams.get('energySave')) || false,
        exclusive: getBoolValue(searchParams.get('exclusive')) || false,
        userId: searchParams.get('userId') || null,
      };
    }
    return initialValues;
  };

  const handleUrlParamsOnSubmit = (values) => {
    const urlValues = Object.fromEntries(Object.entries(values).filter(([, v]) => v?.length !== 0 && v !== false)); // NOTE: removing those values from formik values, which are empty or false
    if (Object.keys(urlValues)?.length !== 0) {
      Object.entries(urlValues).forEach(([k, v]) => { /* NOTE: if there are any object array in them it creates a key: 'string, string', data structure from them. */
        if (Array.isArray(v)) {
          const stringData = v.map(({ name }) => `${name}`).join(',');
          urlValues[k] = stringData;
        }
      });
      urlValues.pageNumber = 1; /* NOTE: onSubmit it adds the pageNumber=1 search param to it. */
      navigate({
        pathname: '/properties',
        search: `?${createSearchParams(urlValues)}`,
      });
    }
  };

  const getCitiesString = (cities) => {
    const citiesArray = [];
    cities.forEach((city) => {
      citiesArray.push(city.name);
    });
    return citiesArray.join(',');
  };

  const handlePrice = (transactionTypeValue, salePriceValue, rentPriceValue) => {
    if (transactionTypeValue) {
      if (transactionTypeValue === '1') {
        return salePriceValue ? Number(salePriceValue) * 1000000 : null;
      }
      if (transactionTypeValue === '2') {
        return rentPriceValue ? Number(rentPriceValue) * 1000 : null;
      }
    }
    return null;
  };

  const handlePublicTag = (tagValue) => {
    const publicTag = attributes?.publicTags?.find((tag) => tag.name === tagValue);
    return publicTag?.id || null;
  };

  const search = (data, submit) => {
    const polycircle = {};
    /* eslint-disable */
    if (isNewCreated) {
      if (newShapes.length < 3 && newShapes.length > 0) {
        polycircle.circleCenter = newShapes[0];
        polycircle.circleRadius = newShapes[1];
      }
      if (newShapes.length >= 3) {
        polycircle.polycoords = newShapes;
      }
    } else {
      if (shapes.length < 3 && shapes.length > 0) {
        polycircle.circleCenter = shapes[0];
        polycircle.circleRadius = shapes[1];
      }
      if (shapes.length >= 3) {
        polycircle.polycoords = shapes;
      }
    }
    /* eslint-enable */
    const dataResult = {
      transactionType: data?.transactionType || null,
      cities: getCitiesString(data?.cities) || null,
      salePriceFrom: handlePrice(data?.transactionType, data.salePriceFrom, null),
      salePriceTo: handlePrice(data?.transactionType, data.salePriceTo, null),
      rentPriceFrom: handlePrice(data?.transactionType, null, data.rentPriceFrom),
      rentPriceTo: handlePrice(data?.transactionType, null, data.rentPriceTo),
      floorAreaFrom: Number(data?.floorAreaFrom) || null,
      floorAreaTo: Number(data.floorAreaTo) || null,
      roomCountFrom: Number(data.roomCountFrom) || null,
      roomCountTo: Number(data.roomCountTo) || null,
      floorLevelFrom: Number(data.floorLevelFrom) || null,
      floorLevelTo: Number(data.floorLevelTo) || null,
      circleCenter: polycircle.circleCenter,
      circleRadius: polycircle.circleRadius,
      polygon: polycircle.polycoords,
      officeIds: data.officeIds && data.officeIds !== 'null' ? [data.officeIds] : null,
      userId: data.userId || null,
      attributeOptionIds:
        [
          data.propertyType?.id || null,
          data.constructionType || null,
          ...data.internalCondition.map((item) => item.id),
          ...data.externalCondition.map((item) => item.id),
          ...data.view.map((item) => item.id),
          ...data.parking.map((item) => item.id),
          ...data.parking.map((item) => item.id),
          data.accessibility || null,
          data.furnished || null,
          ...data.electricity.map((item) => item.id),
          ...data.water.map((item) => item.id),
          ...data.gas.map((item) => item.id),
          ...data.sewer.map((item) => item.id),
          ...data.heating.map((item) => item.id),
          data.cooling || null,
          data.tv || null,
          data.internet || null,
          data?.newConstruction ? handlePublicTag('Új építésű') : null,
          data?.waterFront ? handlePublicTag('Vízparti') : null,
          data?.movable ? handlePublicTag('Azonnal költözhető') : null,
          data?.video ? handlePublicTag('Videó elérhető') : null,
          data?.investment ? handlePublicTag('Befektetésre') : null,
          data?.privateGarden ? handlePublicTag('Saját kert') : null,
          data?.energySave ? handlePublicTag('Energiatakarékos') : null,
          data?.exclusive ? handlePublicTag('Kizárólag nálunk') : null,

        ],
      currency: 'Ft' // TODO: filter for eur
    };
    dispatch(setPropertySearchData(dataResult)); /* NOTE: save search dataResult for page change */
    if (submit) { /* NOTE: onsubmit it should get the first page */
      const url = new URL(window.location);
      if (isNewCreated) {
        url.searchParams.set('shapes', newShapes.toString());
        window.history.pushState({}, '', url);
      } else {
        url.searchParams.set('shapes', shapes.toString());
        window.history.pushState({}, '', url);
      }
      dispatch(clearPropertiesData());
      url.searchParams.set('pageNumber', 1);
      window.history.pushState({}, '', url);

      dispatch(searchProperty(dataResult, 0, pageSize, selectedSort));
      if (expanded) {
        setExpanded(false);
      }
      if (pathname.includes('/properties')) {
        setPropertyArray([...Array(24)]);
      }
    } else { /* NOTE: on useEffect it shoudl get the page from url, and now use effect use this search function */
      dispatch(searchProperty(dataResult, page - 1, pageSize, selectedSort));
    }
  };

  useEffect(() => {
    // if (pathname.includes('/properties')) {
    const searchParamsInitialValues = getInitialValues();
    search(searchParamsInitialValues); // NOTE: when mounting the properties page we get the properties with the search function
    // }
  }, []);

  const handleReset = (setValues) => { // TODO: clear url
    setShapes([]); /* NOTE: clear map values */
    setNewShapes([]);
    setValues(getInitialValues('clear')); // NOTE: "clear" is clearing the initialValues and not getting them from url
    dispatch(clearPropertiesData());
    dispatch(setInitialShapesValue(null));
    dispatch(searchProperty({}, 0, pageSize, selectedSort));
    setPropertyArray([...Array(24)]);
  };

  const handleFormikValuesChange = (name, value) => { /* NOTE: this handles formik values and put them in the url */
    formikRef?.current?.setFieldValue(name, value);
    const url = new URL(window.location);
    if (Array.isArray(value)) { // FIXME: i don't get it cuz it should be an array and below it is working like an array but "type of" says it is an object
      const stringData = value.map((valueName) => `${valueName.name}`).join(',');
      url.searchParams.set(name, stringData);
      window.history.pushState({}, '', url);
    } else {
      url.searchParams.set(name, value);
      window.history.pushState({}, '', url);
    }
  };
  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 101,
        backgroundColor: 'white',
        borderRadius: '10px',
        border: pathname === '/properties' && '2px solid #962F32',
        boxShadow: expanded ? 'rgba(0, 0, 0, 0.24) 0px 3px 8px' : defaultBoxShadow
      }}
    >
      <Formik
        innerRef={formikRef}
        initialValues={getInitialValues()}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          if (!pathname.includes('/properties')) {
            handleUrlParamsOnSubmit(values);
          }
          if (setPage) {
            setPage(1);
          }
          search(values, 'submit');
          setSubmitting(false);
          setExpanded(false);
          setTimeout(() => {
            if (scrollOnSubmit) {
              scrollOnSubmit();
            }
          }, 700);
        }}
      >
        {({
          errors,
          handleBlur,
          setFieldValue,
          setValues,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              p={1}
              sx={{
                backgroundColor: bgColor,
                borderRadius: isProperties ? '5px' : '10px'
              }}
            >
              <Grid
                container
                spacing={1}
              >
                <Grid
                  item
                  xl={0.8}
                  lg={0.8}
                  md={1.2}
                  sm={2}
                  xs={12}
                >
                  <FormControl>
                    <RadioGroup
                      name="transactionType"
                      onChange={(e) => handleFormikValuesChange(e.target.name, e.target.value)}
                      value={values.transactionType}
                      sx={{ color: 'black' }}
                    >
                      <FormControlLabel
                        sx={{
                          mb: -1,
                          color: isProperties && 'white'
                        }}
                        value={1}
                        control={(
                          <Radio
                            sx={{
                              color: isProperties && 'white',
                              '&.Mui-checked': {
                                color: isProperties && 'white'
                              }
                            }}
                          />
                        )}
                        label="Eladó"
                      />
                      <FormControlLabel
                        sx={{
                          mt: -1,
                          color: isProperties && 'white'
                        }}
                        value={2}
                        control={(
                          <Radio
                            sx={{
                              color: isProperties && 'white',
                              '&.Mui-checked': {
                                color: isProperties && 'white'
                              }
                            }}
                          />
                        )}
                        label="Kiadó"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xl={1.7}
                  lg={1.7}
                  md={2.9}
                  sm={10}
                  xs={12}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <AOCAutocomplete
                    fullWidth
                    sx={{ background: 'white', borderRadius: '10px' }}
                    disableCloseOnSelect
                    options={propertyTypeList?.filter((filteredOption) => filteredOption.enabled).map((option) => option)}
                    value={values.propertyType}
                    onChange={(_, newValue) => {
                      setFieldValue('propertyType', newValue);
                      const url = new URL(window.location);
                      url.searchParams.set('propertyType', newValue?.name || '');
                      window.history.pushState({}, '', url);
                    }}
                    label={isProperties ? "" : "Válassz típust"}
                    placeholder={isProperties ? "Válassz típust" : ""}
                  />
                </Grid>
                <Grid
                  item
                  xl={expanded ? 4 : 3.5}
                  lg={expanded ? 3.9 : 3.5}
                  md={7.9}
                  sm={6}
                  xs={12}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                >
                  <AOCAutocomplete
                    fullWidth
                    multiple
                    options={locationOptions}
                    open={false} /* NOTE: onClick func opens a dialog which handles the autocomplete values therefore this one never should opened */
                    readOnly
                    // disabled
                    limitTags={pathname === '/' && smDown ? 1 : 5}
                    textFieldOnChange={(event) => setCitySearchName(event.target.value)}
                    value={values.cities}
                    label={isProperties ? "" : "Hol keresel?"}
                    placeholder={isProperties ? "Hol keresel?" : ""}
                    popupIcon={<LocationOnIcon />}
                    // loadingArray={isLoadingArray.includes('GET_LOCATION_OPTIONS')}
                    sx={{
                      cursor: 'pointer',
                      background: 'white',
                      borderRadius: '10px',
                      '& .MuiChip-root.Mui-disabled': {
                        opacity: 1,
                        pointerEvents: 'auto',
                      },
                      '& .MuiInputLabel-root.Mui-disabled': {
                        color: '#616161',
                      },
                      '& .MuiOutlinedInput-root': {
                        flexWrap: 'nowrap',
                        overflow: 'hidden',
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: (theme) => (hovered && theme.palette.text.primary),
                        }
                      },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleLocationOpen();
                    }}
                    onFocus={(e) => {
                      e.target.blur();
                    }}
                    onChange={(_, newValue) => {
                      if (typeof newValue[newValue.length - 1] === 'object' || newValue.length === 0) {
                        setFieldValue('cities', [
                          ...newValue
                        ]);
                      }
                    }}
                  />
                  {values.cities.length > 0 && (
                    <Box sx={{ position: 'relative', flexWrap: 'nowrap' }}>
                      <Box
                        onClick={handleLocationOpen}
                        sx={{
                          position: 'absolute',
                          right: 0,
                          top: values.cities.length === 0 ? -27 : -28,
                          height: values.cities.length === 0 ? '54.5px' : '56px',
                          backgroundColor: 'rgba(150, 47, 50, .7)',
                          backdropFilter: 'blur(15px)',
                          padding: 1,
                          borderTopRightRadius: '10px',
                          borderBottomRightRadius: '10px',
                          cursor: 'pointer',
                          textTransform: 'uppercase',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          fullWidth
                          color="white"
                          textAlign="center"
                        >
                          +
                        </Typography>
                        <Typography
                          fullWidth
                          color="white"
                          textAlign="center"
                          sx={{ mr: 0.5 }}
                        >
                          {values.cities.length}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Grid>
                <Grid
                  item
                  xl={1.9}
                  lg={1.9}
                  md={4.1}
                  sm={6}
                  xs={12}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {values.transactionType === '2' ? (
                    <AOCMinMaxFormGroup
                      isLabelAnimation={!isProperties}
                      leftVariant="outlined"
                      leftPlaceholder="min"
                      leftName="rentPriceFrom"
                      leftLabel="Ár ezer ft"
                      leftOnChange={(e) => handleFormikValuesChange(e.target.name, e.target.value)}
                      leftValue={values.rentPriceFrom}
                      rightOnChange={(e) => handleFormikValuesChange(e.target.name, e.target.value)}
                      rightVariant="outlined"
                      rightName="rentPriceTo"
                      rightPlaceholder="max"
                      rightValue={values.rentPriceTo}
                      rightEndAndorment="Ft"
                      number
                    />
                  ) : (
                    <AOCMinMaxFormGroup
                      isLabelAnimation={!isProperties}
                      leftVariant="outlined"
                      leftPlaceholder="min"
                      leftName="salePriceFrom"
                      leftLabel="Ár millió ft"
                      leftOnChange={(e) => handleFormikValuesChange(e.target.name, e.target.value)}
                      leftValue={values.salePriceFrom}
                      rightOnChange={(e) => handleFormikValuesChange(e.target.name, e.target.value)}
                      rightVariant="outlined"
                      rightName="salePriceTo"
                      rightPlaceholder="max"
                      rightValue={values.salePriceTo}
                      rightEndAndorment="Ft"
                      number
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xl={2}
                  lg={2}
                  md={4.3}
                  sm={6}
                  xs={12}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <AOCMinMaxFormGroup
                    isLabelAnimation={!isProperties}
                    leftVariant="outlined"
                    leftPlaceholder="min"
                    leftName="floorAreaFrom"
                    leftLabel="Alapterület"
                    leftOnChange={(e) => handleFormikValuesChange(e.target.name, e.target.value)}
                    leftValue={values.floorAreaFrom}
                    rightOnChange={(e) => handleFormikValuesChange(e.target.name, e.target.value)}
                    rightVariant="outlined"
                    rightName="floorAreaTo"
                    rightPlaceholder="max"
                    rightValue={values.floorAreaTo}
                    rightEndAndorment="m&sup2;"
                    number
                  />
                </Grid>
                <Grid
                  item
                  xl={1.5}
                  lg={1.5}
                  md={3.6}
                  sm={6}
                  xs={12}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <AOCMinMaxFormGroup
                    isLabelAnimation={!isProperties}
                    leftVariant="outlined"
                    leftPlaceholder="min"
                    leftName="roomCountFrom"
                    leftLabel="Szobák"
                    leftOnChange={(e) => handleFormikValuesChange(e.target.name, e.target.value)}
                    leftValue={values.roomCountFrom}
                    rightOnChange={(e) => handleFormikValuesChange(e.target.name, e.target.value)}
                    rightVariant="outlined"
                    rightName="roomCountTo"
                    rightPlaceholder="max"
                    rightValue={values.roomCountTo}
                  />
                </Grid>
                {!expanded && !lgDown && (
                  <Grid
                    item
                    xl={0.6}
                    lg={0.6}
                    md={0.7}
                    sm={6}
                    xs={12}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <IconButton
                      type="submit"
                      size="large"
                      disabled={isSubmitting}
                      sx={{
                        fontSize: 16,
                        backgroundColor: '#777777',
                        borderRadius: '10px',
                        border: '1px solid white',
                        color: 'white',
                        '&:hover, &.Mui-focusVisible': {
                          backgroundColor: '#777777'
                        },
                        '& .MuiTouchRipple-child': { borderRadius: '11px' }
                      }}
                    >
                      <SearchIcon fontSize="medium" />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
              <Grid
                container
              >
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    flexDirection: { sm: 'row', xs: 'column' },
                    justifyContent: { sm: 'flex-end', xs: 'center' },
                    alignItems: 'flex-end'
                  }}
                  xs={12}
                >
                  <FormControlLabel
                    sx={{
                      color: isProperties && 'white'
                    }}
                    control={(
                      <Checkbox
                        onBlur={handleBlur}
                        checked={Boolean(values.newConstruction)}
                        onChange={() => handleFormikValuesChange('newConstruction', !values.newConstruction)}
                        name="newConstruction"
                        sx={{
                          color: isProperties && 'white',
                          '&.Mui-checked': {
                            color: isProperties && 'white'
                          }
                        }}
                      />
                    )}
                    label="Újépítésű"
                  />
                  <Button
                    sx={{
                      color: isProperties ? 'white' : '#962F32',
                      '&:hover, &.Mui-focusVisible': {
                        backgroundColor: isProperties ? 'white' : '#962F32',
                        color: isProperties ? '#962F32' : 'white',
                      }
                    }}
                    onClick={() => {
                      setExpanded(true);
                      setTimeout(() => {
                        if (mapRef) {
                          scrollToMap();
                        }
                      }, 700); // FIXME: not the nicest solution
                    }}
                  >
                    Térképes keresés
                  </Button>
                  <Button
                    sx={{
                      color: isProperties ? 'white' : '#962F32',
                      '&:hover, &.Mui-focusVisible': {
                        backgroundColor: isProperties ? 'white' : '#962F32',
                        color: isProperties ? '#962F32' : 'white',
                      }
                    }}
                    disabled={isSubmitting}
                    onClick={(e) => {
                      e.stopPropagation();
                      setExpanded(!expanded);
                    }}
                  >
                    Részletes keresés
                    {' '}
                    {!expanded ? 'kinyitása' : 'bezárása'}
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Collapse in={expanded}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  mb: 2,
                  mt: 5,
                  backgroundColor: 'white',
                }}
              >
                <Grid
                  container
                  p={2}
                  justifyContent="space-between"
                >
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    md={3}
                    sm={12}
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    mt={mdDown && 5}
                  >
                    <Typography
                      color="textPrimary"
                      variant="h6"
                      fontWeight="bold"
                      sx={{
                        opacity: 0.7,
                      }}
                    >
                      Adatok
                    </Typography>
                    <TextField
                      className="SearchFields"
                      error={Boolean(touched.floorLevelFrom && errors.floorLevelFrom)}
                      fullWidth
                      helperText={touched.floorLevelFrom && errors.floorLevelFrom}
                      label="Emelet (-tól)"
                      name="floorLevelFrom"
                      onBlur={handleBlur}
                      onChange={(e) => handleFormikValuesChange(e.target.name, e.target.value)}
                      value={values.floorLevelFrom}
                      variant="outlined"
                    />
                    <TextField
                      className="SearchFields"
                      error={Boolean(touched.floorLevelTo && errors.floorLevelTo)}
                      fullWidth
                      helperText={touched.floorLevelTo && errors.floorLevelTo}
                      label="Emelet (-ig)"
                      name="floorLevelTo"
                      onBlur={handleBlur}
                      onChange={(e) => handleFormikValuesChange(e.target.name, e.target.value)}
                      value={values.floorLevelTo}
                      variant="outlined"
                    />
                    <TextField
                      className="SearchFields"
                      error={Boolean(touched.constructionType && errors.constructionType)}
                      fullWidth
                      helperText={touched.constructionType && errors.constructionType}
                      label="Építési mód"
                      name="constructionType"
                      select
                      onBlur={handleBlur}
                      onChange={(e) => handleFormikValuesChange(e.target.name, e.target.value)}
                      value={values.constructionType}
                      variant="outlined"
                    >
                      <MenuItem value="">
                        <em>Válassz...</em>
                      </MenuItem>
                      {attributes?.constructionType?.map((option) => (
                        <MenuItem
                          key={option.name}
                          value={option.id}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    md={3}
                    sm={12}
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    mt={mdDown && 5}
                  >
                    <Typography
                      color="textPrimary"
                      variant="h6"
                      fontWeight="bold"
                      sx={{
                        opacity: 0.7,
                      }}
                    >
                      Jellemzők
                    </Typography>
                    <AOCAutocomplete
                      fullWidth
                      sx={{ mt: 1.9 }}
                      multiple
                      disableCloseOnSelect
                      options={attributes?.internalCondition?.filter((filteredOption) => filteredOption.enabled).map((option) => option)}
                      value={values.internalCondition}
                      onChange={(_, newValue) => handleFormikValuesChange('internalCondition', newValue)}
                      label="Belső állapot"
                    />
                    <AOCAutocomplete
                      fullWidth
                      sx={{ mt: 1.9 }}
                      multiple
                      disableCloseOnSelect
                      options={attributes?.externalCondition?.filter((filteredOption) => filteredOption.enabled).map((option) => option)}
                      value={values.externalCondition}
                      onChange={(_, newValue) => handleFormikValuesChange('externalCondition', newValue)}
                      label="Külső állapot"
                    />
                    <AOCAutocomplete
                      fullWidth
                      sx={{ mt: 1.9 }}
                      multiple
                      disableCloseOnSelect
                      options={attributes?.view?.filter((filteredOption) => filteredOption.enabled).map((option) => option)}
                      value={values.view}
                      onChange={(_, newValue) => handleFormikValuesChange('view', newValue)}
                      label="Kilátás"
                    />
                    <AOCAutocomplete
                      fullWidth
                      sx={{ mt: 1.9 }}
                      multiple
                      disableCloseOnSelect
                      options={attributes?.parking?.filter((filteredOption) => filteredOption.enabled).map((option) => option)}
                      value={values.parking}
                      onChange={(_, newValue) => handleFormikValuesChange('parking', newValue)}
                      label="Parkolás"
                    />
                    <TextField
                      className="SearchFields"
                      error={Boolean(touched.accessibility && errors.accessibility)}
                      fullWidth
                      helperText={touched.accessibility && errors.accessibility}
                      label="Akadálymentesítés"
                      name="accessibility"
                      select
                      onBlur={handleBlur}
                      onChange={(e) => handleFormikValuesChange(e.target.name, e.target.value)}
                      value={values.accessibility}
                      variant="outlined"
                    >
                      <MenuItem value="">
                        <em>Válassz...</em>
                      </MenuItem>
                      {attributes?.accessibility?.map((option) => (
                        <MenuItem
                          key={option.name}
                          value={option.id}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      className="SearchFields"
                      error={Boolean(touched.furnished && errors.furnished)}
                      fullWidth
                      helperText={touched.furnished && errors.furnished}
                      label="Bútorozottság"
                      name="furnished"
                      select
                      onBlur={handleBlur}
                      onChange={(e) => handleFormikValuesChange(e.target.name, e.target.value)}
                      value={values.furnished}
                      variant="outlined"
                    >
                      <MenuItem value="">
                        <em>Válassz...</em>
                      </MenuItem>
                      {attributes?.furnishing?.map((option) => (
                        <MenuItem
                          key={option.name}
                          value={option.id}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    md={3}
                    sm={12}
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    mt={mdDown && 5}
                  >
                    <Typography
                      color="textPrimary"
                      variant="h6"
                      fontWeight="bold"
                      sx={{
                        opacity: 0.7,
                      }}
                    >
                      Közművek
                    </Typography>
                    <AOCAutocomplete
                      fullWidth
                      sx={{ mt: 1.9 }}
                      multiple
                      disableCloseOnSelect
                      options={attributes?.electricity?.filter((filteredOption) => filteredOption.enabled).map((option) => option)}
                      value={values.electricity}
                      onChange={(_, newValue) => handleFormikValuesChange('electricity', newValue)}
                      label="Villany"
                    />
                    <AOCAutocomplete
                      fullWidth
                      sx={{ mt: 1.9 }}
                      multiple
                      disableCloseOnSelect
                      options={attributes?.water?.filter((filteredOption) => filteredOption.enabled).map((option) => option)}
                      value={values.water}
                      onChange={(_, newValue) => handleFormikValuesChange('water', newValue)}
                      label="Víz"
                    />
                    <AOCAutocomplete
                      fullWidth
                      sx={{ mt: 1.9 }}
                      multiple
                      disableCloseOnSelect
                      options={attributes?.gas?.filter((filteredOption) => filteredOption.enabled).map((option) => option)}
                      value={values.gas}
                      onChange={(_, newValue) => handleFormikValuesChange('gas', newValue)}
                      label="Gáz"
                    />
                    <AOCAutocomplete
                      fullWidth
                      sx={{ mt: 1.9 }}
                      multiple
                      disableCloseOnSelect
                      options={attributes?.sewer?.filter((filteredOption) => filteredOption.enabled).map((option) => option)}
                      value={values.sewer}
                      onChange={(_, newValue) => handleFormikValuesChange('sewer', newValue)}
                      label="Csatorna"
                    />
                    <AOCAutocomplete
                      fullWidth
                      sx={{ mt: 1.9 }}
                      multiple
                      disableCloseOnSelect
                      options={attributes?.heating?.filter((filteredOption) => filteredOption.enabled).map((option) => option)}
                      value={values.heating}
                      onChange={(_, newValue) => handleFormikValuesChange('heating', newValue)}
                      label="Fűtés"
                    />
                    <TextField
                      className="SearchFields"
                      error={Boolean(touched.cooling && errors.cooling)}
                      fullWidth
                      helperText={touched.cooling && errors.cooling}
                      label="Klíma"
                      name="cooling"
                      select
                      onBlur={handleBlur}
                      onChange={(e) => handleFormikValuesChange(e.target.name, e.target.value)}
                      value={values.cooling}
                      variant="outlined"
                    >
                      <MenuItem value="">
                        <em>Válassz...</em>
                      </MenuItem>
                      {attributes?.cooling?.map((option) => (
                        <MenuItem
                          key={option.name}
                          value={option.id}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      className="SearchFields"
                      error={Boolean(touched.tv && errors.tv)}
                      fullWidth
                      helperText={touched.tv && errors.tv}
                      label="TV"
                      name="tv"
                      select
                      onBlur={handleBlur}
                      onChange={(e) => handleFormikValuesChange(e.target.name, e.target.value)}
                      value={values.tv}
                      variant="outlined"
                    >
                      <MenuItem value="">
                        <em>Válassz...</em>
                      </MenuItem>
                      {attributes?.tv?.map((option) => (
                        <MenuItem
                          key={option.name}
                          value={option.id}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      className="SearchFields"
                      error={Boolean(touched.internet && errors.internet)}
                      fullWidth
                      helperText={touched.internet && errors.internet}
                      label="Internet"
                      name="internet"
                      select
                      onBlur={handleBlur}
                      onChange={(e) => handleFormikValuesChange(e.target.name, e.target.value)}
                      value={values.internet}
                      variant="outlined"
                    >
                      <MenuItem value="">
                        <em>Válassz...</em>
                      </MenuItem>
                      {attributes?.internet?.map((option) => (
                        <MenuItem
                          key={option.name}
                          value={option.id}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  my={5}
                >
                  <Grid
                    item
                    xl={checkboxWidth}
                    lg={checkboxWidth}
                    md={checkboxWidth}
                    sm={checkboxWidthPhone}
                    xs={checkboxWidthPhone}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <FormControlLabel
                      control={(
                        <Checkbox
                          color="primary"
                          checked={Boolean(values.newConstruction)}
                          onBlur={handleBlur}
                          onChange={() => handleFormikValuesChange('newConstruction', !values.newConstruction)}
                          name="newConstruction"
                        />
                      )}
                      label="Újépítésű"
                    />
                    <FormControlLabel
                      control={(
                        <Checkbox
                          color="primary"
                          onBlur={handleBlur}
                          checked={Boolean(values.investment)}
                          onChange={() => handleFormikValuesChange('investment', !values.investment)}
                          name="investment"
                        />
                      )}
                      label="Befektetésre"
                    />
                  </Grid>
                  <Grid
                    item
                    xl={checkboxWidth}
                    lg={checkboxWidth}
                    md={checkboxWidth}
                    sm={checkboxWidthPhone}
                    xs={checkboxWidthPhone}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <FormControlLabel
                      control={(
                        <Checkbox
                          color="primary"
                          onBlur={handleBlur}
                          checked={Boolean(values.waterFront)}
                          onChange={() => handleFormikValuesChange('waterFront', !values.waterFront)}
                          name="waterFront"
                        />
                      )}
                      label="Vízparti"
                    />
                    <FormControlLabel
                      control={(
                        <Checkbox
                          color="primary"
                          onChange={() => handleFormikValuesChange('privateGarden', !values.privateGarden)}
                          checked={Boolean(values.privateGarden)}
                          onBlur={handleBlur}
                          name="privateGarden"
                        />
                      )}
                      label="Saját kert"
                    />
                  </Grid>
                  <Grid
                    item
                    xl={checkboxWidth}
                    lg={checkboxWidth}
                    md={checkboxWidth}
                    sm={checkboxWidthPhone}
                    xs={checkboxWidthPhone}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <FormControlLabel
                      control={(
                        <Checkbox
                          color="primary"
                          onBlur={handleBlur}
                          checked={Boolean(values.movable)}
                          onChange={() => handleFormikValuesChange('movable', !values.movable)}
                          name="movable"
                        />
                      )}
                      label="Költözhető"
                    />
                    <FormControlLabel
                      control={(
                        <Checkbox
                          color="primary"
                          onChange={() => handleFormikValuesChange('energySave', !values.energySave)}
                          checked={Boolean(values.energySave)}
                          onBlur={handleBlur}
                          name="energySave"
                        />
                      )}
                      label="Energiatakarékos"
                    />
                  </Grid>
                  <Grid
                    item
                    xl={checkboxWidth}
                    lg={checkboxWidth}
                    md={checkboxWidth}
                    sm={checkboxWidthPhone}
                    xs={checkboxWidthPhone}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <FormControlLabel
                      control={(
                        <Checkbox
                          color="primary"
                          onBlur={handleBlur}
                          checked={Boolean(values.video)}
                          onChange={() => handleFormikValuesChange('video', !values.video)}
                          name="video"
                        />
                      )}
                      label="Videó elérhető"
                    />
                    <FormControlLabel
                      control={(
                        <Checkbox
                          color="primary"
                          onBlur={handleBlur}
                          checked={Boolean(values.exclusive)}
                          onChange={() => handleFormikValuesChange('exclusive', !values.exclusive)}
                          name="exclusive"
                        />
                      )}
                      label="Kizárólag nálunk"
                    />
                  </Grid>
                </Grid>
                <Grid
                  height={400}
                  ref={mapRef}
                >
                  <MapTest
                    pathname={pathname}
                    drawingEnabled
                    initialZoom={8}
                    center={[47, 18]}
                    marker={[47.5, 19]}
                    response={response}
                    setResponse={setResponse}
                    shapes={shapes}
                    setShapes={setShapes}
                    newShapes={newShapes}
                    setNewShapes={setNewShapes}
                    isNewCreated={isNewCreated}
                    setIsNewCrated={setIsNewCrated}
                  />
                </Grid>
              </Box>
            </Collapse>
            {handleSearchButton() && (
              <Box
                sx={{
                  position: expanded && 'sticky',
                  bottom: 0,
                  zIndex: 1000,
                  backgroundColor: 'white',
                  borderRadius: '10px',
                }}
                mt={1}
                pb={1}
              >
                <Divider sx={{ mb: 2 }} />

                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  type="submit"
                  variant="contained"
                  startIcon={<SearchIcon fontSize="small" />}
                >
                  Keresés
                </Button>
                <Button // TODO: clear url
                  fullWidth
                  sx={{ mt: 1 }}
                  color="primary"
                  onClick={(e) => {
                    const clearUrl = window.location;
                    clearUrl.search = '';
                    e.stopPropagation();
                    handleReset(setValues);
                  }}
                  variant="text"
                >
                  Keresési feltételek törlése
                </Button>
              </Box>
            )}
            <Dialog
              open={locationOpen}
              onClose={handleLocationClose}
              maxWidth="sm"
              fullWidth
            >
              <DialogTitle>
                Hol keresel?
              </DialogTitle>
              <DialogContent
                sx={{
                  minHeight: 150
                }}
              >
                <AOCAutocomplete
                  autoFocus
                  fullWidth
                  disableCloseOnSelect
                  sx={{ mt: 1 }}
                  multiple
                  options={locationOptions}
                  onChange={(_, newValue) => {
                    if (typeof newValue[newValue.length - 1] === 'object' || newValue.length === 0) {
                      handleFormikValuesChange('cities', [
                        ...newValue
                      ]);
                    }
                  }}
                  limitTags={10}
                  textFieldOnChange={(event) => setCitySearchName(event.target.value)}
                  value={values.cities}
                  label="pl.: Siófok"
                  placeholder="Kezdje el beírni a település nevét."
                  loadingArray={isLoadingArray.includes('GET_LOCATION_OPTIONS')}
                />
              </DialogContent>
              <Divider />
              <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  fullWidth
                  onClick={handleLocationClose}
                >
                  Bezárás
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        )}
      </Formik>
    </Box>
  );
};

MainSearch.propTypes = {
  setPage: PropTypes.func,
  setPropertyArray: PropTypes.func,
  page: PropTypes.number,
  selectedSort: PropTypes.string
};

export default MainSearch;
