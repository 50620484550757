import { Grid, Stack, Typography } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Edit from '@mui/icons-material/Edit';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PropTypes from 'prop-types';
import { dateFormatter } from '../utils/helperfunctions';

const BlogMetaInfo = ({ publish, modified, author, justifyContent, showEditDate = false }) => (
  <Grid
    display="flex"
    justifyContent="space-between"
    direction={justifyContent ? 'column' : 'row'}
  >
    <Grid
      display={justifyContent ? 'flex' : 'flex'}
      justifyContent={justifyContent ?? 'flex-start'}
      gap={2}
      direction="row"
      flexWrap="wrap"
    >
      <Stack
        gap={1}
        direction="row"
        alignItems="center"
      >
        <CalendarTodayIcon
          fontSize="small"
          color="disabled"
          sx={{
            height: 18,
          }}
        />
        <Typography
          variant="subtitle2"
          color="disabled"
        >
          {dateFormatter(publish, 'yyyy.MM.dd')}
        </Typography>
      </Stack>

      <Stack
        gap={1}
        direction="row"
        alignItems="center"
      >
        <AccountBoxIcon
          fontSize="small"
          color="disabled"
          sx={{
            height: 18,
          }}
        />
        <Typography
          variant="subtitle2"
          color="disabled"
        >
          {author}
        </Typography>
      </Stack>
    </Grid>

    {showEditDate && modified && (
      <Stack
        gap={1}
        direction="row"
        alignItems="center"
      >
        <Edit
          fontSize="small"
          color="disabled"
          sx={{
            height: 18,
          }}
        />
        <Typography
          variant="subtitle2"
          color="disabled"
        >
          {dateFormatter(modified)}
        </Typography>
      </Stack>
    )}
  </Grid>
);

export default BlogMetaInfo;

BlogMetaInfo.propTypes = {
  publish: PropTypes.number.isRequired,
  modified: PropTypes.number.isRequired,
  author: PropTypes.string.isRequired,
  justifyContent: PropTypes.string,
  showEditDate: PropTypes.bool,
};
