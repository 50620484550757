import { createTheme, responsiveFontSizes } from '@mui/material';
import merge from 'lodash/merge';
import { lightShadows, darkShadows } from './shadows';
import { THEMES } from '../constants';

const font = "'Montserrat', sans-serif";

const baseOptions = {
  direction: 'ltr',

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1300,
      xl: 1636,
    },
  },
  components: {
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: 13,
          top: 2,
        },
      },
      styleOverrides: {
        shrink: ({ ownerState }) => ({
          ...(ownerState.shrink && {
            fontSize: '15px !important',
            top: '-1 !important',
          }),
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '13px'
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%'
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          color: 'black',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '10px',
          fontSize: '13px'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box'
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%'
        },
        body: {
          height: '100%'
        },
        '#root': {
          height: '100%'
        },
        '#nprogress .bar': {
          zIndex: '2000 !important',
          background: '#962F32 !important'
        },
        '*::-webkit-scrollbar': {
          height: 12,
          width: 12,
        },
        '*::-webkit-scrollbar-track': {
          background: 'transparent'
        },
        '*::-webkit-scrollbar-thumb': {
          background: 'rgba(163, 163, 163)',
          borderRadius: 8,
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6'
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        }
      }
    }
  },
  typography: {
    button: {
      fontWeight: 600
    },
    fontFamily: font,
    h1: {
      fontSize: '4rem',
    },
    h2: {
      fontSize: '3.7rem',
    },
    h3: {
      fontSize: '3.3rem',
    },
    h4: {
      fontSize: '2.7rem',
    },
    h5: {
      fontSize: '1.8rem',
    },
    h6: {
      fontSize: '1.3rem',
    },
    h6Bold: {
      fontSize: '1.2rem',
      fontWeight: 800
    },
    h7: {
      fontSize: '1rem'
    },
    h8: {
      fontSize: '0.85rem'
    },
    overline: {
      fontWeight: 600
    },
  }
};

const themesOptions = {
  [THEMES.LIGHT]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e',
              borderColor: '#000'
            }
          }
        }
      },
      MuiCssBaseline: {
        styleOverrides: {
          '*::-webkit-scrollbar-track': {
            background: 'transparent'
          },
          '*::-webkit-scrollbar-thumb': {
            background: 'rgba(153, 153, 153)',
            borderRadius: 8,
          }
        }
      }
    },
    palette: {
      action: {
        active: '#6b778c'
      },
      background: {
        default: '#FBFBFD',
        paper: '#ffffff'
      },
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'light',
      primary: {
        contrastText: '#fff',
        main: '#962F32'
      },
      info: {
        main: '#f6f6f7',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      secondary: {
        contrastText: '#ffffff',
        main: '#2C3E50'
      },
      navigation: {
        background: '#ffffff',
        text: '#616161',
        textHome: '#000',
        active: '#962F32'
      },
      text: {
        primary: '#1D1D1F',
        secondary: '#616161',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      }
    },
    shadows: lightShadows
  },
  [THEMES.DARK]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
          }
        }
      },
      MuiCssBaseline: {
        styleOverrides: {
          '*::-webkit-scrollbar-track': {
            background: 'transparent'
          },
          '*::-webkit-scrollbar-thumb': {
            background: 'rgba(125, 125, 125)',
            margin: 5,
            borderRadius: 8,
          }
        }
      }
    },
    palette: {
      background: {
        default: '#171c24',
        paper: '#222b36'
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#919EAB'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      secondary: {
        contrastText: '#ffffff',
        main: '#99AFEF'
      },
      navigation: {
        text: '#919EAB',
        active: '#fff'
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      }
    },
    shadows: darkShadows
  },
  [THEMES.NATURE]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
          }
        }
      }
    },
    palette: {
      background: {
        default: '#1c2531',
        paper: '#293142'
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#01ab56'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      navigation: {
        text: '#919EAB',
        active: '#fff'
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      }
    },
    shadows: darkShadows
  }
};

export const createCustomTheme = (config = {}) => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  let theme = createTheme(merge({}, baseOptions, themeOptions, {
    ...(config.roundedCorners && {
      shape: {
        borderRadius: 10
      }
    })
  }, {
    direction: config.direction
  }));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
