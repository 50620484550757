import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const { REACT_APP_BASE_URL } = process.env;

const initialState = {
  homeNavVisibility: false,
};

const slice = createSlice({
  name: 'webPresentation',
  initialState,
  reducers: {
    handleHomeNavVisibility(state, action) {
      console.log('action.payload', action.payload);
      state.homeNavVisibility = action.payload;
    },
  }
});

export const { reducer } = slice;

/* GET Axios request template */
// export const getOffices = (filters) => async (dispatch) => {
//   await axiosConfig.get(
//     '/offices'
//   ).then((response) => {
//     // handle success
//     dispatch(slice.actions.getOffices(response.data.data, filters));
//   }).catch((error) => {
//     // handle error
//     toast.error('Az irodák betöltése sikertelen');
//     console.error(error);
//   }).then(() => {
//     // always executed
//   });
// };
/* GET Axios request template */

export const getPresentationByEmail = () => async () => {
  console.log('getPresentationByEmail');
  await axios.get(REACT_APP_BASE_URL, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Expose-Headers': '*',
      'Content-Type': 'application/json'
    }
  }).then((response) => {
    // handle success
    console.log('response', response);
  }).catch((error) => {
    // handle error
    console.error(error);
  }).then(() => {
    // always executed
  });
};

export default slice;
