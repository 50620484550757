import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { makeStyles } from 'tss-react/mui';
import { Box, Typography, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';

const SocialMediaLinks = () => {
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  if (lgDown) return null;

  return (
    <Box
      data-aos="fade-left"
      data-aos-offset="1100"
      data-aos-once="false"
      zIndex={2}
      sx={{
        position: 'fixed',
        right: 10,
        bottom: '50%',
        borderRadius: '10px',
        backgroundColor: 'rgba(255, 255, 255, .7)',
        backdropFilter: 'blur(15px)',
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
        p: 1,
      }}
    >
      <Social
        Icon={FacebookIcon}
        link="https://www.facebook.com/LidoHomeIngatlan/"
      />
      <Social
        Icon={InstagramIcon}
        link="https://www.instagram.com/lidohome_ingatlanok"
      />
      <Social
        Icon={YouTubeIcon}
        link="https://www.youtube.com/@lidohomeingatlanok8122"
      />
      <Social
        Icon={LinkedInIcon}
        link="https://www.linkedin.com/company/lido-home/"
      />
    </Box>
  );
};

export default SocialMediaLinks;

const Social = ({ Icon, link }) => {
  const useStyles = makeStyles()(() => ({
    text: {
      color: '#000',
      '&:hover': { color: '#962F32' }
    },
  }));
  const { classes } = useStyles();

  return (
    <a
      style={{ cursor: 'pointer' }}
      href={link}
      rel="noreferrer"
      target="_blank"
    >
      <Typography className={classes.text}>
        <Icon sx={{ fontSize: 23, my: 1 }} />
      </Typography>
    </a>
  );
};

Social.propTypes = {
  Icon: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};
