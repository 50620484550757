import {
  Card,
  Grid,
  Button,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
} from "@mui/material";
import React, { useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Message } from "../app/slices/property";
import { useDispatch } from "../app/store";
import * as Yup from "yup";
import "../App.css";
import { Formik } from "formik";
import AOCTextfield from "../utils/AOCTextfield";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import AOCPhoneInput from "../utils/AOCPhoneInput";
import PropTypes from "prop-types";

const ContactUs = ({ employeeId, propertyId, borderRadius }) => {
  const { t } = useTranslation();
  const smDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const formikRef = useRef();

  const handleSubmitForm = (values, newToken) => {
    const sendData = {
      ...values,
      googleVerificationToken: newToken,
      employeeId,
      propertyId,
    };
    dispatch(Message(sendData));
  };

  const handleReCaptchaVerify = async () => {
    if (!executeRecaptcha) return;

    const newToken = await executeRecaptcha("yourAction");
    // Do whatever you want with the token
    handleSubmitForm(formikRef.current.values, newToken);
  };

  const textColor = "white";

  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: borderRadius || "10px",
        marginTop: 2,
        padding: 2,
        backgroundColor: (theme) => theme.palette.primary.main,
        color: textColor,
      }}
    >
      <Grid
        backgroundColor="primary"
        sx={{
          position: "relative",
          "&:after": {
            backgroundColor: "white",
            bottom: "-8px",
            left: "-20px",
            content: '" "',
            height: "1px",
            position: "absolute",
            width: 150,
            clipPath: "inset(0, -100vmax)",
          },
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          Kapcsolatfelvétel
        </Typography>
      </Grid>
      <Formik
        innerRef={formikRef}
        initialValues={{
          propertySale: false,
          termsConditionsAccepted: true,
          dataManagementAccepted: true,
          dataTransferAccepted: true,
          propertyRent: false,
          propertyBuy: false,
          contactByPhone: false,
          contactBySms: false,
          contactByEmail: false,
          contactName: "",
          contactPhone: "",
          contactEmail: "",
          message: "",
        }}
        validationSchema={Yup.object().shape({
          contactName: Yup.string().required("Mező kitöltése kötelező"),
          contactPhone: Yup.string().required("Mező kitöltése kötelező"),
          contactEmail: Yup.string()
            .required("Mező kitöltése kötelező")
            .email("Az e-mail cím formátuma nem megfelelő"),
          message: Yup.string()
            .required("Mező kitöltése kötelező")
            .min(10, "A mező tartalma legalább 10 karakter hosszú kell legyen"),
        })}
        onSubmit={async () => {
          try {
            handleReCaptchaVerify();
          } catch (err) {
            console.error(err);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          setFieldValue,
          handleChange,
          touched,
          handleSubmit,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container justifyContent="center" mt={4}>
              <Grid
                item
                md={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Typography fontWeight="bold">{t("wantProperty")}</Typography>
                <Box display="flex">
                  <FormControlLabel
                    label={t("sell")}
                    sx={{ color: textColor }}
                    control={
                      <Checkbox
                        name="propertySale"
                        onChange={handleChange}
                        sx={{
                          color: textColor,
                          "&.Mui-checked": { color: textColor },
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label={t("buy")}
                    sx={{ color: textColor }}
                    control={
                      <Checkbox
                        color="primary"
                        name="propertyBuy"
                        onChange={handleChange}
                        sx={{
                          color: textColor,
                          "&.Mui-checked": { color: textColor },
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label={t("rent")}
                    sx={{ color: textColor }}
                    control={
                      <Checkbox
                        color="primary"
                        name="propertyRent"
                        onChange={handleChange}
                        sx={{
                          color: textColor,
                          "&.Mui-checked": { color: textColor },
                        }}
                      />
                    }
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <Typography fontWeight="bold">{t("connectionMode")}</Typography>
              <Box display="flex">
                <FormControlLabel
                  label={t("call")}
                  sx={{ color: textColor }}
                  control={
                    <Checkbox
                      color="primary"
                      name="contactByPhone"
                      onChange={handleChange}
                      sx={{
                        color: textColor,
                        "&.Mui-checked": { color: textColor },
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label={t("sms")}
                  sx={{ color: textColor }}
                  control={
                    <Checkbox
                      color="primary"
                      name="contactBySms"
                      onChange={handleChange}
                      sx={{
                        color: textColor,
                        "&.Mui-checked": { color: textColor },
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label={t("email")}
                  sx={{ color: textColor }}
                  control={
                    <Checkbox
                      color="primary"
                      name="contactByEmail"
                      onChange={handleChange}
                      sx={{
                        color: textColor,
                        "&.Mui-checked": { color: textColor },
                      }}
                    />
                  }
                />
              </Box>
            </Grid>
            <Grid
              container
              backgroundColor="white"
              p={2}
              mt={1}
              borderRadius={borderRadius || "10px"}
              justifyContent="space-between"
            >
              <Grid item md={12} sm={12} xs={12}>
                <AOCTextfield
                  margin="normal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  values={values}
                  touched={touched}
                  errors={errors}
                  name="contactName"
                  label={t("partnerForm.3")}
                />
              </Grid>
              <Grid item md={5.9} sm={5.9} xs={12}>
                <AOCPhoneInput
                  values={values}
                  touched={touched}
                  errors={errors}
                  name="contactPhone"
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Grid item md={5.9} sm={5.9} xs={12} mt={smDown && 1}>
                <AOCTextfield
                  margin="normal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  values={values}
                  touched={touched}
                  errors={errors}
                  name="contactEmail"
                  label={t("partnerForm.7")}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <AOCTextfield
                  margin="normal"
                  rows={3}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  values={values}
                  touched={touched}
                  errors={errors}
                  name="message"
                  label={t("partnerForm.8")}
                />
              </Grid>
              <Box display="flex" justifyContent="flex-end" pr={2}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 2,
                    textTransform: "capitalize",
                  }}
                >
                  {t("send")}
                </Button>
              </Box>
            </Grid>
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default ContactUs;

ContactUs.propTypes = {
  employeeId: PropTypes.number,
  borderRadius: PropTypes.string,
};
