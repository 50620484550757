import { lazy, Suspense } from 'react';
import DashboardLayout from './components/dashboard/DashboardLayout';
import Employee from './components/Employee';
import Hitel from './components/Hitel';
import Invest from './components/Invest';
import LoadingScreen from './components/LoadingScreen';
import Home from './pages/home/Home';
import ProjectDetails from './components/ProjectDetails';
import Projects from './components/Projects';
import WebPresentationBrowse from './pages/home/webPresentation/WebPresentationBrowse';
import StyleHelper from './utils/StyleHelper';
import PartnerForm from './components/PartnerForm';
import VerifyRegistration from './components/VerifyRegistration';
import PasswordReset from './components/PasswordReset';
import BlogDetail from './components/BlogDetail';
import { WebPresentationUnsubscribe } from './pages/home/webPresentation/WebPresentationUnsubscribe';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

/* Properties */
const Properties = Loadable(lazy(() => import('./pages/Properties')));
const PropertyDetails = Loadable(lazy(() => import('./components/PropertyDetails')));

const Offices = Loadable(lazy(() => import('./components/Offices')));
const OfficeDetails = Loadable(lazy(() => import('./components/OfficeDetails')));

const Partner = Loadable(lazy(() => import('./pages/partner/Partner')));

/* References */
const References = Loadable(lazy(() => import('./components/References')));
const ReferenceDetails = Loadable(lazy(() => import('./components/ReferenceDetails')));

/* Creer */
const Karrier = Loadable(lazy(() => import('./components/Karrier')));
const KarrierSecondary = Loadable(lazy(() => import('./components/KarrierSecondary')));
const KarrierForm = Loadable(lazy(() => import('./components/KarrierForm')));

/* Profile */
const Profile = Loadable(lazy(() => import('./pages/Profile')));

const AboutUs = Loadable(lazy(() => import('./components/AboutUs')));

const NotFound = Loadable(lazy(() => import('./pages/NotFound')));

/* Blog */
const Blog = Loadable(lazy(() => import('./components/Blog')));

const routes = [
  {
    path: '/',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/properties',
        children: [
          {
            path: '',
            element: <Properties />
          },
          {
            path: 'details/:id',
            element: <PropertyDetails />
          }
        ]
      },
      {
        path: '/career',
        children: [
          {
            path: '',
            element: <Karrier />,
          },
          {
            path: 'secondary',
            element: <KarrierSecondary />
          },
          {
            path: 'form',
            element: <KarrierForm />
          },

        ]
      },
      {
        path: '/loan',
        element: <Hitel />
      },
      {
        path: '/employee/:id',
        element: <Employee />
      },
      {
        path: '/aboutUs',
        element: <AboutUs />
      },
      {
        path: '/blog',
        children: [
          {
            path: '',
            element: <Blog />
          },
          {
            path: 'details/:id',
            element: <BlogDetail />
          }
        ]
      },
      {
        path: '/StyleHelper',
        element: <StyleHelper />
      },
      {
        path: '/profile',
        element: <Profile />
      },
      {
        path: '/offices',
        children: [
          {
            path: '',
            element: <Offices />
          },
          {
            path: 'details/:id',
            element: <OfficeDetails />
          }
        ]
      },
      {
        path: '/other',
        children: [
          {
            path: 'property',
            element: 'Property',
          },
          {
            path: 'resort',
            element: 'Resort'
          },
          {
            path: 'invest',
            element: <Invest />
          },
          {
            path: 'partner',
            element: <Partner />
          },
          {
            path: 'partnerform',
            element: <PartnerForm />
          },
        ]
      },
      {
        path: '/projects',
        children: [
          {
            path: 'investment',
            element: <Invest />
          },
          {
            path: 'allProjects',
            element: <Projects />
          },
          {
            path: 'details/:id',
            element: <ProjectDetails />
          },
          {
            path: 'references',
            element: <References />,
          },
          {
            path: 'referencedetails/:id',
            element: <ReferenceDetails />
          },
        ]
      },
    ]
  },
  {
    path: 'emails',
    children: [
      {
        path: 'presentations/:uuid',
        children: [
          {
            path: '',
            element: <WebPresentationBrowse type="uuid" />
          },
          {
            path: 'unsubscribe',
            element: <WebPresentationUnsubscribe />
          },
          {
            path: 'updatesearch',
          },
          {
            path: 'hp/',
            element: <WebPresentationBrowse type="publicId" />
          }
        ]
      },
      {
        path: 'weekly-stat/:uuid',
        children: [
          {
            path: 'unsubscribe',
            element: <WebPresentationUnsubscribe type="weekly-stat" />
          }
        ]
      },
      {
        path: 'authorization',
        children: [
          {
            path: 'registration/:uuid',
            element: <VerifyRegistration type="registrationVerify" />
          },
          {
            path: 'delete/:uuid',
            element: <VerifyRegistration type="registrationDelete" />
          },
          {
            path: 'reset/:uuid',
            element: <PasswordReset />
          }
        ]
      },
    ]
  },
  {
    path: '404',
    element: <NotFound />
  },
  {
    path: '*',
    element: <NotFound />
  },
  {
    path: 'email', // TODO: replace this element later, outside of the BO
    children: [
      {
        path: '/email/:uuid/presentation',
        element: <WebPresentationBrowse />
      }
    ]
  },
];

export default routes;
