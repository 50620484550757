/* eslint-disable  */
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router";
import {
  getPresentations,
  getPresentationsBoIp,
  modifySearchParameterRequest,
  unsubscribeRequest,
} from "../../../app/slices/property";
import { getPresentationByEmail } from "../../../app/slices/webPresentation";
import { useDispatch, useSelector } from "../../../app/store";
// import { useDispatch, useSelector } from '../../../app/store';
import ClientSearches from "../../../components/ClientSearches";
import Logo from "../../../components/Logo";
// import { selectIp } from '../../slices/ip';
import WebPresentationBrowseResults from "./WebPresentationBrowseResults";

const { REACT_APP_FILE_URL } = process.env;

const WebPresentationBrowse = ({ type }) => {
  const urlParams = useParams();
  const { presentation } = useSelector((state) => state.property);
  console.log(presentation, "presentationss");
  const dispatch = useDispatch();
  const { uuid } = urlParams;
  // const { presentation } = useSelector((state) => state.ip);
  const mobileDevice = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // useEffect(() => {
  //   if (id) {
  //     dispatch(selectIp(id));
  //   }
  // }, []);

  useEffect(() => {
    if (type === "uuid") {
      dispatch(getPresentations(uuid));
    } else if (type === "publicId") {
      dispatch(getPresentationsBoIp(uuid));
    }
    // dispatch(getPresentationByEmail());
  }, []);

  const parameterChangeRequest = () => {
    console.log("parameterChangeRequest");
    dispatch(modifySearchParameterRequest(uuid));
    // dispatch(getPresentations(uuid));
  };

  const unsubscribe = () => {
    dispatch(unsubscribeRequest(uuid));
  };

  return (
    <Container>
      <Grid container display="flex" justifyContent="center" mt={5}>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Logo
            sx={{
              width: 400,
              height: 130,
            }}
          />
        </Grid>
        <Grid
          container
          spacing={5}
          paddingX={mobileDevice ? 0 : 10}
          mt={10}
          alignItems="center"
        >
          <Grid item md={7} xs={12}>
            <Typography variant="h4">
              {"Tisztelt "}
              {presentation?.client?.name}
            </Typography>
            <Typography variant="h6" fontWeight={400} mt={2}>
              Kollégáink a következő ingatlanokat szeretnék figyelmébe ajánlani.
              Görgessen lejjebb az ingatlanok megtekintéséhez. Amennyiben
              valamelyik ingatlan felkelti érdeklődését, kérjen helyszíni
              bemutatót, üzletkötőnk fel fogja keresni időpontegyeztetés
              céljából.
            </Typography>
          </Grid>
          <Grid item md={5} xs={12} display="flex" justifyContent="center">
            <Card
              variant="outlined"
              // sx={{ maxWidht: '100%' }}
              sx={{
                padding: 2,
              }}
            >
              <CardHeader
                title={
                  <Box display="flex" alignItems="center">
                    <Grid>
                      <Avatar
                        // src={user?.images?.length > 0 ? `http://lidohome.aocsystem.com:8081${user?.images[0].source?.original}` : null}
                        // src='https://bo.lidohome.hu/public-images/iroda-1630/felhasznalok/56-v2/hortobagyi-eva.jpg'
                        // src="https://bo.lidohome.hu/public-images/iroda-1630/felhasznalok/195-v2/jpg.jpg"
                        src={
                          presentation?.user?.images?.length > 0
                            ? `${REACT_APP_FILE_URL}${presentation?.user?.images[0].source?.profile}`
                            : null
                        }
                        sx={{
                          height: 150,
                          width: 150,
                          mr: 2,
                        }}
                      />
                    </Grid>
                    <Grid>
                      <Typography
                        // variant="body1"
                        variant="h6"
                      >
                        {/* {user.name} */}
                        {presentation?.user?.name}
                      </Typography>
                      <Typography
                        // variant="body1"
                        variant="subtitle1"
                      >
                        {/* {user?.title || 'LidoHome'} */}
                        {presentation?.user?.title}
                      </Typography>
                    </Grid>
                  </Box>
                }
              />
              <Divider />
              <CardContent>
                <Box sx={{ mt: 1 }}>
                  <Box display="flex">
                    <Typography variant="h6" sx={{ mr: 2 }}>
                      Telefon:
                    </Typography>
                    <Typography variant="body1" mt={0.4}>
                      {/* {presentation.user.phone ? (
                        user.phone.includes('*') ? user.phone : parsePhoneNumber(user?.phone).formatInternational()
                      ) : (
                        'Nincs megadva'
                      )} */}
                      {/* {user.phone || '+36 20 443 5564'} */}
                      {presentation?.user?.phone}
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography variant="h6" sx={{ mr: 3 }}>
                      E-mail:
                    </Typography>
                    <Typography variant="body1" mt={0.4}>
                      {/* {presentation.user.email || 'testemail@lidohome.hu'} */}
                      {presentation?.user?.email}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
              {/* <Box mt={1}>
                <Button
                  variant="contained"
                  fullWidth
                >
                  Helyszíni bemutatót igényelek!
                </Button>
              </Box> */}
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={20}>
        <Grid item md={12} xs={12} display="flex" justifyContent="center">
          <Typography variant="h6">
            Önnek a megadott paraméterek alapján keresünk ingatlant
          </Typography>
        </Grid>
        <Grid
          item
          sx={{ mt: 2 }}
          md={12}
          xs={12}
          display="flex"
          justifyContent="center"
        >
          <Box width="100%">
            {presentation?.client?.searches.length > 0 && <ClientSearches />}
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 10, marginBottom: 10 }} />
      <Box paddingTop={10} paddingBottom={10} mt={10}>
        {presentation?.properties?.map((property) => (
          <Fragment key={property.id}>
            <WebPresentationBrowseResults
              presUser={presentation.user}
              property={property}
              uuid={uuid}
            />
            <Divider
              sx={{
                marginTop: 10,
                marginBottom: 10,
              }}
            />
          </Fragment>
        ))}
        {/* <WebPresentationBrowseResults property={presentation?.properties[0]} />
        <WebPresentationBrowseResults property={presentation?.properties[0]} /> */}
      </Box>
      <Grid
        container
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {/* <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-evenly"
        > */}
        <Grid item padding={1}>
          <Button
            variant="contained"
            onClick={() => {
              parameterChangeRequest();
            }}
          >
            Szeretném módosítani a keresési paramétereimet
          </Button>
        </Grid>
        <Grid item padding={1}>
          <Typography
            // variant="contained"
            // color="error"
            // component={RouterLink} // TODO: probaby these three will needed but find out later
            // to={`/properties/${search?.id}`}
            // target="_blank"
            onClick={() => {
              unsubscribe();
            }}
            variant="body2"
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Nem szeretnék ilyen jellegű leveleket kapni
          </Typography>
        </Grid>
        {/* </Grid> */}
        <Grid item xs={12} display="flex" justifyContent="center" padding={2}>
          <img src="/static/idaminta.png" alt="indaminta" />
        </Grid>
      </Grid>
    </Container>
  );
};

export default WebPresentationBrowse;
