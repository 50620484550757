import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getProjects } from '../app/slices/property';
import { useDispatch, useSelector } from '../app/store';
import ProjectCard from './ProjectCard';
import { selectLoading } from '../app/slices/loading';
import LidoBanner from '../utils/LidoBanner';
import projectBanner from '../images/projectBanner.png';
import projectsParallax from '../images/projectsParallax.jpg';
import { Helmet } from 'react-helmet-async';
import ParallaxSection from './ParallaxSection';

const Projects = () => {
  const { projects } = useSelector((state) => state.property);
  const [projectArray, setProjectArray] = useState(projects?.data || [...Array(24)]);
  const isLoadingArray = useSelector(selectLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjects());
  }, []);

  useEffect(() => {
    setProjectArray(projects?.data || [...Array(24)]);
  }, [projects]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'Pageview',
      pagePath: '/projects/allProjects',
      pageTitle: 'LidoHome | Összes projekt',
    });
  }, []);

  return (
    <Grid>
      <Helmet>
        <title>LidoHome | Összes projekt</title>
      </Helmet>
      <LidoBanner
        text="Elérhető projektek"
        image={projectBanner}
      />
      <ParallaxSection bg={projectsParallax}>
        <Grid
          container
          spacing={5}
          my={10}
        >
          {projectArray.map((project, i) => (
            <Grid
              item
              xl={4}
              lg={4}
              md={6}
              sm={12}
              xs={12}
              key={project?.id || i}
            >
              <ProjectCard
                project={project}
                loading={!projects?.data || isLoadingArray.includes('GET_PROJECTS')}
              />
            </Grid>
          ))}
        </Grid>
      </ParallaxSection>
    </Grid>
  );
};

export default Projects;
