import {
  CardMedia,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect } from 'react';
import LidoBanner from '../utils/LidoBanner';
import { useTranslation } from 'react-i18next';
import investBanner from '../images/investBanner.png';
import invest1 from '../images/invest1-new.jpeg';
import invest2 from '../images/invest2-new.jpeg';
import invest3 from '../images/invest3-new.jpeg';
import invest4 from '../images/invest4-new.jpeg';
import invest5 from '../images/invest5.png';
import invest6 from '../images/invest6.jpg';
import invest7 from '../images/invest7.jpg';
import invest8 from '../images/invest8.jpg';
import invest9 from '../images/invest9.jpeg';
import invest10 from '../images/invest10.jpg';
import invest11 from '../images/invest11.jpg';
import invest12 from '../images/invest12.jpg';
import parallax1 from '../images/investparallax1.jpg';
import parallax2 from '../images/investparallax2.jpg';
import parallax3 from '../images/investparallax3.jpg';
import PropTypes from 'prop-types';
import StyledTypographyUnderline from '../utils/StyledTypographyUnderline';
import DidYouKnow from '../utils/DidYouKnow';
import ParallaxSection from './ParallaxSection';
import { Helmet } from 'react-helmet-async';

const Invest = () => {
  const { t } = useTranslation();
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'Pageview',
      pagePath: '/investment',
      pageTitle: 'LidoHome | Beruházások',
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>LidoHome | Beruházások</title>
      </Helmet>
      <Grid>
        <LidoBanner
          image={investBanner}
          text={t('invest.title')}
          subText={t('invest.subtitle')}
        />
        <Container
          md={12}
        >
          <Typography
            variant="h4"
            color="primary"
            textAlign="center"
            fontWeight="bolder"
            my={10}
          >
            {t('invest.1')}
          </Typography>
        </Container>
        <Grid
          display="flex"
          flexDirection="column"
          gap={8}
        >
          <ParallaxSection bg={parallax1}>
            <Grid container>
              <ProjectContent
                title="2"
                items={['3', '4']}
                listItems={['5', '6', '7', '8']}
              />
              <Grid
                item
                md={6}
                xs={12}
              >
                <CardMedia
                  image={invest1}
                  sx={{
                    height: 400,
                    borderRadius: 1,
                  }}
                />
              </Grid>
              <ProjectImages
                images={[invest2, invest3, invest4]}
                height={250}
              />
            </Grid>
          </ParallaxSection>
          <ParallaxSection bg={parallax2}>
            <Grid
              container
              display="flex"
              direction={mdDown ? "column-reverse" : "row"}
            >
              <Grid
                item
                md={6}
                xs={12}
                p={2}
              >
                <CardMedia
                  image={invest5}
                  sx={{
                    height: 350,
                    mt: 2,
                    borderRadius: 1,
                  }}
                />
                <ProjectImages
                  images={[invest6, invest7, invest8]}
                  height={200}
                />
              </Grid>
              <ProjectContent
                title="9"
                items={['10', '11', '12', '13', '14']}
                listItems={['15', '16', '17', '18']}
              />
            </Grid>
          </ParallaxSection>
          <ParallaxSection bg={parallax3}>
            <Grid container>
              <ProjectContent
                title="19"
                items={['20', '21', '22', '23', '24']}
                listItems={['25', '26', '27', '28']}
                md={7}
              />
              <Grid
                item
                md={5}
                xs={12}
                p={2}
              >
                <CardMedia
                  image={invest9}
                  sx={{
                    height: 550,
                    borderRadius: 1,
                  }}
                />
              </Grid>
              <ProjectImages
                images={[invest10, invest11, invest12]}
                height={250}
              />
            </Grid>
          </ParallaxSection>
        </Grid>
        <Container>
          <Grid
            container
            px={2}
            data-aos={!lgDown && 'fade-right'}
          >
            <DidYouKnow
              text1={t('invest.29')}
              text2={t('invest.30')}
            />
          </Grid>
        </Container>
      </Grid>
    </>
  );
};

export default Invest;

const ProjectContent = ({ title, items, listItems, md }) => {
  const { t } = useTranslation();
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  return (
    <Grid
      item
      md={md ?? 6}
      xs={12}
      p={2}
      display="flex"
      flexDirection="column"
    >
      <StyledTypographyUnderline>
        {t(`invest.${title}`)}
      </StyledTypographyUnderline>

      <Stack
        useFlexGap
        spacing={2}
        mt={5}
      >
        {items.map((item, index) => (
          <Typography
            key={item}
            variant="h7"
            data-aos={!lgDown && 'fade-up'}
            data-aos-delay={!lgDown && 100 * index}
            sx={{
              fontWeight: 'bold',
            }}
          >
            {t(`invest.${item}`)}
          </Typography>
        ))}
      </Stack>

      <ul style={{ marginTop: 5 }}>
        {listItems.map((item, index) => (
          <li
            key={item}
            data-aos={!lgDown && 'fade-up'}
            data-aos-delay={!lgDown && 200 + 100 * index}
          >
            <Typography
              variant="h7"
              sx={{
                fontWeight: 'bold',
              }}
            >
              {t(`invest.${item}`)}
            </Typography>
          </li>
        ))}
      </ul>
    </Grid>
  );
};

ProjectContent.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  listItems: PropTypes.array.isRequired,
  md: PropTypes.number,
};

const ProjectImages = ({ images, height }) => (
  <Grid
    item
    md={12}
    xs={12}
    mt={2}
    sx={{
      display: 'flex',
      flexDirection: {
        xs: 'column',
        md: 'row',
      },
      justifyContent: 'space-evenly',
      gap: 2,
    }}
  >
    {images.map((image) => (
      <CardMedia
        key={image}
        image={image}
        sx={{
          height: { height },
          width: '100%',
          borderRadius: 1,
        }}
      />
    ))}
  </Grid>
);

ProjectImages.propTypes = {
  images: PropTypes.array.isRequired,
  height: PropTypes.number.isRequired,
};
