import React from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  CircularProgress,
  styled,
  TextField,
} from '@mui/material';

const defaultGetOptionLabel = (option) => option.label || option.name || option;

const defaultIsOptionToValue = (option, itemValue) => option.id === itemValue.id;

const AOCAutocomplete = (
  {
    getOptionLabel,
    isOptionEqualToValue,
    multiple,
    limitTags,
    options,
    disableCloseOnSelect,
    onChange,
    disableClearable,
    value,
    label,
    loadingArray,
    readOnly,
    placeholder,
    variant,
    freeSolo,
    fullWidth,
    textFieldOnChange,
    noOptionsText,
    error,
    helperText,
    renderOption,
    required,
    disabled,
    open,
    onFocus,
    onBlur,
    onClick,
    endAdornemntIcon,
    disabledItemsFocusable,
    size,
    autoFocus,
    popupIcon,
    sx,
    isBold,
  }
) => {

  return (
    <Autocomplete
      getOptionLabel={getOptionLabel || defaultGetOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue || defaultIsOptionToValue}
      multiple={multiple}
      fullWidth={fullWidth}
      limitTags={limitTags || 2}
      loadingText="Töltés..."
      loading={loadingArray}
      options={options || []}
      disableCloseOnSelect={disableCloseOnSelect}
      noOptionsText={noOptionsText || 'Nincs találat'}
      onChange={onChange}
      disableClearable={disableClearable}
      disabled={disabled}
      value={value}
      readOnly={readOnly}
      freeSolo={freeSolo}
      openText=""
      closeText=""
      clearText="Összes kiválasztás visszavonása"
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: isBold && 'black',
          },
        },
        '& .MuiInputLabel-root': {
          color: 'black',
          fontWeight: isBold && 800,
        },
        "& input::placeholder": {
          fontSize: "14px",
        },
        ...sx,
      }}
      open={open}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      popupIcon={popupIcon}
      renderOption={renderOption}
      disabledItemsFocusable={disabledItemsFocusable}
      size={size}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={autoFocus}
          error={error}
          helperText={helperText}
          label={label}
          onClick={onClick}
          required={required}
          placeholder={placeholder}
          onChange={textFieldOnChange}
          variant={variant}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loadingArray ? (
                  <CircularProgress
                    size={20}
                  />
                ) : endAdornemntIcon}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

AOCAutocomplete.propTypes = {
  autoFocus: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  disableClearable: PropTypes.bool,
  disabled: PropTypes.bool,
  textFieldOnChange: PropTypes.func,
  value: PropTypes.any,
  isOptionEqualToValue: PropTypes.func,
  multiple: PropTypes.bool,
  limitTags: PropTypes.number,
  disableCloseOnSelect: PropTypes.bool,
  label: PropTypes.string,
  getOptionLabel: PropTypes.any,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  noOptionsText: PropTypes.string,
  loadingArray: PropTypes.any,
  error: PropTypes.bool,
  helperText: PropTypes.any,
  readOnly: PropTypes.bool,
  freeSolo: PropTypes.bool,
  fullWidth: PropTypes.bool,
  renderOption: PropTypes.func,
  sx: PropTypes.object,
  open: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  required: PropTypes.bool,
  endAdornemntIcon: PropTypes.object,
  popupIcon: PropTypes.object,
  disabledItemsFocusable: PropTypes.bool,
  size: PropTypes.string,
  isBold: PropTypes.bool,
};

export default AOCAutocomplete;
