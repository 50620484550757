import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import { experimentalStyled } from "@mui/material";

const Logo = (props) => {
  const { home, height, width, homeNav, visibility } = props;
  const color = ({ theme }) => theme.palette.navigation.active;

  const useStyles = makeStyles()(() => ({
    logoText: {
      fill:
        homeNav && homeNav === true
          ? color
          : home && home === true
            ? "#fff"
            : "#000",
    },
  }));
  const useStyles2 = makeStyles()(() => ({
    logoText: {
      fill:
        homeNav && homeNav === true ? color : home && home === true && "#fff",
    },
  }));

  const LogoRoot = experimentalStyled("svg")(({ theme }) => ({
    fill: home ? "#fff" : theme.palette.navigation.active,
    zIndex: 1,
    visibility: visibility ? visibility : "visible",
  }));

  const { classes } = useStyles();
  const { classes: classes2 } = useStyles2();

  return (
    <LogoRoot
      height={height ? height : "95"}
      version="1.1"
      viewBox="0 0 298.8 75"
      width={width ? width : "298.8"}
      {...props}
    >
      <title>LidoHome</title>
      <g>
        <g>
          <defs>
            <circle id="SVGID_1_" cx="150.4" cy="42.5" r="42.5" />
          </defs>
          <clipPath id="SVGID_2_">
            <use href="#SVGID_1_" style={{ overflow: "visible" }} />
          </clipPath>
        </g>
        <path
          className={classes.logoText}
          d="M227.8,51.2c0,0.3,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.1-0.5,0c-1.9-0.2-3.8-0.2-5.7-0.1c-0.4,0-0.6,0-0.7-0.1
c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.3,0.3-0.5,0.8-0.6c0.8-0.1,1.2-0.5,1.2-1.3v-5.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.1-0.3-0.2-0.7-0.2
h-10.2c-0.4,0-0.7,0.1-0.8,0.2c-0.1,0.1-0.2,0.4-0.2,0.8v5.7c0,0.6,0.6,1,1.8,1.3c0.5,0.1,0.8,0.3,0.8,0.6c0,0.2,0,0.4-0.1,0.4
c-0.1,0.1-0.3,0.1-0.7,0.1c-1.9-0.1-3.8,0-5.7,0.1c-0.3,0-0.5,0-0.5,0c-0.1-0.1-0.1-0.2-0.1-0.5c0-0.3,0.2-0.4,0.6-0.4
c0.9-0.1,1.4-0.6,1.4-1.5V35.3c0-0.9-0.5-1.5-1.6-1.7c-0.4-0.1-0.6-0.2-0.7-0.2c-0.1,0-0.1-0.1-0.1-0.3c0-0.1,0-0.2,0-0.3
c0.1-0.2,0.3-0.3,0.9-0.2c2.4,0.1,4.4,0.1,5.9,0c0.3,0,0.4,0,0.5,0.1c0.1,0.1,0.1,0.2,0.1,0.4c0,0.2-0.2,0.3-0.7,0.4
c-0.8,0.2-1.2,0.3-1.4,0.4c-0.3,0.2-0.4,0.6-0.4,1.2V40c0,0.7,0.3,1,1,1h10.1c0.4,0,0.6,0,0.7-0.1c0.1-0.1,0.2-0.4,0.2-0.9v-5
c0-0.5-0.1-0.9-0.4-1c-0.2-0.1-0.6-0.2-1.2-0.3c-0.5-0.1-0.8-0.3-0.8-0.5c0-0.3,0-0.4,0.1-0.5c0.1-0.1,0.3-0.1,0.8,0
c1.1,0.1,2.2,0.1,3.3,0.1c0.8,0,1.6-0.1,2.4-0.1c0.3,0,0.4,0,0.5,0.1c0.1,0,0.1,0.2,0.1,0.5c0,0.2-0.2,0.4-0.6,0.4
c-1.1,0.1-1.6,0.6-1.6,1.5v14.2c0,0.5,0.2,0.8,0.5,1c0.3,0.2,0.8,0.3,1.5,0.5C227.6,50.8,227.8,51,227.8,51.2L227.8,51.2z
 M251.9,42.1c0,2.8-1,5.1-3.1,7.1c-2,1.9-4.4,2.9-7.3,2.9c-3,0-5.5-0.9-7.4-2.7c-2-1.8-3-4.2-3-7.2c0-2.8,1-5.2,3.1-7.1
c2-1.9,4.5-2.8,7.4-2.8c3,0,5.4,0.9,7.4,2.8C250.9,36.8,251.9,39.2,251.9,42.1L251.9,42.1z M249,42.5c0-2.8-0.8-5-2.3-6.6
c-1.5-1.6-3.4-2.3-5.8-2.3c-2,0-3.6,0.7-4.9,2.2c-1.3,1.5-1.9,3.4-1.9,5.8c0,2.8,0.7,5.1,2.2,6.7c1.5,1.6,3.4,2.4,5.9,2.4
c2,0,3.6-0.7,4.9-2.2C248.4,46.9,249,45,249,42.5L249,42.5z M280.6,51.1c0,0.2,0,0.4-0.2,0.4c-0.1,0.1-0.4,0.1-0.8,0.1
c-2.5-0.1-4.5-0.1-5.8,0c-0.4,0-0.7-0.2-0.7-0.5c0-0.2,0.2-0.3,0.7-0.4c0.9-0.2,1.4-0.4,1.5-0.5c0.2-0.2,0.3-0.5,0.3-1.1l-0.5-12.3
c0-0.3-0.1-0.4-0.2-0.4c-0.1,0-0.2,0.1-0.3,0.3c-1.7,3.5-2.9,5.9-3.6,7.4c-1.4,2.9-2.4,5.1-3,6.6c-0.3,0.7-0.4,1.1-0.4,1.1
c-0.1,0.2-0.2,0.3-0.4,0.3c-0.1,0-0.2,0-0.2,0c-0.2,0-0.4-0.3-0.5-0.9c-0.6-2.2-2.7-7-6.3-14.5c-0.1-0.2-0.2-0.3-0.3-0.3
c-0.2,0-0.2,0.1-0.2,0.4c-0.1,2.9-0.3,6.7-0.5,11.4c-0.1,1.3,0.6,2,1.9,2.3c0.5,0.1,0.8,0.3,0.8,0.6c0,0.2,0,0.4-0.2,0.5
c-0.1,0.1-0.3,0.1-0.8,0.1c-2.2-0.1-3.9-0.1-5.2,0c-0.4,0-0.7-0.2-0.7-0.5c0-0.2,0.2-0.3,0.7-0.4c0.8-0.2,1.3-0.4,1.5-0.8
c0.2-0.3,0.3-0.8,0.3-1.5l0.5-12.8c0-0.6-0.1-1-0.3-1.3c-0.2-0.3-0.6-0.5-1.3-0.8c-0.5-0.2-0.7-0.4-0.7-0.7c0-0.3,0.2-0.4,0.6-0.3
c1.5,0.2,2.8,0.2,3.9,0.1c0.4,0,0.7,0,0.9,0c0.2,0.1,0.3,0.2,0.4,0.5l6,13.8c0.1,0.2,0.1,0.3,0.2,0.3c0.1,0,0.1-0.1,0.3-0.4
c0.4-0.8,2.5-5.3,6.5-13.4c0.2-0.5,0.5-0.7,0.9-0.7c0.9,0,2.1-0.1,3.7-0.4c0.4-0.1,0.6-0.1,0.7-0.1c0.2,0,0.2,0.2,0.2,0.5
c0,0.2-0.3,0.4-0.8,0.6c-1.2,0.4-1.8,0.8-1.8,1.4l0.5,14c0,0.6,0.2,1,0.4,1.2c0.2,0.2,0.6,0.4,1.2,0.5
C280.3,50.6,280.6,50.8,280.6,51.1L280.6,51.1z M298.8,48c-0.3,1.1-0.5,2.3-0.5,3.5c0,0.2-0.3,0.3-0.9,0.2
c-1.5-0.1-3.4-0.1-5.7-0.1c-2.6,0-4.8,0-6.6,0.1c-0.4,0-0.6-0.2-0.6-0.5c0-0.3,0.2-0.4,0.6-0.4c0.6-0.1,1-0.2,1.1-0.3
c0.2-0.2,0.3-0.6,0.3-1.2V35.9c0-0.9-0.1-1.5-0.2-1.7c-0.2-0.2-0.6-0.5-1.4-0.7c-0.4-0.1-0.5-0.4-0.4-0.7c0-0.2,0.1-0.3,0.2-0.3
c0.1,0,0.4,0,0.8,0.1c0.6,0.1,2.2,0.1,4.8,0.1c2.6,0,4.8-0.1,6.7-0.2c0.6,0,0.9,0,0.9,0.1c0,0,0,0.1,0,0.1c-0.2,0.9-0.3,2-0.3,3.2
c0,0.3-0.1,0.4-0.4,0.4c-0.3,0-0.5-0.1-0.6-0.4c-0.3-0.7-0.5-1.1-0.7-1.4c-0.3-0.4-0.8-0.6-1.3-0.7c-0.5-0.1-2.1-0.1-4.9-0.1
c-0.3,0-0.5,0.1-0.6,0.2c0,0.1-0.1,0.2-0.1,0.4v6c0,0.2,0.1,0.4,0.4,0.4c0.9,0,2.2-0.1,3.8-0.2c0.3,0,0.5-0.2,0.6-0.4
c0.1-0.2,0.2-0.6,0.3-1.2c0.1-0.4,0.3-0.6,0.7-0.4c0.3,0.1,0.5,0.2,0.4,0.4c-0.3,1.3-0.4,3-0.3,5.1c0,0.2-0.1,0.4-0.5,0.4
c-0.3,0-0.5-0.1-0.5-0.5c-0.1-0.7-0.2-1.2-0.4-1.4c-0.1-0.2-0.4-0.4-0.8-0.4c-0.8-0.1-2-0.1-3.6-0.1c-0.2,0-0.3,0.1-0.3,0.2v6.3
c0,0.9,0.5,1.4,1.6,1.6c0.3,0,1.2,0.1,2.7,0.1c2.4,0,3.9-0.8,4.5-2.5c0.1-0.4,0.4-0.5,0.7-0.4C298.8,47.5,298.9,47.7,298.8,48z"
        />
        <path
          className={classes.logoText}
          d="M14.1,48c-0.3,1.2-0.5,2.4-0.5,3.5c0,0.2-0.3,0.3-0.9,0.2c-1.5-0.1-3.4-0.1-5.6-0.1c-2.4,0-4.5,0-6.4,0.1
c-0.3,0-0.5,0-0.5-0.1C0,51.6,0,51.4,0,51.2c0-0.3,0.2-0.4,0.6-0.4C1.6,50.6,2,50.1,2,49.2V35.9c0-0.7-0.1-1.2-0.3-1.5
c-0.2-0.4-0.6-0.7-1.3-0.9C0.1,33.4,0,33.2,0,32.9c0-0.2,0.1-0.3,0.2-0.4c0.1,0,0.3,0,0.8,0c1.4,0.1,3.1,0.1,5,0c0.3,0,0.5,0,0.6,0
c0.2,0,0.3,0.2,0.3,0.4c0,0.3,0,0.4-0.2,0.5c-0.1,0-0.3,0.1-0.6,0.2c-0.7,0.2-1.1,0.3-1.3,0.5c-0.2,0.2-0.3,0.6-0.3,1.3v12.9
c0,0.8,0.3,1.4,0.8,1.7c0.5,0.3,1.5,0.4,3,0.4c1.4,0,2.4-0.2,3-0.5c0.7-0.3,1.2-1,1.6-2c0.2-0.4,0.4-0.5,0.7-0.4
C14,47.5,14.2,47.7,14.1,48L14.1,48z M31.3,32.9c0,0.2-0.3,0.4-0.8,0.6c-1.2,0.3-1.7,0.8-1.7,1.3v14c0,0.9,0.5,1.5,1.6,1.6
c0.5,0.1,0.8,0.3,0.8,0.6c0,0.2,0,0.4-0.1,0.4c-0.1,0.1-0.3,0.1-0.7,0.1c-2.5-0.1-4.5-0.1-5.9,0c-0.4,0-0.6,0-0.6-0.2
c0-0.1,0-0.2,0-0.3c0-0.2,0.2-0.3,0.6-0.4c0.8-0.2,1.3-0.3,1.4-0.4c0.3-0.2,0.5-0.6,0.5-1.2V34.9c0-0.8-0.5-1.3-1.6-1.5
C24.2,33.4,24,33.2,24,33c0-0.3,0-0.4,0.1-0.5c0.1-0.1,0.2-0.1,0.5,0c1.9,0.2,3.8,0.2,5.8,0c0.4,0,0.6-0.1,0.7,0
C31.3,32.4,31.3,32.6,31.3,32.9L31.3,32.9z M62.1,41.7c0,3.3-1.1,5.9-3.3,7.6c-2.1,1.6-4.9,2.4-8.6,2.4c-0.3,0-1,0-2.1-0.1
c-1.1-0.1-1.9-0.1-2.5-0.1c-0.8,0-1.7,0-2.6,0.1c-0.4,0-0.6-0.1-0.6-0.5c0-0.3,0.2-0.4,0.6-0.4c0.9-0.1,1.4-0.6,1.4-1.5V35.4
c0-0.5-0.1-0.9-0.3-1.1c-0.2-0.1-0.6-0.3-1.1-0.4c-0.5-0.1-0.8-0.2-0.9-0.2c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.2,0.1-0.4,0.3-0.4
c0,0,0.3,0,0.6-0.1c2.1-0.1,4.8-0.2,8.2-0.2c3.3,0,6,0.8,8,2.4C61,36.6,62.1,38.9,62.1,41.7L62.1,41.7z M59.1,42.1
c0-5.6-2.9-8.4-8.6-8.4c-1.3,0-2,0-2,0c-1.1,0.1-1.6,0.4-1.6,0.9V48c0,0.9,0.3,1.6,0.7,1.9c0.5,0.3,1.5,0.5,3,0.5
C56.2,50.4,59.1,47.6,59.1,42.1L59.1,42.1z M93.3,42.1c0,2.8-1,5.1-3.1,7.1c-2,1.9-4.4,2.9-7.3,2.9c-3,0-5.5-0.9-7.4-2.7
c-2-1.8-3-4.2-3-7.2c0-2.8,1-5.2,3.1-7.1c2-1.9,4.5-2.8,7.4-2.8c3,0,5.4,0.9,7.4,2.8C92.3,36.8,93.3,39.2,93.3,42.1L93.3,42.1z
 M90.3,42.5c0-2.8-0.8-5-2.3-6.6c-1.5-1.6-3.4-2.3-5.8-2.3c-2,0-3.6,0.7-4.9,2.2c-1.3,1.5-1.9,3.4-1.9,5.8c0,2.8,0.7,5.1,2.2,6.7
c1.5,1.6,3.4,2.4,5.9,2.4c2,0,3.6-0.7,4.9-2.2C89.7,46.9,90.3,45,90.3,42.5z"
        />
        <g>
          <path
            className={classes2.logoText}
            d="M150.4,13.6c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8
C149.6,13.3,150,13.6,150.4,13.6L150.4,13.6z M150.4,7.7c0.4-0.1,0.9-2.7,2.4-3.7c0.1-0.1,0.2-0.6-0.2-0.5
c-1.1,0.3-1.4-0.4-2.2-0.5c-0.8,0-1.1,0.8-2.2,0.5C147.8,3.4,147.9,4,148,4C149.6,5,150.1,7.7,150.4,7.7L150.4,7.7z M179.3,42.5
c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8C179.7,41.7,179.3,42.1,179.3,42.5L179.3,42.5z M185.2,42.5
c0.1,0.4,2.7,0.9,3.7,2.4c0.1,0.1,0.6,0.2,0.5-0.2c-0.3-1.1,0.4-1.4,0.5-2.2c0-0.8-0.8-1.1-0.5-2.2c0.1-0.5-0.4-0.4-0.5-0.2
C187.9,41.7,185.3,42.1,185.2,42.5L185.2,42.5z M170.9,62.9c-0.3,0.3-0.3,0.9,0,1.2c0.3,0.3,0.9,0.3,1.2,0c0.3-0.3,0.3-0.9,0-1.2
C171.7,62.6,171.2,62.6,170.9,62.9L170.9,62.9z M175.1,67.1c-0.2,0.3,1.3,2.5,0.9,4.3c0,0.2,0.3,0.6,0.5,0.2
c0.6-1,1.3-0.7,1.9-1.2c0.6-0.6,0.2-1.3,1.2-1.9c0.4-0.2,0-0.5-0.2-0.5C177.6,68.4,175.4,66.9,175.1,67.1L175.1,67.1z M180.4,54.9
c-2.6-5.4-1.3-11.7,3.6-11.6c2.5,0,5.3,2.1,5.2,5.2c-0.1,4.4-3.1,6.2-3.2,9.6c0,0.8-0.7,0.5-0.9,0.2c-1.4-1.6-1.4-3.2-0.3-4
c1.1-0.8,2.4-2.3,2.7-3.8c0.1-0.5-0.2-0.6-0.3-0.2c-0.5,1.7-1.8,2.8-2.9,3.7c-0.2,0.1-0.4,0.1-0.6,0c-0.4-0.1-0.8-0.3-1-0.6
c-0.1-0.1-0.1-0.3-0.2-0.5c-0.1-0.5-0.1-1-0.1-1.4c0.1-0.4,0.4-0.7,0.8-0.8c0.8-0.2,2-0.6,2.5-1.4c0.4-0.6,0.2-0.7-0.2-0.3
c-1.8,1.8-3.2,1.2-3.6,0.2c-0.3-0.7,0.1-1.8,0.4-2.3c0.2-0.3,0.3-0.3,0.4-0.1c0.9,1.4,2.4,0.5,2.4-0.4c0-0.8-1.2-1.2-2-1.1
c-2.1,0-3.8,3.4-1.2,8.9c2.6,5.4,2.2,12.6-3.9,13.2c-2.4,0.2-5.1-2.3-5.2-5.2c-0.1-3.8,3.9-5.1,4.5-8.9c0.1-0.8,0.7-0.3,0.9-0.1
c1.3,1.4,1.3,3.1,0.1,3.9c-1.2,0.8-1.5,1-2.6,1.8c-0.4,0.3-0.1,0.5,0.2,0.3c1-0.7,1.9-1.1,3.1-1.8c0.2-0.1,0.4,0,0.6,0.1
c0.3,0.2,0.8,0.6,1.1,1c0.1,0.2,0.1,0.4,0.1,0.5c0,0.5-0.2,1.1-0.3,1.5c-0.1,0.4-0.4,0.4-0.9,0.4c-0.9,0-2-0.1-2.9,0.4
c-0.6,0.3-0.5,0.5,0.1,0.3c2.1-0.7,3.3-0.6,3.6,0.4c0.2,1-0.7,1.9-1.3,2.2c-0.4,0.2-0.6,0.1-0.6-0.1c-0.2-2.4-2.8-1.8-2.6,0
c0.1,1,1,1.8,3.1,1.3C181.9,64.8,183.1,60.4,180.4,54.9L180.4,54.9z M180.4,30.1c2.7-5.5,1.5-9.9-1.3-10.5
c-2.1-0.4-2.9,0.3-3.1,1.3c-0.2,1.8,2.4,2.4,2.6,0c0-0.2,0.2-0.3,0.6-0.1c0.5,0.3,1.5,1.3,1.3,2.2c-0.2,1-1.4,1.1-3.6,0.4
c-0.5-0.2-0.6,0-0.1,0.3c0.8,0.5,2,0.4,2.9,0.4c0.4,0,0.7,0,0.9,0.4c0.1,0.4,0.3,1,0.3,1.5c0,0.2,0,0.4-0.1,0.5
c-0.2,0.3-0.7,0.8-1.1,1c-0.2,0.1-0.4,0.2-0.6,0.1c-1.2-0.7-2.1-1.1-3.1-1.8c-0.3-0.2-0.7,0-0.2,0.3c1.1,0.8,1.4,1,2.6,1.8
c1.2,0.8,1.2,2.6-0.1,3.9c-0.2,0.2-0.8,0.7-0.9-0.1c-0.6-3.8-4.6-5.1-4.5-8.9c0.1-2.9,2.7-5.4,5.2-5.2c6,0.5,6.5,7.7,3.9,13.2
c-2.7,5.5-0.9,8.9,1.2,8.9c0.8,0,2-0.4,2-1.1c0-0.9-1.4-1.8-2.4-0.4c-0.1,0.1-0.2,0.2-0.4-0.1c-0.3-0.5-0.7-1.5-0.4-2.3
c0.4-1,1.7-1.6,3.6,0.2c0.4,0.4,0.6,0.2,0.2-0.3c-0.5-0.8-1.6-1.2-2.5-1.4c-0.4-0.1-0.8-0.4-0.8-0.8c-0.1-0.4,0-0.9,0.1-1.4
c0-0.2,0-0.4,0.2-0.5c0.3-0.3,0.7-0.5,1-0.6c0.2-0.1,0.4-0.1,0.6,0c1.1,0.9,2.4,2,2.9,3.7c0.1,0.4,0.4,0.3,0.3-0.2
c-0.4-1.5-1.6-3-2.7-3.8c-1.1-0.8-1-2.5,0.3-4c0.2-0.2,0.9-0.5,0.9,0.2c0.1,3.4,3.1,5.2,3.2,9.6c0.1,3.1-2.7,5.2-5.2,5.2
C179.1,41.8,177.8,35.6,180.4,30.1L180.4,30.1z M150.4,71.4c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8
C151.3,71.8,150.9,71.4,150.4,71.4L150.4,71.4z M150.4,77.3c-0.4,0.1-0.9,2.7-2.4,3.7c-0.1,0.1-0.2,0.6,0.2,0.5
c1.1-0.3,1.4,0.4,2.2,0.5c0.8,0,1.1-0.8,2.2-0.5c0.5,0.1,0.4-0.4,0.2-0.5C151.3,80,150.8,77.4,150.4,77.3L150.4,77.3z M130,62.9
c-0.3-0.3-0.9-0.3-1.2,0c-0.3,0.3-0.3,0.9,0,1.2s0.9,0.3,1.2,0C130.3,63.8,130.3,63.3,130,62.9L130,62.9z M125.8,67.1
c-0.3-0.2-2.5,1.3-4.3,0.9c-0.2,0-0.6,0.3-0.2,0.5c1,0.6,0.7,1.3,1.2,1.9c0.6,0.6,1.3,0.2,1.9,1.2c0.2,0.4,0.5,0,0.5-0.2
C124.5,69.6,126.1,67.5,125.8,67.1L125.8,67.1z M138,72.5c5.4-2.6,11.7-1.3,11.6,3.6c0,2.5-2.1,5.2-5.2,5.2
c-4.4-0.1-6.2-3.1-9.6-3.2c-0.8,0-0.5-0.7-0.2-0.9c1.6-1.4,3.2-1.4,4-0.3c0.8,1.1,2.3,2.4,3.8,2.7c0.5,0.1,0.6-0.2,0.2-0.3
c-1.7-0.5-2.8-1.8-3.7-2.9c-0.1-0.2-0.1-0.4,0-0.6c0.1-0.4,0.3-0.7,0.6-1c0.1-0.1,0.3-0.1,0.5-0.2c0.5-0.1,1-0.1,1.4-0.1
c0.4,0.1,0.7,0.4,0.8,0.8c0.2,0.8,0.6,2,1.4,2.5c0.6,0.4,0.7,0.2,0.3-0.2c-1.8-1.8-1.2-3.2-0.2-3.6c0.7-0.3,1.8,0.1,2.3,0.4
c0.3,0.2,0.3,0.3,0.1,0.4c-1.4,0.9-0.5,2.4,0.4,2.4c0.8,0,1.2-1.2,1.1-2c0-2.1-3.4-3.8-8.9-1.2c-5.4,2.6-12.6,2.2-13.2-3.9
c-0.2-2.4,2.3-5.1,5.2-5.2c3.8-0.1,5.1,3.9,8.9,4.5c0.8,0.1,0.3,0.7,0.1,0.9c-1.4,1.3-3.1,1.3-3.9,0.1c-0.8-1.2-1-1.5-1.8-2.6
c-0.3-0.4-0.5-0.1-0.3,0.2c0.7,1,1.1,1.9,1.8,3.1c0.1,0.2,0,0.4-0.1,0.6c-0.2,0.3-0.6,0.8-1,1.1c-0.2,0.1-0.4,0.1-0.5,0.1
c-0.5,0-1.1-0.2-1.5-0.3c-0.4-0.1-0.4-0.4-0.4-0.9c0-0.9,0.1-2-0.4-2.9c-0.3-0.6-0.5-0.4-0.3,0.1c0.7,2.1,0.6,3.3-0.4,3.6
c-1,0.2-1.9-0.7-2.2-1.3c-0.2-0.4-0.1-0.6,0.1-0.6c2.4-0.2,1.8-2.8,0-2.6c-1,0.1-1.8,1-1.3,3.1C128.2,74,132.5,75.1,138,72.5
L138,72.5z M162.8,72.5c5.5,2.7,9.9,1.5,10.5-1.3c0.4-2.1-0.3-2.9-1.3-3.1c-1.8-0.2-2.4,2.4,0,2.6c0.2,0,0.3,0.2,0.1,0.6
c-0.3,0.5-1.3,1.5-2.2,1.3c-1-0.2-1.1-1.4-0.4-3.6c0.2-0.5,0-0.6-0.3-0.1c-0.5,0.8-0.4,2-0.4,2.9c0,0.4,0,0.7-0.4,0.9
c-0.4,0.1-1,0.3-1.5,0.3c-0.2,0-0.4,0-0.5-0.1c-0.3-0.2-0.8-0.7-1-1.1c-0.1-0.2-0.2-0.4-0.1-0.6c0.7-1.2,1.1-2.1,1.8-3.1
c0.2-0.3,0-0.7-0.3-0.2c-0.8,1.1-1,1.4-1.8,2.6c-0.8,1.2-2.6,1.2-3.9-0.1c-0.2-0.2-0.7-0.8,0.1-0.9c3.8-0.6,5.1-4.6,8.9-4.5
c2.9,0.1,5.4,2.7,5.2,5.2c-0.5,6-7.7,6.5-13.2,3.9c-5.5-2.7-8.9-0.9-8.9,1.2c0,0.8,0.4,2,1.1,2c0.9,0,1.8-1.4,0.4-2.4
c-0.1-0.1-0.2-0.2,0.1-0.4c0.5-0.3,1.5-0.7,2.3-0.4c1,0.4,1.6,1.7-0.2,3.6c-0.4,0.4-0.2,0.6,0.3,0.2c0.8-0.5,1.2-1.6,1.4-2.5
c0.1-0.4,0.4-0.8,0.8-0.8c0.4-0.1,0.9,0,1.4,0.1c0.2,0,0.4,0,0.5,0.2c0.3,0.3,0.5,0.7,0.6,1c0.1,0.2,0.1,0.4,0,0.6
c-0.9,1.1-2,2.4-3.7,2.9c-0.4,0.1-0.3,0.4,0.2,0.3c1.5-0.4,3-1.6,3.8-2.7c0.8-1.1,2.5-1,4,0.3c0.2,0.2,0.5,0.9-0.2,0.9
c-3.4,0.1-5.2,3.1-9.6,3.2c-3.1,0.1-5.2-2.7-5.2-5.2C151.2,71.2,157.4,69.8,162.8,72.5L162.8,72.5z M121.6,42.5
c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8C121.2,43.4,121.6,43,121.6,42.5L121.6,42.5z M115.6,42.5
c-0.1-0.4-2.7-0.9-3.7-2.4c-0.1-0.1-0.6-0.2-0.5,0.2c0.3,1.1-0.4,1.4-0.5,2.2c0,0.8,0.8,1.1,0.5,2.2c-0.1,0.5,0.4,0.4,0.5,0.2
C112.9,43.4,115.6,42.9,115.6,42.5L115.6,42.5z M130,22.1c0.3-0.3,0.3-0.9,0-1.2c-0.3-0.3-0.9-0.3-1.2,0c-0.3,0.3-0.3,0.9,0,1.2
C129.2,22.4,129.7,22.4,130,22.1L130,22.1z M125.8,17.9c0.2-0.3-1.3-2.5-0.9-4.3c0-0.1-0.3-0.6-0.5-0.2c-0.6,1-1.3,0.7-1.9,1.2
c-0.6,0.6-0.2,1.3-1.2,1.9c-0.4,0.2,0,0.5,0.2,0.5C123.3,16.6,125.5,18.1,125.8,17.9L125.8,17.9z M120.5,30.1
c2.6,5.4,1.3,11.7-3.6,11.6c-2.5,0-5.3-2.1-5.2-5.2c0.1-4.4,3.1-6.2,3.2-9.6c0-0.8,0.7-0.5,0.9-0.2c1.4,1.6,1.4,3.2,0.3,4
c-1.1,0.8-2.4,2.3-2.7,3.8c-0.1,0.5,0.2,0.6,0.3,0.2c0.5-1.7,1.8-2.8,2.9-3.7c0.2-0.1,0.4-0.1,0.6,0c0.4,0.1,0.8,0.3,1,0.6
c0.1,0.1,0.1,0.3,0.2,0.5c0.1,0.5,0.1,1,0.1,1.4c-0.1,0.4-0.4,0.7-0.8,0.8c-0.8,0.2-2,0.6-2.5,1.4c-0.4,0.6-0.2,0.7,0.2,0.3
c1.8-1.8,3.2-1.2,3.6-0.2c0.3,0.7-0.1,1.8-0.4,2.3c-0.2,0.3-0.4,0.3-0.4,0.1c-0.9-1.4-2.4-0.5-2.4,0.4c0,0.8,1.2,1.2,2,1.1
c2.1,0,3.8-3.4,1.2-8.9c-2.6-5.4-2.2-12.6,3.9-13.2c2.4-0.2,5.1,2.3,5.2,5.2c0.1,3.8-3.9,5.1-4.5,8.9c-0.1,0.8-0.7,0.3-0.9,0.1
c-1.3-1.4-1.3-3.1-0.1-3.9c1.2-0.8,1.5-1,2.6-1.8c0.4-0.3,0.1-0.5-0.2-0.3c-1,0.7-1.9,1.1-3.1,1.8c-0.2,0.1-0.4,0-0.6-0.1
c-0.3-0.2-0.8-0.6-1.1-1c-0.1-0.2-0.1-0.4-0.1-0.5c0-0.5,0.2-1.1,0.3-1.5c0.1-0.4,0.4-0.4,0.9-0.4c0.9,0,2,0.1,2.9-0.4
c0.6-0.3,0.4-0.5-0.1-0.3c-2.1,0.7-3.3,0.6-3.6-0.4c-0.2-1,0.7-1.9,1.3-2.2c0.4-0.2,0.6-0.1,0.6,0.1c0.2,2.4,2.8,1.8,2.6,0
c-0.1-1-1-1.8-3.1-1.3C118.9,20.2,117.8,24.6,120.5,30.1L120.5,30.1z M120.5,54.9c-2.7,5.5-1.5,9.9,1.3,10.5
c2.1,0.4,2.9-0.3,3.1-1.3c0.2-1.8-2.4-2.4-2.6,0c0,0.2-0.2,0.3-0.6,0.1c-0.5-0.3-1.5-1.3-1.3-2.2c0.2-1,1.4-1.1,3.6-0.4
c0.5,0.2,0.6,0,0.1-0.3c-0.8-0.5-2-0.4-2.9-0.4c-0.4,0-0.7,0-0.9-0.4c-0.1-0.4-0.3-1-0.3-1.5c0-0.2,0-0.4,0.1-0.5
c0.2-0.3,0.7-0.8,1.1-1c0.2-0.1,0.4-0.2,0.6-0.1c1.2,0.7,2.1,1.1,3.1,1.8c0.3,0.2,0.7,0,0.2-0.3c-1.1-0.8-1.4-1-2.6-1.8
c-1.2-0.8-1.2-2.6,0.1-3.9c0.2-0.2,0.8-0.7,0.9,0.1c0.6,3.8,4.6,5.1,4.5,8.9c-0.1,2.9-2.7,5.4-5.2,5.2c-6-0.5-6.5-7.7-3.9-13.2
c2.7-5.5,0.9-8.9-1.2-8.9c-0.8,0-2,0.4-2,1.1c0,0.9,1.4,1.8,2.4,0.4c0.1-0.1,0.2-0.2,0.4,0.1c0.3,0.5,0.7,1.6,0.4,2.3
c-0.4,1-1.7,1.6-3.6-0.2c-0.4-0.4-0.6-0.2-0.2,0.3c0.5,0.8,1.6,1.2,2.5,1.4c0.4,0.1,0.8,0.4,0.8,0.8c0.1,0.4,0,0.9-0.1,1.4
c0,0.2,0,0.4-0.2,0.5c-0.3,0.3-0.7,0.5-1,0.6c-0.2,0.1-0.4,0.1-0.6,0c-1.1-0.9-2.4-2-2.9-3.7c-0.1-0.4-0.4-0.3-0.3,0.2
c0.4,1.5,1.6,3,2.7,3.8c1.1,0.8,1,2.5-0.3,4c-0.2,0.2-0.9,0.5-0.9-0.2c-0.1-3.4-3.1-5.2-3.2-9.6c-0.1-3.1,2.7-5.2,5.2-5.2
C121.8,43.3,123.1,49.5,120.5,54.9L120.5,54.9z M170.9,22.1c0.3,0.3,0.9,0.3,1.2,0c0.3-0.3,0.3-0.9,0-1.2c-0.3-0.3-0.9-0.3-1.2,0
C170.5,21.2,170.5,21.8,170.9,22.1L170.9,22.1z M175.1,17.9c0.3,0.2,2.5-1.3,4.3-0.9c0.2,0,0.6-0.3,0.2-0.5
c-1-0.6-0.7-1.3-1.2-1.9c-0.6-0.6-1.3-0.2-1.9-1.2c-0.2-0.4-0.5,0-0.5,0.2C176.4,15.4,174.8,17.6,175.1,17.9L175.1,17.9z
 M162.8,12.6c-5.4,2.6-11.7,1.3-11.6-3.6c0-2.5,2.1-5.2,5.2-5.2c4.4,0.1,6.2,3.1,9.6,3.2c0.8,0,0.5,0.7,0.2,0.9
c-1.6,1.4-3.2,1.4-4,0.3c-0.8-1.1-2.3-2.4-3.8-2.7c-0.5-0.1-0.6,0.2-0.2,0.3c1.7,0.5,2.8,1.8,3.7,2.9c0.1,0.2,0.1,0.4,0,0.6
c-0.1,0.4-0.3,0.7-0.6,1c-0.1,0.1-0.3,0.1-0.5,0.2c-0.5,0.1-1,0.1-1.4,0.1c-0.4-0.1-0.7-0.4-0.8-0.8c-0.2-0.8-0.6-2-1.4-2.5
c-0.6-0.4-0.7-0.2-0.3,0.2c1.8,1.8,1.2,3.2,0.2,3.6c-0.7,0.3-1.8-0.1-2.3-0.4c-0.3-0.2-0.3-0.4-0.1-0.4c1.4-0.9,0.5-2.4-0.4-2.4
c-0.8,0-1.2,1.2-1.1,2c0,2.1,3.4,3.8,8.9,1.2c5.4-2.6,12.6-2.2,13.2,3.9c0.2,2.4-2.3,5.1-5.2,5.2c-3.8,0.1-5.1-3.9-8.9-4.5
c-0.8-0.1-0.3-0.7-0.1-0.9c1.4-1.3,3.1-1.3,3.9-0.1c0.8,1.2,1,1.5,1.8,2.6c0.3,0.4,0.5,0.1,0.3-0.2c-0.7-1-1.1-1.9-1.8-3.1
c-0.1-0.2,0-0.4,0.1-0.6c0.2-0.3,0.6-0.8,1-1.1c0.2-0.1,0.4-0.1,0.5-0.1c0.5,0,1.1,0.2,1.5,0.3c0.4,0.1,0.4,0.4,0.4,0.9
c0,0.9-0.1,2,0.4,2.9c0.3,0.6,0.5,0.4,0.3-0.1c-0.7-2.1-0.6-3.3,0.4-3.6c1-0.2,1.9,0.7,2.2,1.3c0.2,0.4,0.1,0.6-0.1,0.6
c-2.4,0.2-1.8,2.8,0,2.6c1-0.1,1.8-1,1.3-3.1C172.7,11,168.3,9.9,162.8,12.6L162.8,12.6z M138,12.6c-5.5-2.7-9.9-1.5-10.5,1.3
c-0.4,2.1,0.3,2.9,1.3,3.1c1.8,0.2,2.4-2.4,0-2.6c-0.2,0-0.3-0.2-0.1-0.6c0.3-0.5,1.3-1.5,2.2-1.3c1,0.2,1.1,1.4,0.4,3.6
c-0.2,0.5,0,0.6,0.3,0.1c0.5-0.8,0.4-2,0.4-2.9c0-0.4,0-0.7,0.4-0.9c0.4-0.1,1-0.3,1.5-0.3c0.2,0,0.4,0,0.5,0.1
c0.3,0.2,0.8,0.7,1,1.1c0.1,0.2,0.2,0.4,0.1,0.6c-0.7,1.2-1.1,2.1-1.8,3.1c-0.2,0.3,0,0.7,0.3,0.2c0.8-1.1,1-1.4,1.8-2.6
c0.8-1.2,2.6-1.2,3.9,0.1c0.2,0.2,0.7,0.8-0.1,0.9c-3.8,0.6-5.1,4.6-8.9,4.5c-2.9-0.1-5.4-2.7-5.2-5.2c0.5-6,7.7-6.5,13.2-3.9
c5.5,2.7,8.9,0.9,8.9-1.2c0-0.8-0.4-2-1.1-2c-0.9,0-1.8,1.4-0.4,2.4c0.1,0.1,0.2,0.2-0.1,0.4c-0.5,0.3-1.6,0.7-2.3,0.4
c-1-0.4-1.6-1.7,0.2-3.6c0.4-0.4,0.2-0.6-0.3-0.2c-0.8,0.5-1.2,1.6-1.4,2.5c-0.1,0.4-0.4,0.8-0.8,0.8c-0.4,0.1-0.9,0-1.4-0.1
c-0.2,0-0.4,0-0.5-0.2c-0.3-0.3-0.5-0.7-0.6-1c-0.1-0.2-0.1-0.4,0-0.6c0.9-1.1,2-2.4,3.7-2.9c0.4-0.1,0.3-0.4-0.2-0.3
c-1.5,0.4-3,1.6-3.8,2.7c-0.8,1.1-2.5,1-4-0.3C134.3,7.6,134,7,134.8,7c3.4-0.1,5.2-3.1,9.6-3.2c3.1-0.1,5.2,2.7,5.2,5.2
C149.7,13.9,143.5,15.2,138,12.6z"
          />
          <path
            className={classes2.logoText}
            d="M145.1,55.7c-0.1,0.8,0.1,1.6,0.3,2.3c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.2,0-0.2-0.1c-0.5-0.8-1.1-1.6-1.6-2.3
c-0.2-0.3-0.4-0.6-0.7-0.9c-1,0-1.9,0-2.8,0c-0.8,0.1-1.6,0.2-2.2,0.3c-0.9,0.2-1.7,0.4-2.4,0.6c-0.1,0-0.2,0-0.2-0.1
c0-0.1,0-0.2,0.1-0.2c0.6-0.4,1.4-0.7,2.3-1c0.6-0.2,1.4-0.4,2.2-0.5c3-0.5,1.7,0.5,2.1-1.6l0-0.2c-0.5,0-1,0-1.5,0
c-1.7,0-3.1,0.1-4.4,0.2c-2.2,0.6-5.5,2.2-6.9,4.1c-0.2,0.3-0.3,0.6-0.4,0.9c-0.1,0.3-0.1,0.7-0.1,1c0.1,0.7,0.5,1.3,1,1.7
c0.5,0.5,1.2,0.8,1.9,0.9c0.7,0.1,1.4,0.2,2.1,0.1c0.7,0,1.4-0.1,2.2-0.3c0.7-0.1,1.4-0.3,2.1-0.5c0.6-0.5,1.3-0.8,2-1.1
c0.4-0.2,0.8-0.3,1.3-0.4c0.4-0.1,0.8-0.3,1.2-0.5c0.1-0.1,0.2-0.1,0.3,0c0.1,0.1,0.1,0.2,0,0.3c-0.4,0.4-0.8,0.8-1.2,1.1
c-0.5,0.3-1,0.6-1.5,0.7c-0.5,0.2-1.1,0.3-1.6,0.3c-0.5,0.1-1.1,0.1-1.6,0.2c-0.7,0.2-1.4,0.3-2.1,0.4c-0.7,0.1-1.4,0.1-2.2,0
c-0.8,0-1.5-0.2-2.1-0.5c-0.4-0.2-0.7-0.4-1-0.6c-0.3-0.2-0.5-0.5-0.7-0.8c-0.2-0.3-0.3-0.7-0.4-1.1c-0.1-0.4-0.1-0.8,0-1.2
c0.1-0.4,0.2-0.7,0.4-1c0.2-0.3,0.4-0.6,0.7-0.9c1.2-1.2,3.2-2.4,4.8-3c-1.1,0.2-2.6,0.5-3.7,1c-0.1,0-0.2,0-0.2-0.1
c0-0.1,0-0.2,0-0.2c0.8-0.6,1.8-1.1,3.2-1.6c0.8-0.3,1.8-0.5,2.9-0.8c1.2-0.2,2.5-0.4,4.1-0.5c0.6,0,1.2-0.1,1.8-0.1
c0-0.3,0.1-0.7,0.1-1c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.2-0.1c-0.6-0.2-1.3-0.5-2.2-0.8c-0.9-0.3-1.9-0.6-3-0.8
c-1.1-0.3-2.2-0.5-3.3-0.6c-1.1-0.2-2.2-0.2-3.1-0.2c-1,0-1.8,0.1-2.5,0.4c-0.7,0.2-1.2,0.6-1.5,1.2c-0.2,0.5-0.3,1.1-0.1,1.9
c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.2,0-0.2-0.1c0,0,0,0,0,0c-0.2-0.5-0.3-1-0.3-1.5c0-0.5,0.1-1,0.3-1.4c0.2-0.4,0.6-0.8,1.1-1.2
c0.5-0.3,1.3-0.6,2.2-0.8c0.8-0.2,1.8-0.2,2.8-0.1c1,0.1,2.1,0.2,3.3,0.5c1.1,0.2,2.3,0.5,3.6,0.9l0.1,0c-0.3-0.2-0.6-0.3-0.9-0.4
c-0.6-0.3-1.2-0.6-1.9-0.8c-1.2-0.4-2.6-0.7-3.9-0.9c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0.1-0.1,0.2-0.1c1.1,0,2.3,0.2,3.4,0.5
c1.1,0.3,2,0.6,2.9,1c0.9,0.4,1.7,0.8,2.4,1.1c0.7,0.4,1.2,0.7,1.6,0.9l0.1,0.1c0.1-0.6,0.2-1.2,0.3-1.8c0.2-2.4,0.6-4.8,1-7.3
c0.2-1.1,0.5-2.2,0.7-3.5c0.6-2.7,1.4-5.4,2.4-8.1c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0.1,0.1,0.2c-0.7,2.4-1.3,4.9-1.8,7.4
c1.3-4.4,2.9-8.8,5.1-12.7c0.7,0.1,1.4,0.1,2.2,0.1c0.6,0,1.4-0.1,2.2-0.2c0.2,0,0.4-0.1,0.6-0.1c0.5-0.3,1-0.5,1.5-0.6
c0.5-0.2,1.1-0.3,1.7-0.3c1.1,0,2.2,0.3,3.2,0.9c0.5,0.3,0.9,0.7,1.3,1.2c0.4,0.6,0.6,1.1,0.6,1.7c0,0.6,0,1.1-0.2,1.7
c-0.2,0.6-0.4,1.1-0.7,1.6c-0.6,1-1.3,1.8-2.1,2.6c-0.8,0.8-1.8,1.4-2.8,1.8c-0.4,0.5-1,0.8-1.6,0.8c-0.4,0.1-0.8,0-1.1-0.1
c-0.4-0.1-0.7-0.2-1-0.4c-0.1,0-0.2-0.1-0.1-0.3c0-0.1,0.1-0.2,0.2-0.2c0.4,0,0.8-0.1,1.1-0.2c0.3-0.1,0.7-0.2,1-0.2
c0.3,0,0.7,0,1.1-0.1c0.4,0,0.7-0.1,1.1-0.3c0.9-0.5,1.7-1.1,2.4-1.9c0.7-0.8,1.3-1.6,1.7-2.5c0.2-0.5,0.4-0.9,0.5-1.4
c0.1-0.5,0.1-1-0.1-1.5c-0.1-0.5-0.3-0.9-0.7-1.3c-0.3-0.4-0.7-0.7-1.2-0.8c-0.5-0.2-0.9-0.4-1.4-0.5c-0.5-0.1-1-0.1-1.5-0.1
c-0.5,0-1,0.2-1.5,0.3c-0.2,0.1-0.3,0.1-0.5,0.2c-0.4,0.3-0.8,0.7-1.3,1.3c-0.8,1-1.6,2.2-2.4,3.6c-0.8,1.4-1.6,3-2.3,4.8
c-0.8,1.8-1.5,3.7-2.1,5.6c-0.6,2-1.2,3.9-1.7,5.9c-0.5,2-0.9,3.9-1.2,5.8l-0.1,1.6l0.3,0c2,0,4.2,0.2,6.7,0.4
c5,0.5,9.2,0.7,12.8,0.5c3.2-0.2,7-0.4,10.5-1.2c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2c-4.1,1.3-8.6,2.1-12.6,2.5
c1.7,0,3.5,0,5.2-0.2c0.1,0,0.2,0.1,0.2,0.1c0,0.1-0.1,0.2-0.1,0.2c-1.4,0.2-2.8,0.3-4.1,0.3c-1.9,0.1-3.8,0.1-5.7,0
c-1.9-0.1-3.7-0.2-5.4-0.3c-1.6-0.1-3.1-0.2-4.3-0.4c-1.2-0.1-2.1-0.2-2.5-0.3c-0.4,0-0.8,0-1.2-0.1l-0.1,1.5
c0.4,0,0.9,0.1,1.3,0.1c1.4,0.1,2.9,0.3,4.6,0.6c2,0.4,3.8,0.6,5.7,0.7c0.7,0,3.8,0,6.6-0.2c0.1,0,0.2,0.1,0.2,0.1
c0,0.1-0.1,0.2-0.1,0.2c-2.7,0.3-5.7,0.6-6.4,0.7c-1.2,0.1-2.4,0.1-3.5,0c-1.2,0-2.2-0.2-3.1-0.4c-1.5-0.3-2.8-0.5-4-0.7
c-0.4,0-0.7-0.1-1.1-0.1L145.1,55.7z"
          />
          <path
            className={classes2.logoText}
            d="M150.4,15.4c7.5,0,14.3,3,19.2,7.9c4.9,4.9,7.9,11.7,7.9,19.2c0,2.1-0.2,4.2-0.7,6.2c0,0.1-0.1,0.1-0.1,0.1
c-0.5,0.1-1,0.1-1.5,0.2c0,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1c0.5-2,0.8-4.2,0.8-6.4c0-7-2.8-13.4-7.4-18c-4.6-4.6-11-7.4-18-7.4
c-7,0-13.4,2.8-18,7.4c-4.6,4.6-7.4,11-7.4,18c0,0.7,0,1.3,0.1,2c0,0,0,0.1,0,0.1c-0.4,0.7-0.8,1.4-1.1,2.4c0,0.1-0.1,0.1-0.1,0.1
s-0.1-0.1-0.1-0.1c-0.2-1.4-0.4-2.9-0.4-4.4c0-7.5,3-14.3,7.9-19.2C136.2,18.4,143,15.4,150.4,15.4L150.4,15.4z M175.3,53.4
c-1.4,3.1-3.3,5.9-5.7,8.3c-4.9,4.9-11.7,7.9-19.2,7.9c-6.4,0-12.2-2.2-16.8-5.9c0,0-0.1-0.1,0-0.2c0-0.1,0.1-0.1,0.1-0.1
c0.8,0,1.5,0,2.3,0c0,0,0.1,0,0.1,0c4.1,2.8,9,4.4,14.4,4.4c7,0,13.4-2.8,18-7.4c2-2,3.6-4.3,4.9-6.8c0,0,0.1-0.1,0.1-0.1
c0.6-0.1,1.2-0.3,1.8-0.4c0.1,0,0.1,0,0.1,0C175.3,53.3,175.3,53.3,175.3,53.4z"
          />
          <path
            className={classes2.logoText}
            d="M173,43.4C173,43.4,173,43.4,173,43.4c0-0.1,0-0.1-0.1-0.1c0,0-0.1,0-0.1,0.1c-0.7,1.4-3.3,3.9-4.9,3.8
c-2.7-0.2,0-6.8,0.9-8.4c0.5-0.5,1-1.6,1.5-3c0.1-0.4,0.7-3.1,0-3.1c-0.4,0-0.9,1.4-1.2,2.3c-0.6,1.6-0.9,2.8-1.4,4
c-2.1,1.2-4.7,1.5-6.6-0.1c0.5-1.2,1.3-3.7,1-5c-0.2-1.2-1.5-2-2.6-2.1c-0.8-0.1-1.1,0.1-1.8,0.6c-0.2,0.1-0.3,0.2-0.3,0.3
c0,0.1,0,0.1,0.1,0.1c0.1,0,0.2,0,0.2-0.1c0.4-0.5,0.9-0.7,1.8-0.6c2.7,0.2-0.6,7.3-1,8.4c-0.6,1.7-1.4,3.7-2.2,5.4
c-0.2,0.5,0.9,0.6,1.2,0.6c0.7,0.1,1.1-0.7,1.1-1.2c0.7-1.8,1.3-3.8,2.1-5.6c3.3,1.6,3,1.1,6.8-0.2c-0.7,2.2-3.6,7.9,0,8.2
C169.8,48,172,45.1,173,43.4z"
          />
        </g>
      </g>
    </LogoRoot>
  );
};

Logo.propTypes = {
  fill: PropTypes.string,
  home: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  homeNav: PropTypes.bool,
};

export default Logo;
