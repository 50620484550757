import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Divider,
  Drawer,
} from '@mui/material';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import { useTranslation } from 'react-i18next';
import { switchModal } from '../../app/slices/property';
import useAuth from '../../hooks/useAuth';
import { useDispatch } from '../../app/store';
import { toast } from 'react-hot-toast';

const DashboardSidebar = (props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'navigation' });
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const handleModalOpen = () => {
    if (!user) {
      dispatch(switchModal('LoginModal', true));
    } else {
      toast.error('Már be vagy jelentkezve.');
    }
  };

  const sections = [
    {
      title: 'LidoHome',
      items: [
        {
          title: t('properties'),
          path: '/properties',
        },
        {
          title: t('projects.projects'),
          children: [
            {
              title: t('projects.investment'),
              path: '/projects/investment'
            },
            {
              title: t('projects.allProjects'),
              path: '/projects/allProjects'
            },
            {
              title: t('projects.references'),
              path: '/projects/references'
            }
          ]
        },
        {
          title: t('loan'),
          path: '/loan',
        },
        {
          title: t('offices'),
          path: '/offices',
        },
        {
          title: t('career'),
          path: '/career',
        },
        {
          title: 'Blog',
          path: '/blog',
        },
        {
          title: t('other.industry'),
          children: [
            {
              title: t('other.invest'),
              path: '/other/invest'
            },
            {
              title: t('other.partner'),
              path: '/other/partner'
            },
            {
              title: t('other.resort'),
              path: '/other/resort',
              disabled: true
            },
            {
              title: t('other.property'),
              path: '/other/property',
              disabled: true
            },
          ]
        },
        {
          title: t('aboutUs'),
          path: '/aboutUs',
        },
      ]
    },
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 50,
                width: 150
              }}
            />
          </RouterLink>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              disabled={section.disabled}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
          <Box
            pl={1}
          >
            {user ? (
              <Button // TODO: go to profile
                component={RouterLink}
                to="/profile"
              >
                {`${user.name} `}
                {t('profile')}
              </Button>
            ) : (
              <Button
                onClick={handleModalOpen}
              >
                {t('signIn')}
              </Button>
            )}
          </Box>
        </Box>
        <Divider />
      </Scrollbar>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
