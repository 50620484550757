import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  Collapse,
  FormControlLabel,
  Checkbox,
  Box,
  FormHelperText,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import AOCTextfield from '../utils/AOCTextfield';
import { useDispatch } from '../app/store';
import { register, switchModal } from '../app/slices/property';
import AOCPasswordTextfield from '../utils/AOCPasswordTextfield';
import useAuth from '../hooks/useAuth';
import useMounted from '../hooks/useMounted';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import AOCPhoneInput from '../utils/AOCPhoneInput';

const initalValues = {
  name: '',
  phone: '',
  email: '',
  password: '',
  confirmPassword: '',
  termsConditionsAccepted: true, // NOTE: There is now terms and condition field for now
  dataManagementAccepted: false,
  dataTransferAccepted: true, // NOTE: There is now terms and condition field for now
};

const LoginRegistrationForm = ({ type }) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { login } = useAuth();

  const dispatch = useDispatch();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerifyRegistration = useCallback(async (values) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const newToken = await executeRecaptcha('yourAction');
    // Do whatever you want with the token
    const data = {
      ...values,
      googleVerificationToken: newToken,
    };
    dispatch(register(data));
  }, [executeRecaptcha]);

  const handleReCaptchaVerifyLogin = useCallback(async (values) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    // Do whatever you want with the token
    await login(values.email, values.password);
    dispatch(switchModal('LoginModal', false));
  }, [executeRecaptcha]);

  const forgotPasswordRequest = () => {
    dispatch(switchModal('getNewPasswordModal', true));
  };

  return (
    <Grid
      mb={1}
      px={2}
    >
      <Formik
        initialValues={initalValues}
        validationSchema={Yup
          .object()
          .shape({
            name: Yup.string().when('email', {
              is: () => type === 'registration',
              then: () => Yup.string().required('Mező kitöltése kötelező')
            }),
            phone: Yup.string().when('email', {
              is: () => type === 'registration',
              then: () => Yup.string().required('Mező kitöltése kötelező').min(8, 'A telefonszám formátuma nem megfelelő')
            }),
            email: Yup.string().required('Mező kitöltése kötelező').email('Az e-mail cím formátuma nem megfelelő'),
            password: Yup.string().required('Jelszó megadása kötelező').min(8, 'A jelszónak minimum 8 karakter hosszúnak kell lennie'),
            confirmPassword: Yup.string()
              .when('password', {
                is: () => type === 'registration',
                then: () => Yup.string()
                  .required('jelszó megerősítése kötelező!')
                  .oneOf([Yup.ref('password'), null], 'A jelszavak nem egyeznek')
              }),
            // dataManagementAccepted: Yup.boolean().oneOf(
            //   [true],
            //   "A mező elfogadása kötelező"
            // )
            dataManagementAccepted: Yup.boolean().when('email', {
              is: () => type === 'registration',
              then: () => Yup.boolean().oneOf(
                [true],
                'A mező elfogadása kötelező'
              )
            }),
          })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            if (type === 'registration') {
              handleReCaptchaVerifyRegistration(values);
            } else if (type === 'login') {
              handleReCaptchaVerifyLogin(values);
            }
            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          setFieldValue,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              justifyContent="space-between"
            >
              <Grid
                item
                md={12}
                xs={12}
                p={1}
              >
                <Collapse in={type === 'registration'}>
                  <AOCTextfield
                    onBlur={handleBlur}
                    onChange={handleChange}
                    values={values}
                    touched={touched}
                    errors={errors}
                    name="name"
                    label={t('partnerForm.3')}
                  />
                </Collapse>
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                p={1}
              >
                <Collapse in={type === 'registration'}>
                  <AOCPhoneInput
                    values={values}
                    touched={touched}
                    errors={errors}
                    name="phone"
                    setFieldValue={setFieldValue}
                  />
                </Collapse>
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                p={1}
              >
                <AOCTextfield
                  onBlur={handleBlur}
                  onChange={handleChange}
                  values={values}
                  touched={touched}
                  errors={errors}
                  name="email"
                  label={t('partnerForm.7')}
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                p={1}
              >
                <AOCPasswordTextfield
                  onBlur={handleBlur}
                  onChange={handleChange}
                  values={values}
                  touched={touched}
                  errors={errors}
                  name="password"
                  label="Jelszó"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                p={1}
              >
                <Collapse in={type === 'registration'}>
                  <AOCPasswordTextfield
                    onBlur={handleBlur}
                    onChange={handleChange}
                    values={values}
                    touched={touched}
                    errors={errors}
                    name="confirmPassword"
                    label={t('confirmPassword')}
                  />
                </Collapse>
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                p={1}
                display="flex"
                flexDirection="column"
              >
                <Collapse in={type === 'registration'}>
                  {/* <FormControlLabel
                    label={t('footer.checkTermsAndConditions')}
                    sx={{ color: 'black' }}
                    control={(
                      <Checkbox
                        color="primary"
                        name="termsConditionsAccepted"
                        onChange={handleChange}
                        sx={{ color: 'black' }}
                      />
                    )}
                  /> */}
                  <FormControlLabel
                    label={t('footer.checkDataManagement')}
                    sx={{ color: 'black' }}
                    control={(
                      <Checkbox
                        color="primary"
                        name="dataManagementAccepted"
                        onChange={handleChange}
                        sx={{ color: 'black' }}
                      />
                    )}
                  />
                  {errors.dataManagementAccepted &&
                    touched.dataManagementAccepted && (
                      <Box sx={{ mt: 3 }}>
                        <FormHelperText error>
                          {errors.dataManagementAccepted}
                        </FormHelperText>
                      </Box>
                    )}
                  {/* <FormControlLabel
                    label={t('footer.checkDataTransfer')}
                    sx={{ color: 'black' }}
                    control={(
                      <Checkbox
                        color="primary"
                        name="dataTransferAccepted"
                        onChange={handleChange}
                        sx={{ color: 'black' }}
                      />
                    )}
                  /> */}
                </Collapse>
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {type === 'registration' ? t('registration') : t('partnerForm.11')}
                </Button>
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                mt={1}
                display={type === 'login' ? 'flex' : 'none'}
                justifyContent="flex-end"
              >
                <Button
                  onClick={forgotPasswordRequest}
                >
                  {t('forgotPassword')}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>

    </Grid>
  );
};

LoginRegistrationForm.propTypes = {
  type: PropTypes.string,
};

export default LoginRegistrationForm;
