/* eslint-disable no-unused-vars */
import React from "react";
import {
  Box,
  Button,
  CardMedia,
  Grid,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Container } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import DashboardNavItem from "../../components/dashboard/DashboardNavItem";
import Logo from "../../components/Logo";
// eslint-disable-next-line no-unused-vars
import homePagepic from "../../images/homepagepic.png";
import video from "../../images/video.mp4";
import LanguagePopover from "../../components/dashboard/LanguagePopover";
import MainSearch from "../../components/MainSearch";
import { switchModal } from "../../app/slices/property";
import { useDispatch } from "../../app/store";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-hot-toast";

const HomeNavigation = () => {
  const { t } = useTranslation("translation", { keyPrefix: "navigation" });
  // const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const dispatch = useDispatch();
  const { user } = useAuth();
  const handleModalOpen = () => {
    if (!user) {
      dispatch(switchModal("LoginModal", true));
    } else {
      toast.error("Már be vagy jelentkezve.");
    }
  };
  const leftNavigationData = [
    {
      title: t("properties"),
      path: "/properties",
    },
    {
      title: t("projects.projects"),
      children: [
        {
          title: t("projects.investment"),
          path: "/projects/investment",
        },
        {
          title: t("projects.allProjects"),
          path: "/projects/allProjects",
        },
        {
          title: t("projects.references"),
          path: "/projects/references",
        },
      ],
    },
    {
      title: t("loan"),
      path: "/loan",
    },
  ];

  const rightNavigationData = [
    {
      title: t("offices"),
      path: "/offices",
    },
    {
      title: t("career"),
      path: "/career",
    },
    {
      title: "Blog",
      path: "/blog",
    },
    {
      title: t("other.industry"),
      children: [
        {
          title: t("other.invest"),
          path: "/other/invest",
        },
        {
          title: t("other.partner"),
          path: "/other/partner",
        },
        {
          title: t("other.resort"),
          path: "/other/resort",
          disabled: true,
        },
        {
          title: t("other.property"),
          path: "/other/property",
          disabled: true,
        },
      ],
    },
    {
      title: t('aboutUs'),
      path: '/aboutUs',
    },
  ];

  return (
    <>
      <Grid height={mdDown ? 1100 : 724} position="relative">
        <Box
          position="relative"
          display="flex"
          justifyContent="center"
          height="100%"
          alignItems="center"
          width="100%"
          zIndex={1}
        >
          <Typography
            sx={{
              position: 'absolute',
              bottom: !lgDown && 75,
              top: lgDown && 75
            }}
            textAlign="center"
            color="primary"
            variant="h2"
            zIndex={1}
            fontWeight={500}
          >
            Ingatlanok egy életen át!
          </Typography>
          <video
            src={video}
            autoPlay
            loop
            muted
            disablePictureInPicture
            style={{
              position: "absolute",
              width: "100%",
              objectFit: "cover",
              height: mdDown ? 1100 : 724,
            }}
          >
            <track kind="captions" />
          </video>
        </Box>
        {/* <CardMedia */}
        {/*   image={homePagepic} */}
        {/*   sx={{ */}
        {/*     position: 'relative', */}
        {/*     height: mdDown ? 1100 : 724, */}
        {/*     // borderRadius: '10px', */}
        {/*     // height: '540px', */}
        {/*     // width: '100%', */}
        {/*     // top: 100, */}
        {/*   }} */}
        {/* /> */}
      </Grid>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            bottom: 20,
            width: "90%",
            left: "5%",
            borderRadius: "10px",
            // backgroundImage: 'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(150,47,50,0.5) 50%, rgba(255,255,255,0) 100%)'
          }}
          backgroundColor="rgba(0, 0, 0, 0.3)"
        >
          <Typography
            textAlign="center"
            color="white"
            variant="h1"
            zIndex={1}
            // fontFamily="Antic Didone"
            textTransform="uppercase"
            sx={{
              // fontFamily: 'Antic Didone',
              fontFamily: "Tenor Sans",
              // textShadow: '1px 2px 10px #000',
            }}
          >
            {/* {t('propertiesForLife')} */}
          </Typography>
        </Box>
      </Box>
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "absolute",
          top: 0,
          py: "10px",
          boxShadow:
            !lgDown &&
            "0 0 1px 0 rgba(0,0,0,0.31),0 3px 4px -2px rgba(0,0,0,0.25)",
          left: 0,
          zIndex: 1,
          bgcolor: !lgDown && "white",
        }}
      >
        {leftNavigationData.map((item) => (
          <Box
            key={item.title}
            sx={{
              px: 1,
              display: {
                lg: "flex",
                xs: "none",
              },
            }}
          >
            <DashboardNavItem
              navigationData={item}
              home
            />
          </Box>
        ))}
        {rightNavigationData.map((item) => (
          <Box
            key={item.title}
            sx={{
              px: 1,
              display: {
                lg: "flex",
                xs: "none",
              },
            }}
          >
            <DashboardNavItem navigationData={item} home />
          </Box>
        ))}
        {/* <Grid
          item
          display="flex"
          justifyContent="flex-end"
        > */}
        {/* <Button
          sx={{
            opacity: 0.9,
            ml: '8px',
            // pr: '8px',
            py: '12px',
            textTransform: 'uppercase',
            display: {
              lg: 'flex',
              xs: 'none'
            }
          }}
          // size="small"
          variant="outlined"
        >
          {t('signIn')}
        </Button> */}
        {/* </Grid> */}
        {/* <Box sx={{ ml: 1 }}>
          <LanguagePopover
            component={MenuItem}
          // label
          />
        </Box> */}
      </Grid>
      <Box
        sx={{
          position: "absolute",
          top: 12,
          right: 10,
          zIndex: 1,
        }}
      >
        {user ? (
          <Button // TODO: go to profile
            component={RouterLink}
            to="/profile"
            sx={{
              opacity: 0.9,
              ml: "8px",
              // pr: '8px',
              py: "12px",
              textTransform: "uppercase",
              display: {
                lg: "flex",
                xs: "none",
              },
            }}
            // size="small"
            variant="contained"
          >
            {`${user.name} `}
            {t("profile")}
          </Button>
        ) : (
          <Button
            onClick={handleModalOpen}
            sx={{
              opacity: 0.9,
              ml: "8px",
              // pr: '8px',
              py: "12px",
              textTransform: "uppercase",
              display: {
                lg: "flex",
                xs: "none",
              },
            }}
            // size="small"
            variant="contained"
          >
            {t("signIn")}
          </Button>
        )}
      </Box>
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "absolute",
          // top: smDown ? 50 : 90,
          top: { xs: 30, sm: 90, md: 10, lg: 90 },
          left: 0,
        }}
      >
        <Container>
          <RouterLink to="/">
            <Grid container justifyContent="center">
              <Logo
                visibility={lgDown ? "hidden" : "visible"}
                homeNav
                sx={{
                  mx: "2.8vw",
                  height: 245,
                  // width: smDown ? 250 : 450
                  width: { xs: 250, sm: 350, md: 350, lg: 450 },
                }}
              />
            </Grid>
          </RouterLink>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            sx={{
              px: lgDown ? 0 : 5,
              // mt: smDown ? 1 : 10,
              mt: { xs: 0, sm: 5, md: -5, lg: 10 },
            }}
          >
            <MainSearch />
          </Grid>
          {/* <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            sx={{ mt: 5 }}
          >
            <Typography
              textAlign="center"
              color="primary"
              variant="h2"
              zIndex={1}
              fontWeight={500}
            >
              Ingatlanok egy életen át!
            </Typography>
          </Grid> */}
        </Container>
      </Grid>
    </>
  );
};

export default HomeNavigation;
