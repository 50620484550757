import { CardMedia, Typography } from '@mui/material';
import useInnerHTML from '../hooks/useInnerHTML';
import PropTypes from 'prop-types';

const { REACT_APP_FILE_URL } = process.env;

const BlogSection = ({ section }) => {
  const refContent = useInnerHTML(section.content);

  return (
    <>
      <Typography
        ref={refContent}
        variant="body1"
        color="textPrimary"
        sx={{ wordWrap: 'break-word' }}
      />

      {section.image?.source?.large && (
        <CardMedia
          component="img"
          src={`${REACT_APP_FILE_URL}/private${section.image.source.large}`}
          alt={section.image.title}
          sx={{
            borderRadius: 1,
            my: 4,
          }}
        />
      )}

      {section.video?.source && (
        <video
          controls
          width="100%"
          height="100%"
          style={{
            borderRadius: '8px'
          }}
          src={`${REACT_APP_FILE_URL}${section.video?.source}`}
        >
          <track kind="captions" />
        </video>
      )}

    </>
  );
};

export default BlogSection;

BlogSection.propTypes = {
  section: PropTypes.object.isRequired
};
