import { Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const StyledRegularText = ({ children, style, variant, mt }) => (
  <Typography
    mt={mt}
    variant={variant || 'h7'}
    style={style}
  >
    {children}
  </Typography>
);

export default StyledRegularText;

StyledRegularText.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  variant: PropTypes.string,
  mt: PropTypes.number
};
