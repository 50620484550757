import { forwardRef } from "react";
import { TextField } from "@mui/material";

const PhoneNumberStyle = (props, ref) => (
  <TextField
    {...props}
    inputRef={ref}
    fullWidth
    label="Telefonszám" // TODO: If secondary phone it should be Masodlagos Telefonszam
    variant="outlined"
    name="phone"
  />
);

export default forwardRef(PhoneNumberStyle);
