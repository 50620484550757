import React, { useCallback } from 'react';
import {
  Button,
  Typography,
  Dialog,
  Box,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import AOCTextfield from '../utils/AOCTextfield';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from '../app/store';
import { requestPasswordReset, switchModal } from '../app/slices/property';

const initalValues = {
  email: '',
};

const ForgottPasswordModal = () => {
  const { openModals } = useSelector((state) => state.property);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleModalClose = () => {
    dispatch(switchModal('getNewPasswordModal', false));
  };

  const forgotPasswordRequest = useCallback(async (values) => {
    if (!executeRecaptcha) return;

    const newToken = await executeRecaptcha('yourAction');
    const data = {
      googleVerificationToken: newToken,
      email: values.email,
    };
    dispatch(requestPasswordReset(data));
  }, [executeRecaptcha]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={handleModalClose}
      open={openModals.includes('getNewPasswordModal')}
    >
      <Formik
        initialValues={initalValues}
        validationSchema={Yup
          .object()
          .shape({
            email: Yup.string().required('Mező kitöltése kötelező').email('Az e-mail cím formátuma nem megfelelő'),
          })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            forgotPasswordRequest(values);
          } catch (err) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              py={3}
              px={3}
              display="flex"
              flexDirection="column"
            >
              <Typography
                sx={{
                  mb: 2,
                }}
              >
                {t('getNewPassword')}
              </Typography>
              <AOCTextfield
                onBlur={handleBlur}
                onChange={handleChange}
                values={values}
                touched={touched}
                errors={errors}
                name="email"
                label={t('Email')}
              />
              <Box
                display="flex"
                justifyContent="flex-end"
                mt={2}
              >
                <Button
                  variant="contained"
                  type="submit"
                >
                  {t('send')}
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ForgottPasswordModal;
