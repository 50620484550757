import React, { Fragment } from 'react';
import {
  Box,
  Grid,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import Label from './Label';
import PropTypes from 'prop-types';
import { format, toDate } from 'date-fns';
import huLocale from 'date-fns/locale/hu';
import NumberFormatCustom from '../utils/NumberFormatCustom';

const searchNameDict = {
  city: 'Város:',
  locations: 'Település:',
  floorAreaFrom: 'Alapterület (-től):',
  floorAreaTo: 'Alapterület (-ig):',
  floorLevelFrom: 'Emelet (-től):',
  floorLevelTo: 'Emelet (-ig):',
  landAreaFrom: 'Telekterület (-től):',
  landAreaTo: 'Telekterület (-ig):',
  northCoast: 'Északi part:',
  southCoast: 'Déli part:',
  roomCountFrom: 'Szobák(-tól):',
  roomCountTo: 'Szobák(-ig):',
  salePriceFrom: 'Ár(-tól):',
  salePriceTo: 'Ár(-ig):',
  waterDistanceFrom: 'Vízparttól való távolság (-tól):',
  waterDistanceTo: 'Vízparttól való távolság (-ig):',
  accessibility: 'Akadálymentesítés:',
  cooling: 'Klíma:',
  electricity: 'Villany:',
  energyCertificate: 'Energetikai tanúsítvány:',
  externalCondition: 'Külső állapot:',
  furnishing: 'Bútorozottság:',
  gas: 'Gáz:',
  heating: 'Fűtés:',
  internalCondition: 'Belső állapot:',
  internet: 'Internet:',
  parking: 'Parkolás:',
  phone: 'Telefon:',
  privateTags: 'Privát tulajdonságok:',
  propertyType: 'Ingatlan típusa:',
  publicTags: 'Nyilvános címkék:',
  polygon: 'Poligon',
  circleCenter: 'Kör',
  circleRadius: 'Kör sugara méterben',
  sewer: 'Csatorna:',
  transactionType: 'Tranzakció típusa:', // TODO: u will get different values then before
  tv: 'TV:',
  view: 'Kilátás:',
  water: 'Víz:',
  created: 'Létrehozva',
  modified: 'Módosítva',
  constructionType: 'Építési mód',
  residential: 'Ingatlan típus',
  comfortLevel: 'Komfort'
};

const labelColorsMap = {
  1: 'success',
  0: 'warning'
};

const CustomerSearchesRow = (props) => {
  const { search, handleModalOpen } = props;
  return (
    <TableRow
      key={search?.id}
      hover
      onClick={(_) => {
        if (handleModalOpen) {
          handleModalOpen(_, search);
        }
      }}
      sx={{
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }}
    >
      <TableCell>
        <Grid container>
          <Grid
            item
            sx={{ p: 1 }}
          >
            {search?.status === 1
              ? (
                <Label color={labelColorsMap[search.status]}>
                  aktív keresés
                </Label>
              )
              : (
                <Label color={labelColorsMap[search.status || 0]}>
                  inaktív keresés
                </Label>
              )}
          </Grid>
          <Box sx={{ flexGrow: 1 }} />
          <Grid
            item
            sx={{ p: 1 }}
            display="flex"
          >
            <Typography
              sx={{ mr: 1 }}
              color="textSecondary"
              variant="body2"
            >
              Értékesítő:
            </Typography>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              {search?.user?.name || 'Nincs megadva'}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          display="flex"
        >
          {Object.entries(search).map(([keyName, keyValue]) => (
            <React.Fragment key={keyName}>
              {keyName !== 'id' && keyName !== 'status' && keyName !== 'clientId' && keyName !== 'transactionTypeId' && keyName !== 'externalKey' && (typeof search[keyName] !== 'object' || keyName === 'attributeOptions' || keyName === 'polygon' || keyName === 'circleCenter' || keyName === 'locations')
                && (
                  <>
                    {keyValue && (
                      <>
                        {keyName === 'attributeOptions' ? (
                          <>
                            {Object.entries(keyValue).map(([name, value]) => (
                              <Grid
                                item
                                key={name}
                                sx={{
                                  p: 1,
                                  m: 1,
                                  border: (theme) => `1px solid ${theme.palette.divider}`,
                                  borderRadius: '10px'
                                }}
                              >
                                <Typography
                                  color="textSecondary"
                                  variant="body2"
                                >
                                  {searchNameDict[name]}
                                </Typography>
                                <Typography
                                  color="textPrimary"
                                  variant="subtitle2"
                                >
                                  {value.map((attribute, i, { length }) => (
                                    <React.Fragment key={attribute.id}>
                                      {i + 1 === length ? (
                                        attribute.name
                                      ) : (
                                        `${attribute.name}, `
                                      )}
                                    </React.Fragment>
                                  ))}
                                </Typography>
                              </Grid>
                            ))}
                          </>
                        ) : (
                          <>
                            {keyName === 'polygon' || keyName === 'circleCenter' ? (
                              <Grid
                                item
                                sx={{
                                  p: 1,
                                  m: 1,
                                  border: (theme) => `1px solid ${theme.palette.divider}`,
                                  borderRadius: '10px'
                                }}
                              >
                                <Typography
                                  color="textSecondary"
                                  variant="body2"
                                >
                                  Forma:
                                </Typography>
                                <Typography
                                  color="textPrimary"
                                  variant="subtitle2"
                                >
                                  {searchNameDict[keyName]}
                                </Typography>
                              </Grid>
                            ) : (
                              <>
                                {keyName === 'locations' ? (
                                  <>
                                    {keyValue.length > 0 && (
                                      <Grid
                                        item
                                        sx={{
                                          p: 1,
                                          m: 1,
                                          border: (theme) => `1px solid ${theme.palette.divider}`,
                                          borderRadius: '10px'
                                        }}
                                      >
                                        <Typography
                                          color="textSecondary"
                                          variant="body2"
                                        >
                                          {searchNameDict[keyName]}
                                        </Typography>
                                        <Typography
                                          color="textPrimary"
                                          variant="subtitle2"
                                        >
                                          {keyValue.map((value, index) => (
                                            <Fragment key={value.id}>
                                              {`${value.name}${index === keyValue.length - 1 ? '' : ', '}`}
                                            </Fragment>
                                          ))}
                                        </Typography>
                                      </Grid>
                                    )}
                                  </>
                                ) : (
                                  <Grid
                                    item
                                    sx={{
                                      p: 1,
                                      m: 1,
                                      border: (theme) => `1px solid ${theme.palette.divider}`,
                                      borderRadius: '10px'
                                    }}
                                  >
                                    <Typography
                                      color="textSecondary"
                                      variant="body2"
                                    >
                                      {searchNameDict[keyName]}
                                    </Typography>
                                    <Typography
                                      color="textPrimary"
                                      variant="subtitle2"
                                    >
                                      {keyName === 'northCoast' || keyName === 'southCoast' ? (
                                        <>
                                          Igen
                                        </>
                                      ) : (
                                        <>
                                          {keyName === 'created' || keyName === 'modified' ? (
                                            <>
                                              {format(toDate(keyValue), 'yyyy.MM.dd HH:mm', { locale: huLocale })}
                                            </>
                                          ) : (
                                            <>
                                              {keyName === 'salePriceFrom' || keyName === 'salePriceTo' ? (
                                                <>
                                                  {keyValue ? (
                                                    <NumberFormatCustom
                                                      value={keyValue}
                                                      displayType="text"
                                                      suffix=" Ft"
                                                    />
                                                  ) : (
                                                    'Nincs megadva'
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  {keyValue}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </Typography>
                                  </Grid>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
            </React.Fragment>
          ))}
        </Grid>
      </TableCell>
    </TableRow>
  );
};
CustomerSearchesRow.propTypes = {
  search: PropTypes.any,
  handleModalOpen: PropTypes.func,
};
export default CustomerSearchesRow;
