/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import axiosConfig from "../../axiosConfig";
// import { convertEmptyStringsToNull } from '../../utils/helperfunctions';
import { toggleLoading } from "./loading";

const initialState = {
  stateVariable: null,
  properties: {},
  projects: {},
  clientSearches: [],
  selectedProject: {},
  presentation: {},
  highlightedProperties: {},
  canBlur: false,
  searchData: {},
  attributes: null,
  selectedProperty: {},
  openModals: [],
  scrollLocations: [],
  generalArray: [],
  offices: {},
  selectedOffice: {},
  selectedOfficeEmployees: [],
  selectedEmployee: [],
  importantEmployees: [],
  simpOffices: {},
  filter: {},
  usingPropertySearchEndpoint: false,
  locationOptions: [],
  propertySearchData: {},
  karrierFormSubmit: false,
  initialShapesValue: null,
};

const slice = createSlice({
  name: "property",
  initialState,
  reducers: {
    getProperties(state, action) {
      state.properties = action.payload;
    },
    setSelectedProject(state, action) {
      state.selectedProject = action.payload;
    },
    getClientSearches(state, action) {
      let isInAlready = false;
      state.clientSearches.forEach((clientSearch) => {
        if (clientSearch.clientId === action.payload.clientId) {
          isInAlready = true;
        }
      });
      if (!isInAlready) {
        state.clientSearches.push(action.payload);
      }
    },
    openModal(state, action) {
      state.openModals.push(action.payload);
    },
    handleBlur(state, action) {
      state.canBlur = action.payload;
    },
    getPresentations(state, action) {
      state.presentation = action.payload;
    },
    closeModal(state, action) {
      state.openModals = state.openModals.filter(
        (modal) => modal !== action.payload
      );
    },
    openScrollLocation(state, action) {
      state.scrollLocations.push(action.payload);
    },
    pushIntoGeneralArray(state, action) {
      state.generalArray.push(action.payload);
    },
    erasefromGeneralArray(state, action) {
      state.generalArray = state.generalArray.filter(
        (item) => item !== action.payload
      );
    },
    resetGeneralArray(state) {
      state.generalArray = [];
    },
    closeScrollLocation(state, action) {
      state.scrollLocations = state.scrollLocations.filter(
        (modal) => modal !== action.payload
      );
    },
    getProjects(state, action) {
      state.projects = action.payload;
    },
    getHighlightedProperties(state, action) {
      state.highlightedProperties = action.payload;
    },
    getOffices(state, action) {
      state.offices = action.payload;
    },
    getEmployees(state, action) {
      state.selectedOfficeEmployees = action.payload;
    },
    getImportantEmployees(state, action) {
      state.importantEmployees = action.payload;
    },
    setSelectedEmployee(state, action) {
      state.selectedEmployee = action.payload;
    },
    selectOffice(state, action) {
      state.selectedOffice = action.payload;
    },
    getSimpOffices(state, action) {
      state.simpOffices = action.payload;
    },
    getAttributeOptions(state, action) {
      state.attributes = action.payload;
    },
    saveSearchData(state, action) {
      state.searchData = action.payload;
    },
    setSelectedProperty(state, action) {
      state.selectedProperty = action.payload;
    },
    setUsingPropertySearchEndpoint(state, action) {
      state.usingPropertySearchEndpoint = action.payload;
    },
    getLocationOptions(state, action) {
      state.locationOptions = action.payload;
    },
    clearProperties(state) {
      state.properties = {};
    },
    clearPropertiesData(state) {
      if (state.properties.data) {
        state.properties.data = null;
      }
    },
    clearOffice(state) {
      state.selectedOffice = {};
    },
    setPropertySearchData(state, action) {
      state.propertySearchData = action.payload;
    },
    setInitialShapesValue(state, action) {
      state.initialShapesValue = action.payload;
    },
    setKarrierFormSubmit(state, action) {
      state.karrierFormSubmit = action.payload;
    },
  },
});

export const { reducer } = slice;

export const switchModal = (modalName, open) => (dispatch) => {
  if (open) {
    dispatch(slice.actions.openModal(modalName));
  } else {
    dispatch(slice.actions.closeModal(modalName));
  }
};

export const handleBlur = (canBlur) => (dispatch) => {
  dispatch(slice.actions.handleBlur(canBlur));
};

export const getClientSearches = (clientId) => async (dispatch) => {
  dispatch(toggleLoading({ type: `GET_CLIENT_SEARCHES${clientId}` }));
  await axiosConfig
    .get(`/clients/${clientId}/searches`)
    .then((response) => {
      // handle success
      /* eslint-disable-next-line */
      const data = response.data;
      dispatch(slice.actions.getClientSearches({ data, clientId }));
    })
    .catch((error) => {
      // if (error?.response?.status === 500 && error?.response?.status === 403) {
      toast.error("Az ügyfél kereséseinek betöltése sikertelen");
      // }
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: `GET_CLIENT_SEARCHES${clientId}` }));
    });
};

export const operateGeneralArray = (generalMessage, open) => (dispatch) => {
  if (open === "push") {
    dispatch(slice.actions.pushIntoGeneralArray(generalMessage));
  } else if (open === "reset") {
    dispatch(slice.actions.resetGeneralArray());
  } else {
    dispatch(slice.actions.erasefromGeneralArray(generalMessage));
  }
};

export const switchScrollLocation = (scrollName, open) => (dispatch) => {
  if (open) {
    dispatch(slice.actions.openScrollLocation(scrollName));
  } else {
    dispatch(slice.actions.closeScrollLocation(scrollName));
  }
};

export const getAttributeOptions = (filter) => async (dispatch) => {
  dispatch(toggleLoading({ type: "GET_ATTRIBUTES" }));
  // dispatch(slice.actions.getAttributeOptions({}, null));
  await axiosConfig
    .get("/attributes")
    .then((response) => {
      dispatch(slice.actions.getAttributeOptions(response.data, filter));
    })
    .catch((error) => {
      // handle error
      // toast.error('A lista betöltése sikertelen (/attributes)');
      console.error(error);
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: "GET_ATTRIBUTES" }));
    });
};

export const getProjects = (pageNumber, pageSize) => async (dispatch) => {
  // dispatch(slice.actions.handleLoading(true));
  dispatch(toggleLoading({ type: "GET_PROJECTS" }));
  await axiosConfig
    .get(
      `projects?pageNumber=${pageNumber - 1 || 0}&pageSize=${pageSize || 24}`
    )
    .then((response) => {
      /* eslint-disable-next-line */
      const data = response.data;
      dispatch(slice.actions.getProjects(data));
    })
    .catch((error) => {
      // handle error
      toast.error("Hiba");
      console.error(error);
    })
    .then(() => {
      // dispatch(slice.actions.handleLoading(false));
      // always executed
      dispatch(toggleLoading({ type: "GET_PROJECTS" }));
    });
};

export const searchProject =
  (filter, pageNumber, pageSize) => async (dispatch) => {
    // TODO: sort
    dispatch(toggleLoading({ type: "GET_SEARCH_PROJECTS" }));
    await axiosConfig
      .post(
        `/projects/search?pageNumber=${pageNumber - 1 || 0}&pageSize=${pageSize || 24
        }`,
        filter
      )
      .then((response) => {
        dispatch(slice.actions.getProjects(response.data));
      })
      .catch((error) => {
        // handle error
        toast.error("Hiba az projektek keresésekor");
        console.error(error);
      })
      .then(() => {
        // always executed
        dispatch(toggleLoading({ type: "GET_SEARCH_PROJECTS" }));
      });
  };

export const setSelectedProject = (id) => async (dispatch) => {
  if (id === "zero") {
    dispatch(slice.actions.setSelectedProject({}));
    return;
  }
  dispatch(toggleLoading({ type: "SELECT_PROJECT" }));
  await axiosConfig
    .get(`projects/${id}`)
    .then((response) => {
      const { data } = response;
      dispatch(slice.actions.setSelectedProject(data));
    })
    .catch((error) => {
      // handle error
      toast.error("Hiba");
      console.error(error);
    })
    .then(() => {
      // always executed
    });
};

export const getOffices = () => async (dispatch) => {
  await axiosConfig
    .get("/offices")
    .then((response) => {
      // handle success
      dispatch(slice.actions.getOffices(response.data));
    })
    .catch((error) => {
      // handle error
      // toast.error('Az irodák betöltése sikertelen');
      console.error(error);
    })
    .then(() => {
      // always executed
    });
};

export const selectOffice = (officeId) => async (dispatch) => {
  dispatch(toggleLoading({ type: "SELECT_OFFICE" }));
  await axiosConfig
    .get(`/offices/${officeId}`)
    .then((response) => {
      // handle success
      // console.log(response.data);
      dispatch(slice.actions.selectOffice(response.data));
    })
    .catch((error) => {
      // handle error
      // toast.error('Hiba az iroda adatainak lekérdezésekor');
      console.error(error);
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: "SELECT_OFFICE" }));
    });
};

export const getEmployees =
  (page, officeId, pageSize, query) => async (dispatch) => {
    dispatch(toggleLoading({ type: "GET_EMPLOYEES" }));
    await axiosConfig
      .get(
        `/users?pageNumber=${page || 0}&filter=${query || ""}&officeId=${officeId || ""
        }&pageSize=${pageSize || 24}`
      )
      .then((response) => {
        // handle success
        dispatch(slice.actions.getEmployees(response.data.data));
      })
      .catch((error) => {
        // handle error
        // toast.error('A munkatársak betöltése sikertelen');
        console.error(error);
      })
      .then(() => {
        dispatch(toggleLoading({ type: "GET_EMPLOYEES" }));
        // always executed
      });
  };

export const getImportantEmployees =
  (page, officeId, query, pageSize) => async (dispatch) => {
    dispatch(toggleLoading({ type: "GET_EMPLOYEES" }));
    await axiosConfig
      .get(
        `/users?pageNumber=${page || 0}&filter=${query || ""}&officeId=${officeId || ""
        }&pageSize=${pageSize || 24}`
      )
      .then((response) => {
        // handle success
        dispatch(slice.actions.getImportantEmployees(response.data.data));
      })
      .catch((error) => {
        // handle error
        // toast.error('A munkatársak betöltése sikertelen');
        console.error(error);
      })
      .then(() => {
        dispatch(toggleLoading({ type: "GET_EMPLOYEES" }));
        // always executed
      });
  };

export const getEmployeeById = (id) => async (dispatch) => {
  await axiosConfig
    .get(`/users/${id}`)
    .then((response) => {
      // handle success
      dispatch(slice.actions.setSelectedEmployee(response.data));
    })
    .catch((error) => {
      // handle error
      // toast.error('Hiba a munkatárs adatainak lekérdezésekor');
      console.error(error);
    })
    .then(() => {
      // always executed
    });
  //   dispatch(toggleLoading({ type: 'GET_EMPLOYEES' }));
  //   await axiosConfig.get(
  //     `/users?pageNumber=${0}&filter=${id || ''}&officeId=${''}&pageSize=${1}`
  //   ).then((response) => {
  //     // handle success
  //     dispatch(slice.actions.setSelectedEmployee(response.data.data[0]));
  //   }).catch((error) => {
  //     // handle error
  //     // toast.error('A munkatársak betöltése sikertelen');
  //     console.error(error);
  //   }).then(() => {
  //     dispatch(toggleLoading({ type: 'GET_EMPLOYEES' }));
  //     // always executed
  //   });
};

export const getSimpOffices = () => async (dispatch) => {
  await axiosConfig
    .get("/offices/simple")
    .then((response) => {
      // handle success
      dispatch(slice.actions.getSimpOffices(response.data.data));
    })
    .catch((error) => {
      // handle error
      // toast.error('Az irodák betöltése sikertelen');
      console.error(error);
    })
    .then(() => {
      // always executed
    });
};

export const setSelectedProperty = (id) => async (dispatch) => {
  dispatch(toggleLoading({ type: "SELECT_PROPERTY" }));
  if (id === "zero") {
    const property = {};
    dispatch(slice.actions.setSelectedProperty(property));
  } else {
    await axiosConfig
      .get(`/properties/${id}`)
      .then((response) => {
        const property = response.data;
        dispatch(slice.actions.setSelectedProperty(property));
      })
      .catch((error) => {
        dispatch(slice.actions.setSelectedProperty({ id: -1000 }));
        console.error(error);
      });
    dispatch(toggleLoading({ type: "SELECT_PROPERTY" }));
  }
};

export const searchProperty =
  (data, pageNumber, pageSize, sort) => async (dispatch) => {
    // TODO: sort
    dispatch(toggleLoading({ type: "GET_SEARCH_PROPERTIES" }));
    dispatch(slice.actions.setUsingPropertySearchEndpoint(true));
    await axiosConfig
      .post(
        `/properties/search?pageNumber=${pageNumber || 0}&pageSize=${pageSize || 24
        }&sort=${sort || ""}`,
        data
      )
      .then((response) => {
        dispatch(slice.actions.getProperties(response.data));
      })
      .catch((error) => {
        // handle error
        toast.error("Hiba az ingatlanok keresésekor");
        console.error(error);
      })
      .then(() => {
        // always executed
        dispatch(toggleLoading({ type: "GET_SEARCH_PROPERTIES" }));
      });
  };

/* GET Axios request template */
// export const getOffices = (filters) => async (dispatch) => {
//   await axiosConfig.get(
//     '/offices'
//   ).then((response) => {
//     // handle success
//     dispatch(slice.actions.getOffices(response.data.data, filters));
//   }).catch((error) => {
//     // handle error
//     toast.error('Az irodák betöltése sikertelen');
//     console.error(error);
//   }).then(() => {
//     // always executed
//   });
// };
/* GET Axios request template */

const getFilterStatus = (filter) => {
  // TODO: here we always get the approved proeprties so it is deprecated
  switch (filter) {
    case "draft":
      return "/properties/status/1";
    case "unapproved":
      return "/properties/status/2";
    case "approved":
      return "/properties/status/3";
    case "markArchive":
      return "/properties/status/4";
    case "archived":
      return "/properties/status/5";
    default:
      return "/properties";
  }
};

export const search = (data) => (dispatch) => {
  dispatch(slice.actions.saveSearchData(data));
};

export const getProperties = (filter, controller) => async (dispatch) => {
  dispatch(toggleLoading({ type: "GET_PROPERTIES" }));
  await axiosConfig
    .get(getFilterStatus(filter), {
      signal: controller?.signal,
    })
    .then((response) => {
      dispatch(slice.actions.getProperties(response.data, filter));
    })
    .catch((error) => {
      // handle error
      if (!controller.signal.aborted) {
        // toast.error('Az ingatlanok betöltése sikertelen');
      }
      console.error(error);
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: "GET_PROPERTIES" }));
    });
};

export const getPropertiesPage =
  (filter, pageNumber, pageSize) => async (dispatch) => {
    // dispatch(slice.actions.getProperties([], null)); // TODO: Test it
    dispatch(toggleLoading({ type: "GET_PROPERTIES_PAGE" }));
    await axiosConfig
      .get(
        // `/properties?pageNumber=${page}`
        `${getFilterStatus(filter)}?pageNumber=${pageNumber - 1}&pageSize=${pageSize || 24
        }`
      )
      .then((response) => {
        // handle success
        if (filter === "unapproved") {
          const { data, ...rest } = response;
          const unapprovedProperties = data.data.filter(
            (property) => property.status === "0"
          );
          const filteredProperties = {
            data: unapprovedProperties,
            ...rest,
          };

          dispatch(slice.actions.getProperties(filteredProperties, filter));
        } else {
          dispatch(slice.actions.getProperties(response.data, filter));
        }
      })
      .catch((error) => {
        // handle error
        // toast.error('Az ingatlanok betöltése sikertelen');
        console.error(error);
      })
      .then(() => {
        // always executed
        dispatch(toggleLoading({ type: "GET_PROPERTIES_PAGE" }));
      });
  };

export const getHighlightedPropertiesPage =
  (filter, pageNumber, pageSize) => async (dispatch) => {
    dispatch(toggleLoading({ type: "GET_HIGHLIGHTED_PROPERTIES_PAGE" }));
    await axiosConfig
      .get(
        `/properties/featured?pageSize=${pageSize || 24}` // TODO: this one shoudl be something like /properties/highlighted?...
        // '/properties/featured'
      )
      .then((response) => {
        dispatch(slice.actions.getHighlightedProperties(response.data, filter));
      })
      .catch((error) => {
        // handle error
        // toast.error('Az ingatlanok betöltése sikertelen');
        console.error(error);
      })
      .then(() => {
        // always executed
        dispatch(toggleLoading({ type: "GET_HIGHLIGHTED_PROPERTIES_PAGE" }));
      });
  };

export const getLocationOptions = (data) => async (dispatch) => {
  dispatch(toggleLoading({ type: "GET_LOCATION_OPTIONS" }));
  await axiosConfig
    .get(`/locations/name/${data}?level=8`)
    .then((response) => {
      dispatch(slice.actions.getLocationOptions(response.data.data));
    })
    .catch((error) => {
      // handle error
      if (error?.response?.status === 500 && error?.response?.status === 403) {
        toast.error("Az adatok betöltése sikertelen");
      }
      console.error(error);
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: "GET_LOCATION_OPTIONS" }));
    });
};

export const setInitialShapesValue = (data) => (dispatch) => {
  dispatch(slice.actions.setInitialShapesValue(data));
};

export const setPropertySearchData = (data) => (dispatch) => {
  dispatch(slice.actions.setPropertySearchData(data));
};

export const clearProperties = () => (dispatch) => {
  dispatch(slice.actions.clearProperties());
};

export const clearPropertiesData = () => (dispatch) => {
  dispatch(slice.actions.clearPropertiesData());
};

export const clearOffice = () => (dispatch) => {
  dispatch(slice.actions.clearOffice());
};

export const register = (data) => async (dispatch) => {
  dispatch(toggleLoading({ type: "REGISTRATION" }));
  await axiosConfig
    .post("/users/registration", data)
    .then((response) => {
      // dispatch(slice.actions.registration(response.data));
      toast.success(
        "Sikeres regisztráció, kérjük erősítse meg a regisztrációt az e-mail címén keresztül"
      );
    })
    .catch((error) => {
      // handle error
      toast.error("A regisztráció sikertelen");
      console.error(error);
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: "REGISTRATION" }));
    });
};
export const login = (data) => async (dispatch) => {
  dispatch(toggleLoading({ type: "LOGIN" }));
  await axiosConfig
    .post("/login", data)
    .then((response) => {
      // dispatch(slice.actions.login(response.data));
      toast.success("Sikeres bejelentkezés");
    })
    .catch((error) => {
      // handle error
      toast.error("A bejelentkezés sikertelen");
      console.error(error);
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: "LOGIN" }));
    });
};

export const updateUserData = (data, id) => async (dispatch) => {
  dispatch(toggleLoading({ type: "UPDATE_USER_DATA" }));
  await axiosConfig
    .put(`/users/${id}`, data)
    .then((response) => {
      // dispatch(slice.actions.login(response.data));
      toast.success("Sikeres adatmódosítás");
    })
    .catch((error) => {
      // handle error
      toast.error("A módosítás sikertelen");
      console.error(error);
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: "UPDATE_USER_DATA" }));
    });
};

export const registerConfirm = (uuid) => async (dispatch) => {
  dispatch(toggleLoading({ type: "REGISTRATION_CONFIRM" }));
  await axiosConfig
    .post(`/emails/${uuid}/users/confirmation`)
    .then((response) => {
      // dispatch(slice.actions.registration(response.data));
      dispatch(slice.actions.pushIntoGeneralArray("verify_success"));
      // toast.success('Sikeres regisztráció');
    })
    .catch((error) => {
      // handle error
      // toast.error('A regisztráció sikertelen');
      dispatch(slice.actions.pushIntoGeneralArray("verify_failure"));
      console.error(error);
    })
    .then(() => {
      dispatch(toggleLoading({ type: "REGISTRATION_CONFIRM" }));
      // always executed
    });
};

export const registerDelete = (uuid) => async (dispatch) => {
  dispatch(toggleLoading({ type: "REGISTRATION_CONFIRM" }));
  await axiosConfig
    .delete(`/emails/${uuid}/users/confirmation`)
    .then((response) => {
      dispatch(slice.actions.pushIntoGeneralArray("verify_success"));
      // dispatch(slice.actions.registration(response.data));
    })
    .catch((error) => {
      // handle error
      // toast.error('A regisztráció sikertelen');
      dispatch(slice.actions.pushIntoGeneralArray("verify_failure"));
      console.error(error);
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: "REGISTRATION_CONFIRM" }));
    });
};

export const requestPasswordReset = (data) => async (dispatch) => {
  // dispatch(toggleLoading({ type: 'PASSWORD_RESET_REQUEST' }));
  await axiosConfig
    .post("/users/password/reset", data)
    .then((response) => {
      // dispatch(slice.actions.registration(response.data));
      toast.success(
        "Új jelszó kérése sikeres. Kérem ellenőrizze az E-mail fiókját"
      );
    })
    .catch((error) => {
      // handle error
      toast.error("A jelszó változtatásának kérése sikertelen");
      console.error(error);
    })
    .then(() => {
      // always executed
      // dispatch(toggleLoading({ type: 'PASSWORD_RESET_REQUEST' }));
    });
};

export const passwordReset = (uuid, data) => async (dispatch, getState) => {
  // dispatch(slice.actions.resetGeneralArray());
  await axiosConfig
    .put(`/emails/${uuid}/users/password/reset`, data)
    .then((response) => {
      // dispatch(slice.actions.registration(response.data));
      // dispatch(toggleLoading({ type: 'PASSWORD_RESET' }));
      dispatch(slice.actions.erasefromGeneralArray("password_change_failure"));
      dispatch(slice.actions.pushIntoGeneralArray("password_change_success"));
      toast.success("A jelszó visszaállítása sikeres");
    })
    .catch((error) => {
      // handle error
      console.log("test error");
      dispatch(slice.actions.erasefromGeneralArray("password_change_success"));
      dispatch(slice.actions.pushIntoGeneralArray("password_change_failure"));
      toast.error("A jelszó visszaállítása sikertelen");
      console.error(error);
    })
    .then(() => {
      // always executed
      // check generalArray length
      // if (getState().property.generalArray.length < 0) {
      //   dispatch(slice.actions.pushIntoGeneralArray('password_change_failure'));
      // }
      dispatch(toggleLoading({ type: "PASSWORD_RESET" }));
    });
};

export const getPresentations = (uuid) => async (dispatch) => {
  dispatch(toggleLoading({ type: "GET_PRESENTATIONS" }));
  await axiosConfig
    .get(`/emails/${uuid}/clients/presentations`)
    .then((response) => {
      dispatch(slice.actions.getPresentations(response.data));
    })
    .catch((error) => {
      // handle error
      toast.error("A bemutatók lekérése sikertelen");
      console.error(error);
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: "GET_PRESENTATIONS" }));
    });
};

export const getPresentationsBoIp = (uuid) => async (dispatch) => {
  dispatch(toggleLoading({ type: "GET_PRESENTATIONS" }));
  await axiosConfig
    .get(`/presentations/${uuid}`)
    .then((response) => {
      dispatch(slice.actions.getPresentations(response.data));
    })
    .catch((error) => {
      // handle error
      toast.error("A bemutatók lekérése sikertelen");
      console.error(error);
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: "GET_PRESENTATIONS" }));
    });
};

export const modifySearchParameterRequest = (uuid) => async (dispatch) => {
  dispatch(toggleLoading({ type: "MODIFY_SEARCH_PARAMETER" }));
  await axiosConfig
    .post(`/emails/${uuid}/clients/update/search`)
    .then((response) => {
      // dispatch(slice.actions.modifySearchParameter(response.data));
      toast.success("Sikeres keresési paraméterek módosításának kérése");
    })
    .catch((error) => {
      // handle error
      toast.error("A keresési paraméterek módosítása sikertelen");
      console.error(error);
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: "MODIFY_SEARCH_PARAMETER" }));
    });
};

export const unsubscribeRequest = (uuid) => async (dispatch) => {
  dispatch(toggleLoading({ type: "UNSUBSCRIBE" }));
  await axiosConfig
    .post(`/emails/${uuid}/clients/unsubscribe`)
    .then((response) => {
      // dispatch(slice.actions.modifySearchParameter(response.data));
      toast.success("Sikeres leiratkozás");
    })
    .catch((error) => {
      // handle error
      toast.error("A leiratkozás sikertelen");
      console.error(error);
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: "UNSUBSCRIBE" }));
    });
};

export const unsubscribeWeeklyStat = (uuid) => async (dispatch) => {
  dispatch(toggleLoading({ type: "UNSUBSCRIBE_WEEKLY_STAT" }));
  await axiosConfig
    .post(`/emails/${uuid}/weekly-stat/unsubscribe`)
    .then((response) => {
      // dispatch(slice.actions.modifySearchParameter(response.data));
      toast.success("Sikeres leiratkozás");
    })
    .catch((error) => {
      // handle error
      toast.error("A leiratkozás sikertelen");
      console.error(error);
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: "UNSUBSCRIBE_WEEKLY_STAT" }));
    });
};

export const HPresentationRequest =
  (uuid, propertyPublicId) => async (dispatch) => {
    dispatch(toggleLoading({ type: "H_PRESENTATION" }));
    await axiosConfig
      .post(`/emails/${uuid}/clients/HP/${propertyPublicId}`)
      .then((response) => {
        // dispatch(slice.actions.modifySearchParameter(response.data));
        toast.success("Sikeres helyszíni bemutató kérése");
      })
      .catch((error) => {
        // handle error
        toast.error("A helyszíni bemutató kérése sikertelen");
        console.error(error);
      })
      .then(() => {
        // always executed
        dispatch(toggleLoading({ type: "H_PRESENTATION" }));
      });
  };

const geMessageEndpoint = (type) => {
  switch (type) {
    case "partner":
      return "messages/partner";
    case "employee":
      return "messages/employee";
    case "career":
      return "messages/career";
    default:
      return "messages";
  }
};

export const Message = (data, type) => async (dispatch) => {
  dispatch(toggleLoading({ type: "MESSAGE" }));
  await axiosConfig
    .post(geMessageEndpoint(type), data)
    .then((response) => {
      toast.success(
        "Üzenetedet sikeresen elküldted. Kollégánk hamarosan felveszi veled a kapcsolatot."
      );
    })
    .catch((error) => {
      // handle error
      toast.error("Az üzenetküldés sikertelen");
      console.error(error);
    })
    .then((error) => {
      // always executed
      dispatch(toggleLoading({ type: "MESSAGE" }));
    });
};

export const MessagePartner = (data, type) => async (dispatch) => {
  // TODO: same as Message just differene success
  dispatch(toggleLoading({ type: "MESSAGE" }));
  await axiosConfig
    .post(geMessageEndpoint(type), data)
    .then((response) => {
      toast.success(
        'Köszönjük, hogy érdeklődik Lido Partner programunk iránt. Kollégánk hamarosan felveszi Önnel a kapcsolatot!"'
      );
    })
    .catch((error) => {
      // if (error?.response?.data?.validationErrors) {
      //   error?.response?.data?.validationErrors?.forEach((error) => {
      //     toast.error(error.message);
      //   });
      // } else {
      //   toast.error('Az üzenetküldés sikertelen');
      // }
      // handle error
      toast.error("Az üzenetküldés sikertelen");
      console.error(error);
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: "MESSAGE" }));
    });
};

export const makeFavorite = (propertyId) => async (dispatch) => {
  dispatch(toggleLoading({ type: "MAKE_FAVORITE" }));
  await axiosConfig
    .post(`/properties/${propertyId}/favorites`)
    .then((response) => {
      toast.success("Sikeres kedvencekhez adás");
    })
    .catch((error) => {
      // handle error
      if (error.response.status === 403) {
        dispatch(slice.actions.openModal("LoginModal"));
        return;
      }
      toast.error("A kedvencekhez adás sikertelen");
      console.error(error);
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: "MAKE_FAVORITE" }));
    });
};

export const removeFavorite = (propertyId) => async (dispatch) => {
  dispatch(toggleLoading({ type: "REMOVE_FAVORITE" }));
  await axiosConfig
    .delete(`/properties/${propertyId}/favorites`)
    .then((response) => {
      toast.success("Sikeres kedvenc törlés");
    })
    .catch((error) => {
      // handle error
      if (error.response.status === 403) {
        dispatch(slice.actions.openModal("LoginModal"));
        return;
      }
      toast.error("A kedvenc törlése sikertelen");
      console.error(error);
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: "REMOVE_FAVORITE" }));
    });
};

export const getFavorites = () => async (dispatch) => {
  dispatch(toggleLoading({ type: "GET_FAVORITES" }));
  await axiosConfig
    .get("/properties/favorites")
    .then((response) => {
      dispatch(slice.actions.getProperties(response.data));
    })
    .catch((error) => {
      // handle error
      toast.error("A kedvencek lekérése sikertelen");
      console.error(error);
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: "GET_FAVORITES" }));
    });
};

export const candidateApplication = (data) => async (dispatch) => {
  dispatch(toggleLoading({ type: "CANDIDATE_APPLICATION" }));
  await axiosConfig
    .post("/candidates", data)
    .then((response) => {
      dispatch(slice.actions.setKarrierFormSubmit(true));
      toast.success(
        "Jelentkezésedet sikeresen elküldted. Kollégánk 24-48 órán belül felveszi veled a kapcsolatot."
      );
    })
    .catch((error) => {
      // handle error
      if (error.response.data.message === "Email must be unique.") {
        toast.error("A jelentkezés sikertelen, ezzel az e-mail címmel már küldtek be jelentkezést.");
      } else {
        toast.error("A jelentkezés sikertelen");
      }
      dispatch(slice.actions.setKarrierFormSubmit(false));
      console.error(error);
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: "CANDIDATE_APPLICATION" }));
    });
};

export const setKarrierFormSubmit = (data) => (dispatch) => {
  dispatch(slice.actions.setKarrierFormSubmit(data));
}

export const rateEmployee = (employeeId, data) => async (dispatch) => {
  dispatch(toggleLoading({ type: "RATE_EMPLOYEE" }));
  await axiosConfig
    .post(`users/${employeeId}/ratings`, data)
    .then((response) => {
      toast.success("Sikeres értékelés");
      dispatch(switchModal("rateModal", false));
    })
    .catch((error) => {
      // handle error
      toast.error("Az értékelés sikertelen");
      console.error(error);
    })
    .then(() => {
      // always executed
      dispatch(toggleLoading({ type: "RATE_EMPLOYEE" }));
    });
};

export const deActivateUser =
  (id, data, logout, navigate) => async (dispatch) => {
    dispatch(toggleLoading({ type: "DEACTIVATE_USER" }));
    await axiosConfig
      .post(
        // `/${id}/deactivate`,
        `users/${id}/delete`,
        data
      )
      .then((response) => {
        toast.success("Sikeres felhasználó deaktiválás");
        logout();
        navigate("/");
      })
      .catch((error) => {
        // handle error
        toast.error("A felhasználó deaktiválása sikertelen");
        console.error(error);
      })
      .then(() => {
        // always executed
        dispatch(toggleLoading({ type: "DEACTIVATE_USER" }));
      });
  };

export const resetSelectedEmployee = () => async (dispatch) => {
  dispatch(slice.actions.setSelectedEmployee([]));
};

export default slice;
