import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, useMediaQuery } from '@mui/material';

const ScrollToTopOnRoute = () => {
  const location = useLocation();
  const topRef = useRef(null);
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const forceScroll = () => {
    if (topRef !== null) {
      if (lgDown) {
        topRef.current.scrollIntoView();
      } else {
        window.scrollTo(0, 0);
      }
    }
  };

  useEffect(() => {
    forceScroll();
  }, [location.pathname]);

  return (
    <Box
      ref={topRef}
    />
  );
};

export default ScrollToTopOnRoute;
