import { Box, Container, Grid } from '@mui/material';
import PropTypes from 'prop-types';

const ParallaxSection = ({ bg, bgColor, maskOpacity, darken = false, children }) => (
  <Grid
    py={10}
    justifyContent="space-between"
    position="relative"
    sx={{
      background: `url(${bg})`,
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }}
  >
    <Box
      position="absolute"
      sx={{
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: bgColor ?? 'white',
        opacity: maskOpacity ?? 0.8,
      }}
    />
    {darken && (
      <Box
        position="absolute"
        sx={{
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'black',
          opacity: 0.5,
        }}
      />
    )}

    <Container
      py={10}
      justifyContent="space-between"
      sx={{
        position: 'relative',
        zIndex: 100
      }}
    >
      {children}
    </Container>
  </Grid>
);

ParallaxSection.propTypes = {
  bg: PropTypes.string,
  bgColor: PropTypes.string,
  maskOpacity: PropTypes.number,
  darken: PropTypes.bool,
  children: PropTypes.any
};

export default ParallaxSection;
