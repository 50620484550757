import {
  Box,
  Grid,
  Typography,
  Dialog,
  Tabs,
  Tab,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { switchModal } from '../app/slices/property';
import { useDispatch, useSelector } from '../app/store';
import '../App.css';
import LoginRegistrationForm from './LoginRegistrationForm';
import Logo from './Logo';
import ForgottPasswordModal from './ForgottPasswordModal';

const LoginModal = () => {
  const { openModals } = useSelector((state) => state.property);
  const { t } = useTranslation();
  const [page, setPage] = useState('login');
  const dispatch = useDispatch();
  const handleModalClose = () => {
    dispatch(switchModal('LoginModal', false));
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={handleModalClose}
      open={openModals.includes('LoginModal')}
    >
      <Grid
        container
        sx={{
          overflowX: 'hidden',
        }}
      >
        <Grid
          item
          p={2}
          md={12}
          mb={2}
          maxWidth={320}
        >
          <Tabs
            indicatorColor="primary"
            scrollButtons="auto"
            allowScrollButtonsMobile
            variant="scrollable"
            textColor="primary"
            value={page}
          >
            <Tab
              label={t('login')}
              value="login"
              onClick={() => setPage('login')}
            />
            <Tab
              label={t('registration')}
              value="registration"
              onClick={() => setPage('registration')}
            />
          </Tabs>
        </Grid>
        <Grid
          display="flex"
          item
          md={12}
          xs={12}
        >
          {page === 'login' ? (
            <Grid container>
              <Grid
                item
                md={7}
                xs={12}
                sm={12}
              >
                <LoginRegistrationForm type="login" />
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  p={2}
                  pt={1}
                />
              </Grid>
              <Grid
                item
                md={5}
                xs={12}
                sm={12}
              >
                <Box
                  // red box
                  sx={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: 'primary.main',
                    borderRadius: '20px 0px 0px 0px',
                    color: 'white',
                    p: 2,
                    px: 4
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    mb={3}
                  >
                    <Logo home />
                  </Box>
                  <Box display="flex">
                    <Box width="100%">
                      <Typography
                        variant="h6"
                        textAlign="center"
                      >
                        Üdvözöljük
                        <br />
                        a Lido Home Világában!
                      </Typography>
                    </Box>
                  </Box>
                  <Box mt={3}>
                    <Typography variant="h8">
                      Köszönjük, hogy ingatlanaink iránt érdeklődik. Oldalunkon
                      történő regisztrációval még könnyebbé válik az
                      ingatlanvásárlás.
                      {' '}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              // p={2}
            >
              <Grid
                item
                md={7}
                xs={12}
                sm={12}
                mb={5}
              >
                <LoginRegistrationForm type="registration" />
              </Grid>
              <Grid
                item
                md={5}
                xs={12}
                sm={12}
              >
                <Box
                  // red box
                  sx={{
                    height: '100%',
                    width: '100%',
                    borderRadius: '20px 0px 0px 0px',
                    backgroundColor: 'primary.main',
                    color: 'white',
                    px: 4,
                  }}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-around"
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                  >
                    <Logo home />
                  </Box>
                  <Box>
                    <Box display="flex">
                      <Box>
                        <Typography variant="h6">
                          Regisztráljon most és...
                        </Typography>
                      </Box>
                    </Box>
                    <Box mt={3}>
                      <Typography variant="h7">
                        • mentse el kedvenc ingatlanjait!
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h7">
                        • értesüljön azonnal a változásokról!
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h7">
                        • részesüljön exkluzív ajánlatainkban!
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography variant="h6">
                      Ingatlanok egy életen át!
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <ForgottPasswordModal />
    </Dialog>
  );
};

export default LoginModal;
