import * as React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

const NumberFormatCustom = React.forwardRef((props, ref) => {
  const {
    onChange,
    suffix,
    prefix,
    ...other
  } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      decimalSeparator={props.name === 'gpsLatitude' || props.name === 'gpsLongitude' ? '.' : ','}
      onValueChange={(values) => {
        if (onChange) {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }
      }}
      thousandSeparator={props.name === 'gpsLatitude' || props.name === 'gpsLongitude' ? '' : ' '}
      isnumericstring="true"
      suffix={suffix}
      prefix={prefix}
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  suffix: PropTypes.string,
  prefix: PropTypes.string,
};

export default NumberFormatCustom;
