import { Container, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PasswordResetForm from './PasswordResetForm';

const PasswordReset = () => {
  const { t } = useTranslation();

  return (
    <Grid my={20}>
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography
            sx={{ mb: 2 }}
          >
            {t('passwordReset')}
          </Typography>
          <PasswordResetForm />
        </Box>
      </Container>
    </Grid>
  );
};

export default PasswordReset;
