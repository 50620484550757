import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Card, CardMedia, Grid, Typography,
  Skeleton,
  Fade,
  Divider,
} from '@mui/material';
import NumberFormatCustom from '../utils/NumberFormatCustom';
import { Link } from 'react-router-dom';
import ImageIcon from '@mui/icons-material/Image';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { useDispatch } from '../app/store';
import { makeFavorite, removeFavorite } from '../app/slices/property';
import useAuth from '../hooks/useAuth';
import { useTranslation } from 'react-i18next';

const { REACT_APP_FILE_URL } = process.env;

const PropertyCard = (props) => {
  const { t } = useTranslation();
  const { property, loading, mwidth } = props;
  const { user } = useAuth();
  const [fav, setFav] = useState(property?.isFavorite);
  const pictureHeight = 250;
  const skeletonHeight = 16;
  const dispatch = useDispatch();
  const handleFavorite = () => {
    if (!fav) {
      dispatch(makeFavorite(property?.id));
      if (user) {
        setFav(true);
      }
    } else {
      dispatch(removeFavorite(property?.id));
      if (user) {
        setFav(false);
      }
    }
  };
  return (
    <Grid
      component={!loading ? Link : null}
      to={!loading ? `/properties/details/${property?.publicId}` : null}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        textDecoration: 'none',
      }}
    >
      <Card
        sx={{
          height: '100%',
          width: mwidth && mwidth,
          boxShadow: 'rgba(0, 0, 0, 0.20) 0px 10px 20px, rgba(0, 0, 0, 0.13) 0px 6px 6px',
          transition: 'all 0.2s ease-in-out',
          border: '2px transparent solid',
          '&:hover': {
            border: '2px solid #962F32'
          }
        }}
      >
        {!loading ? (
          <>
            <Grid>
              <Box
                sx={{ height: '100%' }}
                display="flex"
                justifyContent="flex-end"
                onClick={(event) => {
                  event.preventDefault();
                  handleFavorite();
                }}
              >
                <img
                  id="heart"
                  alt="heart"
                  style={{
                    height: 32,
                    width: 32,
                    zIndex: 1,
                    margin: 10,
                    padding: 4,
                    cursor: 'pointer',
                    position: 'absolute',
                    backgroundColor: '#65656566',
                    borderRadius: '50%'
                  }}
                  src={!fav ? '/static/heart.svg' : '/static/heart2.svg'}
                />
              </Box>
              <Box>
                {`${REACT_APP_FILE_URL}${property?.coverImage?.source?.thumb}` ? (
                  <>
                    <Fade
                      in
                      appear
                    >
                      <CardMedia
                        image={`${REACT_APP_FILE_URL}${property?.coverImage?.source?.thumb}`}
                        style={{
                          ckgroundColor: 'background.default',
                          height: pictureHeight,
                          borderTopLeftRadius: '10px',
                          borderTopRightRadius: '10px',
                        }}
                      />
                    </Fade>
                    <Box
                      sx={{
                        position: 'relative',
                      }}
                    >
                      <Box
                        sx={{
                          position: 'absolute',
                          left: 0,
                          top: -242,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        {property?.attributeOptions?.publicTags?.map((option, i) => (
                          <Fragment key={option.id}>
                            {i < 3 && (
                              <Typography
                                variant="h8"
                                fontSize="10px"
                                color="white"
                                fontWeight="bold"
                                width="fit-content"
                                sx={{
                                  backgroundColor: 'rgb(150, 47, 50, 0.8)',
                                  borderTopRightRadius: '5px',
                                  borderBottomRightRadius: '5px',
                                  padding: 0.8,
                                  pl: 1,
                                  my: 0.6,
                                  textTransform: 'uppercase',
                                  letterSpacing: '1px',
                                }}
                              >
                                {option.name}
                              </Typography>
                            )}
                          </Fragment>
                        ))}
                      </Box>
                    </Box>
                  </>
                ) : (
                  <Box
                    sx={{
                      alignItems: 'center',
                      backgroundColor: 'background.default',
                      display: 'flex',
                      width: '100%',
                      border: 2,
                      borderColor: 'background.paper',
                      minWidth: 'auto',
                      minHeight: pictureHeight,
                      justifyContent: 'center',
                      borderTopLeftRadius: '10px',
                      borderTopRightRadius: '10px',
                    }}
                  >
                    <ImageIcon
                      fontSize="medium"
                      color="action"
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </>
        ) : (
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{
              m: 0.5,
              borderRadius: 1,
            }}
            height={pictureHeight}
          />
        )}
        <Box
          p={2}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ mt: 1 }}
          >
            {!loading ? (
              <>
                <Typography
                  sx={{
                    textTransform: 'capitalize'
                  }}
                  fontSize="1.5rem"
                  fontWeight="800"
                >
                  <>
                    {property?.transactionType
                      && (property?.transactionType === 1 ? 'Eladó' : 'Kiadó')}
                    {' '}
                    {property?.attributeOptions?.residential
                      && property?.attributeOptions?.residential[0]?.name}
                    {property?.attributeOptions?.agricultural
                      && property?.attributeOptions?.agricultural[0]?.name}
                    {property?.attributeOptions?.business
                      && property?.attributeOptions?.business[0]?.name}
                    {property?.attributeOptions?.industrial
                      && property?.attributeOptions?.industrial[0]?.name}
                  </>
                </Typography>
              </>
            ) : (
              <>
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  sx={{
                    borderRadius: 0.5,
                  }}
                  width={130}
                  height={skeletonHeight}
                />
              </>
            )}
          </Box>
          <Box>
            {!loading ? (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ mt: 0.2 }}
                >
                  <LocationOnOutlinedIcon
                    fontSize="5px"
                    sx={{ mr: '1px', color: '#616161' }}
                  />
                  <Typography
                    variant="h7"
                    color="#616161"
                  >
                    {property?.address?.city || 'Nincs megadva'}
                  </Typography>
                  {property?.address?.suburb ? (
                    <Typography
                      variant="h8"
                      color="#616161"
                      sx={{
                        ml: 2
                      }}
                    >
                      {`(${property?.address?.suburb})`}
                    </Typography>
                  ) : (
                    <Box sx={{ mt: 2.6 }} />
                  )}
                </Box>
              </>
            ) : (
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{
                  borderRadius: 0.5,
                  mt: 1,
                  mb: 1
                }}
                width={130}
                height={skeletonHeight}
              />
            )}
          </Box>
          <Box mt={2}>
            {!loading ? (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ ml: 0.4 }}
                >
                  <Typography
                    color="#616161"
                    variant="h7"
                  >
                    {'ID: '}
                    <span style={{ fontWeight: 'bold' }}>
                      {property?.publicId}
                    </span>
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  sx={{ ml: 0.4 }}
                >
                  <Typography
                    color="primary"
                    fontWeight="bold"
                    variant="h6"
                  >
                    {(property?.price || property?.price) && (
                      <NumberFormatCustom
                        value={property?.price || property?.price}
                        displayType="text"
                        suffix={` ${property?.currency}`}
                      />
                    )}
                  </Typography>
                </Box>
              </>
            ) : (
              <Box
                display="flex"
                justifyContent="space-between"
              >
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  sx={{
                    borderRadius: 0.5,
                    mb: 1
                  }}
                  width={130}
                  height={skeletonHeight}
                />
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  sx={{
                    borderRadius: 0.5,
                    mt: 1,
                    mb: 1
                  }}
                  width={130}
                  height={skeletonHeight + 10}
                />
              </Box>
            )}
          </Box>
          <Divider sx={{ mb: 0.5, mt: 0.5 }} />
          <Box
            display="flex"
          >
            {!loading ? (
              <Grid container>
                {property?.floorArea ? (
                  <Box
                    sx={{
                      borderRadius: '10px',
                      p: 1,
                      mr: 2,
                      mt: 1,
                      backgroundColor: '#F7F7F7'
                    }}
                  >
                    <Typography
                      variant="h8"
                      fontWeight="bold"
                    >
                      <NumberFormatCustom
                        value={property?.floorArea}
                        displayType="text"
                        suffix=" m&sup2;"
                      />
                    </Typography>
                  </Box>
                ) : ('')}
                {property?.landArea ? (
                  <Box
                    sx={{
                      borderRadius: '10px',
                      p: 1,
                      mr: 2,
                      mt: 1,
                      backgroundColor: '#F7F7F7'
                    }}
                  >
                    <Typography
                      variant="h8"
                      fontWeight="bold"
                    >
                      <>
                        <NumberFormatCustom
                          value={property?.landArea}
                          displayType="text"
                          suffix=" m&sup2;"
                        />
                        {' '}
                        telek
                      </>
                    </Typography>
                  </Box>
                ) : ('')}
                {property?.numberOfRooms ? (
                  <Box
                    sx={{
                      borderRadius: '10px',
                      p: 1,
                      mr: 2,
                      mt: 1,
                      backgroundColor: '#F7F7F7'
                    }}
                  >
                    <Typography
                      variant="h8"
                      fontWeight="bold"
                    >
                      {property?.numberOfRooms}
                      {property.numberOfHalfRooms < 0 || !property.numberOfHalfRooms && ' szoba'}
                      {property.numberOfHalfRooms > 0 && ' + '}
                      {property.numberOfHalfRooms}
                      {property.numberOfHalfRooms && ' ' + t("halfRoom")}
                    </Typography>
                  </Box>
                ) : ('')}
              </Grid>
            ) : (
              <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{
                  borderRadius: 0.5,
                }}
                width="100%"
                height={skeletonHeight + 15}
              />
            )}
          </Box>
        </Box>
      </Card>
    </Grid>
  );
};

PropertyCard.propTypes = {
  property: PropTypes.object,
  loading: PropTypes.bool,
  mwidth: PropTypes.any,
};

export default PropertyCard;
