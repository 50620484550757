import {
  Card,
  Box,
  Grid,
  Button,
  Typography,
  useMediaQuery,
  CardMedia,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { setSelectedProject } from '../app/slices/property';
import { useDispatch, useSelector } from '../app/store';
import '../App.css';
import NumberFormatCustom from '../utils/NumberFormatCustom';
import PropertyBrowse from './PropertyBrowse';
import LidoBanner from '../utils/LidoBanner';
import KeyIcon from '@mui/icons-material/Key';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import HouseIcon from '@mui/icons-material/House';
import BedIcon from '@mui/icons-material/Bed';
import ApartmentIcon from '@mui/icons-material/Apartment';
import StyledTypographyUnderline from '../utils/StyledTypographyUnderline';
import StyledRegularText from '../utils/StyledRegularText';
import ProjectBrowse from './ProjectBrowse';
import { Helmet } from 'react-helmet-async';
import projectParallax from '../images/projectDetailParallax.jpg';
import ParallaxSection from './ParallaxSection';
import parse from 'html-react-parser';

const { REACT_APP_FILE_URL } = process.env;

const ProjectDetails = () => {
  const bottomRef = useRef(null);
  const { selectedProject } = useSelector((state) => state.property);
  const { id } = useParams();
  const project = selectedProject;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const pictureHeight = 350;
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  const forceScroll = () => {
    if (bottomRef !== null) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getNumberOfRooms = (min, max) => {
    if (min && max) {
      return `${min} - ${max} szoba`;
    }
    if (min) {
      return `${min} szoba`;
    }
    if (max) {
      return `${max} szoba`;
    }
    return '';
  }

  const projectData = [
    {
      title: t('plannedRelease'),
      subTitle: selectedProject?.expectedDeliveryDate,
      icon: KeyIcon,
    },
    {
      title: t('price'),
      subTitle: (
        <Typography
          variant="h7"
        >
          {project.minSalePrice && project.minSalePrice / 1000000 || ''}
          {project.minSalePrice && '-'}
          {project.maxSalePrice && project.maxSalePrice / 1000000 || ''}
          {(project.minSalePrice || project.maxSalePrice) && ' Millió '}
          {project?.currency && (project.minSalePrice || project.maxSalePrice) && 'Ft'}
        </Typography>
      ),
      icon: CreditCardIcon,
    },
    {
      title: t('floorArea'),
      subTitle: (
        <Typography variant="h7">
          {project?.minFloorArea || ''}
          {project?.minFloorArea && '-'}
          <NumberFormatCustom
            value={project?.maxFloorArea || ''}
            displayType="text"
            suffix=" m&sup2;"
          />
        </Typography>
      ),
      icon: HouseIcon,
    },
    {
      title: t('roomCount'),
      subTitle: (
        <Typography variant="h7">
          {getNumberOfRooms(project?.minNumberOfRooms, project?.maxNumberOfRooms)}
        </Typography>
      ),
      icon: BedIcon,
    },
    {
      title: t('allApartments'),
      subTitle: (
        <Typography variant="h7">
          {project?.properties?.length > 0 && project?.properties?.length}
          {project?.properties?.length > 0 && ' db lakás'}
        </Typography>
      ),
      icon: ApartmentIcon,
    },
  ];

  useEffect(() => {
    dispatch(setSelectedProject(id));
    return () => {
      dispatch(setSelectedProject('zero'));
    };
  }, [id]);

  return (
    <Grid>
      <Helmet><title>{`LidoHome ${project?.title ? ` | ${project.title}` : ''}`}</title></Helmet>
      <LidoBanner
        text={project?.title}
        subText={project?.address}
        image={`${REACT_APP_FILE_URL}${project?.image?.source?.large}`}
      />
      {!lgDown && (
        <Box
          data-aos="fade-up"
          zIndex={2}
          sx={{
            position: 'fixed',
            right: -32,
            bottom: '30%',
            rotate: '-90deg',
            backdropFilter: 'blur(15px)',
            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
          }}
        >
          <Button
            onClick={forceScroll}
            variant="contained"
            style={{ cursor: 'pointer', borderRadius: '8px 8px 0px 0px', height: 50, paddingBottom: 20 }}
          >
            Kapcsolat
          </Button>

        </Box>
      )}
      <ParallaxSection bg={projectParallax}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            md={12}
            mt={-16}
            zIndex={10}
          >
            <Card>
              <Grid
                container
                spacing={2}
                p={2}
              >
                {projectData.map((item) => (
                  <Grid
                    item
                    xl={2.4}
                    lg={2.4}
                    md={2.4}
                    sm={4}
                    xs={12}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      textAlign="center"
                    >
                      <item.icon
                        style={{
                          color: '#962F32',
                          height: 32,
                          width: 32,
                          opacity: 0.8,
                        }}
                      />
                      <Typography
                        mt={2}
                        fontWeight="bold"
                      >
                        {item?.title}
                        {' '}
                      </Typography>
                      <Typography>
                        {item?.subTitle}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Card>
          </Grid>
          <Grid
            item
            md={12}
          >
            {project?.id && (
              <PropertyBrowse
                noBackground
                text="Lakások"
                searchParam={{
                  projectId: project?.id,
                }}
              />
            )}
          </Grid>
          <Grid
            item
            md={12}
            mt={6}
            display="flex"
            justifyContent="center"
          >
            <StyledTypographyUnderline>
              {t('knowTheProject')}
            </StyledTypographyUnderline>
          </Grid>
          <Grid
            item
            md={6}
            mt={6}
            display="flex"
            flexDirection="column"
          >
            <Typography
              variant="h5"
              color="primary"
              mb={2}
              data-aos={!lgDown && 'fade-right'}
            >
              A projektről
            </Typography>
            <StyledRegularText>
              {project?.note && parse(project.note)}
            </StyledRegularText>
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            mt={12}
          >
            {project?.image && project?.image?.source?.large ? (
              <CardMedia
                image={`${REACT_APP_FILE_URL}${project?.image?.source?.large}`}
                style={{
                  marginTop: 10,
                  backgroundColor: 'background.default',
                  height: pictureHeight,
                  borderRadius: 18,
                }}
              />
            ) : (
              <>
                <Box
                  sx={{
                    alignItems: 'center',
                    backgroundColor: 'white',
                    display: 'flex',
                    width: '100%',
                    border: 2,
                    borderColor: 'background.paper',
                    minWidth: 'auto',
                    minHeight: pictureHeight,
                    justifyContent: 'center',
                    borderTopLeftRadius: 18,
                    borderTopRightRadius: 18,
                  }}
                />
              </>
            )}
          </Grid>
        </Grid>
        <Grid
          mt={15}
        >
          <ProjectBrowse
            text="További Projektek"
            TextComponent={StyledTypographyUnderline}
            excludeById={project?.id}
          />
        </Grid>
        <Box ref={bottomRef} />
      </ParallaxSection>
    </Grid>
  );
};

export default ProjectDetails;
