import {
  Box,
  Container,
  Grid,
  Pagination,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  useDispatch,
  useSelector
} from '../app/store';
import { searchProject } from '../app/slices/property';
import ProjectCard from './ProjectCard';
import PropTypes from 'prop-types';

const ProjectBrowse = ({ text, TextComponent, minusCard, excludeById }) => {
  const minCard = minusCard || 0;
  const breakPoints = { xl: 1600, lg: 1170, md: 768, sm: 576, xs: 0 };
  const getPageSize = () => {
    if (window.innerWidth > breakPoints.xl) return 3 - minCard;
    if (window.innerWidth < breakPoints.xl && window.innerWidth > breakPoints.lg) return 3 - minCard;
    if (window.innerWidth < breakPoints.lg && window.innerWidth > breakPoints.md) return 2;
    if (window.innerWidth < breakPoints.md && window.innerWidth > breakPoints.sm) return 1;

    return 1;
  };
  const [page, setPage] = useState(1);
  const {
    projects,
  } = useSelector((state) => state.property);
  const [pageSize, setPageSize] = useState(getPageSize);
  const [projectArray, setProjectArray] = useState(projects?.data || [...Array(pageSize)]);
  const dispatch = useDispatch();

  useEffect(() => {
    setPageSize(getPageSize);
  }, [window.innerWidth]);

  useEffect(() => {
    dispatch(searchProject({ excludeById }, page, pageSize));
  }, [excludeById]);

  useEffect(() => {
    setProjectArray(projects?.data || [...Array(pageSize)]);
  }, [projects]);

  const handlePageChange = (_, nextPage) => {
    setPage(nextPage);
    dispatch(searchProject({ excludeById }, nextPage, pageSize));
  };

  return (
    <Grid
      display="flex"
      flexDirection="column"
      alignItems="center"
      mb={5}
    >
      <Container>
        {TextComponent ? (
          <>
            <TextComponent>
              {text || 'Ez is érdekelhet'}
            </TextComponent>
          </>
        ) : (
          <Typography
            variant="h6"
            fontWeight="bold"
            color="primary"
          >
            {text || 'Ez is érdekelhet'}
          </Typography>
        )}
        <Grid // TODO: responsiveness
          container
          mt={2}
          spacing={2}
          justifyContent="center"
        >

          {projectArray.map((project, i) => (
            <Grid
              item
              key={project?.id || i}
            >
              <ProjectCard
                mwidth="390px"
                project={project}
                loading={!projects.data}
              />
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 7,
          }}
        >
          <Pagination
            count={(projects?.totalPages) || 1}
            boundaryCount={0}
            page={page}
            showFirstButton={false}
            showLastButton={false}
            onChange={(e, nextPage) => {
              handlePageChange(e, nextPage);
            }}
          />
        </Box>
      </Container>
    </Grid>
  );
};

export default ProjectBrowse;

ProjectBrowse.propTypes = {
  text: PropTypes.string,
  TextComponent: PropTypes.elementType,
  minusCard: PropTypes.number,
  excludeById: PropTypes.number,
};
