import { Grid, Typography } from '@mui/material';
import React from 'react';

const StyleHelper = () => (
  <Grid>
    <Typography variant="h1">
      Helo h1 Csak egy helyen lehet az egész oldalon(csak a főoldalon a cím)
    </Typography>
    <Typography variant="h2">
      Helo h2 pagenként egy(a címe)
    </Typography>
    <Typography variant="h3">
      Helo h3
    </Typography>
    <Typography variant="h4">
      Helo h4
    </Typography>
    <Typography variant="h5">
      Helo h5
    </Typography>
    <Typography variant="h6">
      Helo h6
    </Typography>
    <Typography variant="h7">
      Helo h7
    </Typography>
    <Typography variant="h8">
      Helo h8
    </Typography>
  </Grid>
);

export default StyleHelper;
