import React from "react";
import { styled, FormHelperText } from "@mui/material";
import { Box } from "@mui/system";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import PhoneNumberStyle from "./phoneInput/PhoneNumberStyle";
import CustomCountrySelect from "./phoneInput/CustomCountrySelect";
import PropTypes from "prop-types";

const AOCPhoneInput = ({
  name,
  values,
  touched,
  setFieldValue,
  errors,
  bold,
}) => {
  return (
    <>
      <PhoneInput
        sx={{
          fontWeight: "bold",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: bold && "black",
            },
          },
        }}
        InputLabelProps={{
          style: { color: bold && "black", fontWeight: bold && "bold" },
        }}
        countryCallingCodeEditable={false}
        international
        defaultCountry="HU"
        value={values[name]}
        error={Boolean(
          touched[name] &&
            (isPossiblePhoneNumber(values[name])
              ? undefined
              : "Nem létező telefonszám!")
        )}
        onChange={(value) => {
          if (value) {
            setFieldValue(name, value);
          }
        }}
        required
        inputComponent={PhoneNumberStyle}
        countrySelectComponent={CustomCountrySelect}
      />

      {Boolean(touched[name] && errors[name]) && (
        <Box sx={{ mt: 0, ml: 2 }}>
          <FormHelperText error>{errors[name]}</FormHelperText>
        </Box>
      )}
    </>
  );
};

export default AOCPhoneInput;

AOCPhoneInput.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  bold: PropTypes.bool,
};
