import React, { useState, Fragment } from 'react';
import { PropTypes } from 'prop-types';
import { CardMedia, Box, Typography, Collapse, Divider, Button, Grid, Fade } from '@mui/material';
import NumberFormatCustom from '../utils/NumberFormatCustom';
import ImageIcon from '@mui/icons-material/Image';
import { Link } from 'react-router-dom';

const { REACT_APP_FILE_URL } = process.env;

const HighlightedPropertyCard = ({ property }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Box
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {property.coverImage.source.large ? (
        <>
          <CardMedia
            image={`${REACT_APP_FILE_URL}${property.coverImage.source.large}`}
            sx={{
              height: 300,
              borderRadius: '10px',
              filter: hovered && 'saturate(150%)',
              transition: '0.4s',
            }}
          />
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                top: -290,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              {property?.attributeOptions?.publicTags?.map((option, i) => (
                <Fragment key={option.id}>
                  {i < 3 && (
                    <Typography
                      variant="h8"
                      fontSize="10px"
                      color="white"
                      fontWeight="bold"
                      width="fit-content"
                      sx={{
                        backgroundColor: 'rgb(150, 47, 50, 0.8)',
                        borderTopRightRadius: '5px',
                        borderBottomRightRadius: '5px',
                        padding: 0.8,
                        pl: 1,
                        my: 0.6,
                        textTransform: 'uppercase',
                        letterSpacing: '1px',
                      }}
                    >
                      {option.name}
                    </Typography>
                  )}
                </Fragment>
              ))}
            </Box>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: 'background.paper',
            display: 'flex',
            width: '100%',
            border: 2,
            borderColor: 'background.paper',
            minWidth: 'auto',
            minHeight: 300,
            justifyContent: 'center',
            borderTopLeftRadius: 18,
            borderTopRightRadius: 18,
          }}
        >
          <ImageIcon
            fontSize="medium"
            color="action"

          />
        </Box>
      )}
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            px: 1,
            pt: 1,
          }}
        >
          <Typography
            color="white"
            mb={1}
          >
            {property?.transactionType
              && (property?.transactionType === 1 ? 'Eladó' : 'Kiadó')}
            {' '}
            {property?.attributeOptions?.residential
              && property?.attributeOptions?.residential[0]?.name}
            {property?.attributeOptions?.agricultural
              && property?.attributeOptions?.agricultural[0]?.name}
            {property?.attributeOptions?.business
              && property?.attributeOptions?.business[0]?.name}
            {property?.attributeOptions?.industrial
              && property?.attributeOptions?.industrial[0]?.name}
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            mb={1}
          >
            <Typography
              variant="h6"
              color="white"
            >
              {property?.address?.city && property?.address?.city}
            </Typography>
            <Typography
              variant="h6"
              color="white"
            >
              {property.salePrice ? (
                <NumberFormatCustom
                  value={property.salePrice}
                  displayType="text"
                  suffix={property?.currency ? ` ${property?.currency}` : ' Ft'}
                />
              )
                : property.rentPrice && (
                  <NumberFormatCustom
                    value={property.rentPrice}
                    displayType="text"
                    suffix={property?.currency ? ` ${property?.currency}` : ' Ft'}
                  />
                )}
              {!property.salePrice && !property.rentPrice && (
                <Typography variant="subtitle2">Nincs megadva</Typography>
              )}
            </Typography>
          </Box>
          <Collapse
            in={hovered}
            timeout={{ enter: 400, exit: 400 }}
          >
            <Fade
              in={hovered}
              timeout={{ enter: 400, exit: 400 }}
            >
              <Grid
                container
                display="flex"
                justifyContent="space-between"
              >
                <Grid
                  item
                  xs={8}
                >
                  <Divider
                    color="white"
                  />
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                  >
                    <Typography
                      color="white"
                    >
                      <NumberFormatCustom
                        value={property.floorArea}
                        displayType="text"
                        suffix=" m&sup2;"
                      />
                    </Typography>
                    <Divider
                      orientation="vertical"
                      color="white"
                      sx={{ height: 10, mx: 1, mt: 0.2 }}
                    />
                    <Typography
                      color="white"
                    >
                      <NumberFormatCustom
                        value={property.landArea}
                        displayType="text"
                        suffix=" m&sup2;"
                      />
                      {' '}
                      telek
                    </Typography>
                    <Divider
                      orientation="vertical"
                      color="white"
                      sx={{ height: 10, mx: 1, mt: 0.2 }}
                    />
                  </Box>
                  <Typography
                    color="white"
                  >
                    {property.numberOfRooms && property.numberOfRooms}
                    {' '}
                    szoba
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="contained"
                    component={Link}
                    to={`/properties/details/${property.publicId}`}
                    size="large"
                    sx={{ textTransform: 'uppercase' }}
                  >
                    Érdekel
                  </Button>
                </Grid>
              </Grid>
            </Fade>
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
};

HighlightedPropertyCard.propTypes = {
  property: PropTypes.object,
};

export default HighlightedPropertyCard;
