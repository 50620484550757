import { createSlice } from '@reduxjs/toolkit';

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: [],
  reducers: {
    toggleLoading: (state, action) => {
      const loadingStateType = action.payload.type;
      if (state.includes(loadingStateType)) {
        return state = state.filter((loadingState) => loadingState !== loadingStateType);
      }
      state.push(loadingStateType);
      return state;
    }
  }
});

export const selectLoading = (state) => state.loading;

export const { toggleLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
