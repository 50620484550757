import React, { useEffect } from 'react';
import { getAttributeOptions } from '../../app/slices/property';
import { useDispatch } from '../../app/store';
import HomeNavigation from './HomeNavigation';
import { Container, Card, Typography, Grid, Box, CardMedia, Button, CardHeader, CardContent, CardActions, useMediaQuery, Stack, Link as MuiLink } from '@mui/material';
import familyPic from '../../images/home/familyPic.png';
import cyprusBackground from '../../images/home/cyprusBackground.png';
import cyprus from '../../images/home/cyprus.png';
// import whiteLHLogo from '../../images/whiteLHLogo.png';
import oldRedLHLogo from '../../images/oldRedLHLogo.png';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import ScrollToTopOnRoute from '../../components/ScrollToTopOnRoute';
import { Link } from 'react-router-dom';
import ParallaxSection from '../../components/ParallaxSection';
import HighlightedProperties from './HighlightedProperties';
import WhyAdvertiseWithus from './WhyAdvertiseWithUs';
import LetTheNumbersTalk from './LetTheNumbersTalk';
import SocialMediaLinks from './SocialMediaLinks';
import Logo from '../../components/Logo';
import parallax2 from "../../images/aboutusparallax2.jpg";
import about2 from "../../images/about2.jpg";

const textSize = 17;

// const tagManagerArgs = {
//   dataLayer: {
//     // userId: '001',
//     // userProject: 'project',
//     page: 'home'
//   },
//   dataLayerName: 'PageDataLayer'
// }

const Home = () => {
  const { t } = useTranslation('translation');
  const mobileDevice = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAttributeOptions());
  }, []);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'Pageview',
      pagePath: '/',
      pageTitle: 'LidoHome | Főoldal',
    });
  }, []);

  // TagManager.dataLayer(tagManagerArgs)

  // window.dataLayer?.push({
  //   event: 'pageview'
  // });

  // TagManager.dataLayer({
  //   dataLayer: {
  //     event: 'pageview',
  //     pagePath: '/',
  //     pageTitle: 'Lidohome | Főoldal',
  //   },
  // });

  return (
    <>
      <Helmet>
        <title>LidoHome | Főoldal</title>
      </Helmet>
      <ScrollToTopOnRoute />
      <HomeNavigation />
      <SocialMediaLinks />
      <HighlightedProperties />
      <WhyAdvertiseWithus />
      <LetTheNumbersTalk />
      <img
        src={familyPic}
        alt="family"
        style={{
          width: '100%',
          zIndex: 1
        }}
      />
      <Container>
        <Grid
          container
        >
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            mb={12}
            mt="-15%"
          >
            <Box
              sx={{
                display: 'flex',
                zIndex: 1,
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 35px',
                backgroundColor: 'white',
                borderRadius: '10px'
              }}
              data-aos={!lgDown && 'fade-up'}
            >
              <Grid
                container
                spacing={5}
              >
                <Grid
                  item
                  md={4}
                  xs={12}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    gap={4}
                    sx={{
                      backgroundColor: '#8d1f1f',
                      height: '100%',
                      width: '100%',
                      borderTopLeftRadius: '10px',
                      borderBottomLeftRadius: '10px'
                    }}
                  >
                    <Logo home />
                    <Stack>
                      <Typography
                        mt={1}
                        variant="h5"
                        textAlign="center"
                        color="white"
                        fontWeight="bold"
                      >
                        {t('oneFamily.1')}
                        {t('oneFamily.2')}
                      </Typography>
                      <Typography
                        variant="h5"
                        textAlign="center"
                        color="white"
                        fontWeight="bold"
                      >
                        {t('twoGeneration.1')}
                        {t('twoGeneration.2')}
                      </Typography>
                      <Typography
                        variant="h5"
                        textAlign="center"
                        color="white"
                        mb={5}
                        fontWeight="bold"
                      >
                        {t('oneCompany.1')}
                        {t('oneCompany.2')}
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
                <Grid
                  item
                  md={8}
                  xs={12}
                >
                  <Box
                    sx={{ m: 2 }}
                  >
                    <Typography
                      variant="h4"
                      color="primary"
                      sx={{
                        position: 'relative',
                        '&:after': {
                          backgroundColor: 'primary.main',
                          bottom: '-8px',
                          content: '" "',
                          height: '3px',
                          left: 0,
                          position: 'absolute',
                          width: '200px',
                        }
                      }}
                    >
                      {t('footer.aboutUs')}
                    </Typography>
                    <Typography
                      color="text.primary"
                      variant="h5"
                      my={1}
                      mt={5}
                      sx={{
                        fontWeight: 'bold',
                        opacity: 0.7
                      }}
                    >
                      {t('wholeService')}
                    </Typography>
                    <Typography>
                      {t('wholeServiceText1')}
                    </Typography>
                    <Typography sx={{ mt: 1 }}>
                      {t('wholeServiceText2')}
                    </Typography>
                    <Typography
                      color="text.primary"
                      variant="h5"
                      my={1}
                      mt={5}
                      sx={{
                        fontWeight: 'bold',
                        opacity: 0.7
                      }}
                    >
                      {t('wholeSericeText2sub')}
                    </Typography>
                    <Typography sx={{ mt: 1 }}>
                      {t('wholeServiceText3')}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      mt={4}
                    >
                      <Button
                        size="large"
                        variant="contained"
                        component={Link}
                        to="/aboutUs"
                        sx={{
                          fontSize: '1.1rem',
                        }}
                      >
                        {t('learnMore')}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box>
        {/* <ParallaxSection bg={cyprusBackground}>
          <Grid
            container
            spacing={2}
            my={3}
            display="flex"
            alignItems="center"
          >
            <Grid
              item
              md={6}
              xs={12}
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Card sx={{ p: 3 }}>
                <CardHeader
                  title={(
                    <>
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        {!mobileDevice && (
                          <CardMedia
                            image={oldRedLHLogo}
                            sx={{ height: '69px', width: '70px' }}
                          />
                        )}
                        <Typography
                          variant="h5"
                          color="primary"
                          sx={{
                            position: 'relative',
                            textTransform: 'uppercase',
                            ml: 2,
                            '&:after': {
                              backgroundColor: '#962F32',
                              bottom: '-8px',
                              content: '" "',
                              height: '3px',
                              left: 0,
                              position: 'absolute',
                              width: '200px',
                            }
                          }}
                        >
                          {t('lidoHome')}
                        </Typography>
                      </Box>
                      <Typography
                        variant="h6"
                        mx={2}
                        mt={2}
                        sx={{
                          fontWeight: 'bold',
                          opacity: 0.7
                        }}
                      >
                        {t('internationalProperties')}
                      </Typography>
                    </>
                  )}
                />
                <CardContent>
                  <Typography>
                    {t('newDream')}
                  </Typography>
                  <Typography>
                    {t('cyprus')}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    width="100%"
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        px: 6,
                        py: 1.5
                      }}
                    >
                      {t('interestedCyprus')}
                    </Button>
                  </Box>
                </CardActions>
              </Card>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img
                src={cyprus}
                alt="family"
                style={{
                  height: '100%',
                  maxHeight: '550px',
                  zIndex: 1
                }}
              />
            </Grid>
          </Grid>
        </ParallaxSection> */}
        <ParallaxSection bg={parallax2} maskOpacity={0.9}>
          <Grid container justifyContent="space-between">
            <Grid item md={4} sm={12} xs={12}>
              <CardMedia
                image={about2}
                style={{
                  backgroundColor: "background.default",
                  height: textSize * 21,
                  borderRadius: 18,
                }}
              />
            </Grid>
            <Grid item md={8} sm={12} xs={12} p={2}>
              <Typography
                color="primary"
                fontSize={25}
                fontWeight="bold"
                sx={{
                  position: "relative",
                  "&:after": {
                    backgroundColor: "primary.main",
                    bottom: "-8px",
                    content: '" "',
                    height: "3px",
                    left: 0,
                    position: "absolute",
                    width: 200,
                  },
                }}
              >
                Für unsere ausländischen Kunden
              </Typography>
              <Typography mt={3} fontWeight="bold">
                Es ist für uns sehr wichtig, nicht nur für die Leute in Ungarn, sondern auch denjenigen, die nach Ungarn ziehen möchten, einen entsprechenden Service zu bieten.
              </Typography>
              <Typography mt={3} fontWeight="bold">
                Lido Home arbeitet mit mehreren englisch- und deutschsprachigen Kollegen zusammen, die allen Kunden gern mit Rat und Tat zur Verfügung stehen, die kein Ungarisch sprechen können. Unsere Firma präsentiert die in unserem Angebot befindlichen Immobilien auf mehreren ausländischen Werbeportalen, unter anderem auf der deutschen Webseite „Immowelt.de“.              </Typography>
              <Button
                component={MuiLink}
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.immowelt.de/profil/59ed7059ad3044ebb1dd17956a2f38b5"
                sx={{
                  mt: 5,
                  px: 4,
                  py: 1,
                  fontSize: 16,
                }}
                color="primary"
                variant="contained"
                size="medium"
              >
                Erfahren Sie mehr!
              </Button>
            </Grid>
          </Grid>
        </ParallaxSection>
      </Box>
    </>
  );
};

export default Home;
