/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MapContainer, TileLayer, Marker, Popup, useMap, FeatureGroup, AttributionControl, Circle, Polygon } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
/* eslint-disable-next-line */
import L, { DivIcon } from 'leaflet';
// import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import lidomarker from '../images/lidomarker.png';
import { useDispatch } from '../app/store';
import { setInitialShapesValue } from '../app/slices/property';
// import '../styles/styles.scss';

const DefaultIcon = L.icon({
  // iconUrl: icon,
  iconUrl: lidomarker,
  shadowUrl: iconShadow,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
});

L.Marker.prototype.options.icon = DefaultIcon;

L.drawLocal = {
  draw: {
    toolbar: {
      // #TODO: this should be reorganized where actions are nested in actions
      // ex: actions.undo  or actions.cancel
      actions: {
        title: 'Elvetés',
        text: 'Forma elvetése, mentés nélkül'
      },
      finish: {
        title: 'Befejezés',
        text: 'Rajzolás befejezése, utolsó és első pont összekötése'
      },
      undo: {
        title: 'Utolsó pont törlése',
        text: 'Utolsó pont törlése'
      },
      buttons: {
        polyline: '- your text-',
        polygon: 'polygon',
        rectangle: '- your text-',
        circle: 'Kör',
        marker: '- your text-',
        circlemarker: '- your text-'
      }
    },
    handlers: {
      circle: {
        tooltip: {
          start: 'Kör',
          cont: 'Engedd el az egeret hogy befejezd a rajzolást'
        },
        radius: 'Távolság'
      },
      circlemarker: {
        tooltip: {
          start: '- your text-.'
        }
      },
      marker: {
        tooltip: {
          start: 'Helyezd el a markert'
        }
      },
      polygon: {
        tooltip: {
          start: 'Kezdet.',
          cont: 'Folytatás',
          end: 'Vég'
        }
      },
      polyline: {
        error: '<strong>Error:</strong> shape edges cannot cross!',
        tooltip: {
          start: 'Click to start drawing line.',
          cont: 'Click to continue drawing line.',
          end: 'Click last point to finish line.'
        }
      },
      rectangle: {
        tooltip: {
          start: '- your text-.'
        }
      },
      simpleshape: {
        tooltip: {
          end: 'Engedd el az egeret hogy befejezd a rajzolást'
        }
      }
    }
  },
  edit: {
    toolbar: {
      actions: {
        save: {
          title: 'Változtatások mentése',
          text: 'Mentés'
        },
        cancel: {
          title: 'Változtatások eldobása mentés nélkül',
          text: 'Mégsem'
        },
        clearAll: {
          title: 'Minden forma törlése',
          text: 'Minden törlése'
        }
      },
      buttons: {
        edit: 'Módosítás',
        editDisabled: 'Nincs mit módosítani',
        remove: 'Törlés',
        removeDisabled: 'Nincs mit törölni'
      }
    },
    handlers: {
      edit: {
        tooltip: {
          text: 'Editálj',
          subtext: 'Kattints mégsére hogy elvetesd a változtatásokat'
        }
      },
      remove: {
        tooltip: {
          text: 'Kattints a formára a törléséhez'
        }
      }
    }
  }
};

// eslint-disable-next-line react/prop-types
const ChangeView = ({ center, initialZoom }) => {
  const map = useMap();
  // map.setView(center, initialZoom);
  return null;
};

const MapTest = (props) => {
  const { center, marker, label, initialZoom, drawingEnabled, response, shapes, setShapes, setResponse, newShapes, setNewShapes, isNewCreated, setIsNewCrated, pathname } = props;
  const [addedShapes, setAddedShapes] = useState([]);
  const dispatch = useDispatch();
  let genShape = shapes.length > 0 ? L.circle(shapes[0][1], shapes[0][0], { radius: shapes[1], color: '#01579b' }) : null;
  const editRef = useRef();
  const arr = [];
  const [polygonShapePositions, setPolygonShapePositions] = useState([]);
  const checkValue = (array) => {
    if (array.includes('-')) {
      return [0, 0];
    }
    return array;
  };

  const handlePolyShape = () => {
    const resultShapesArray = [];
    shapes?.map((shape) => resultShapesArray.push([shape[1], shape[0]]));
    setPolygonShapePositions(resultShapesArray);
  };

  useEffect(() => {
    if (shapes && !isNewCreated && shapes.length >= 3) {
      handlePolyShape();
    }
    return () => {
      setPolygonShapePositions([]);
    };
  }, []);

  const onCreated = (e) => {
    setIsNewCrated(true);

    if (genShape) {
      genShape.remove();
    }

    genShape = e.layer;

    if (e.layer?._latlngs) {
      const polygon = e.layer._latlngs[0];
      const polygonSend = [];
      polygon.forEach((elem) => {
        const point = [elem.lng, elem.lat];
        polygonSend.push(point);
      });
      polygonSend.push(polygonSend[0]);
      setNewShapes(polygonSend);
      if (pathname && !pathname.includes('/properties')) {
        dispatch(setInitialShapesValue(polygonSend.toString()));
      } else {
        dispatch(setInitialShapesValue(null));
      }
    }

    if (e?.layer?._latlng) {
      const circle = [[e.layer._latlng.lng, e.layer._latlng.lat], e.layer._mRadius];
      setNewShapes(circle);
      if (pathname && !pathname.includes('/properties')) {
        dispatch(setInitialShapesValue(circle.toString()));
      } else {
        dispatch(setInitialShapesValue(null));
      }
    }
  };

  const onEdited = (e) => {
    const polycircles = Object.values(e.layers._layers);
    const polycircle = polycircles[0];

    if (polycircle?._latlngs) {
      const polygon = polycircle._latlngs[0];
      const polygonSend = [];
      polygon.forEach((elem) => {
        const point = [elem.lng, elem.lat];
        polygonSend.push(point);
      });
      polygonSend.push(polygonSend[0]);
      if (!isNewCreated && shapes.length > 0) {
        setShapes(polygonSend);
      }
      setNewShapes(polygonSend);
      if (pathname && !pathname.includes('/properties')) {
        dispatch(setInitialShapesValue(polygonSend.toString()));
      } else {
        dispatch(setInitialShapesValue(null));
      }
    }

    if (polycircle?._latlng) {
      const circle = [[polycircle._latlng.lng, polycircle._latlng.lat], polycircle._mRadius];
      if (!isNewCreated && shapes.length > 0) {
        setShapes(circle);
      }
      setNewShapes(circle);
    }
  };

  return (
    <>
      <MapContainer
        attributionControl={false}
        center={checkValue(center)}
        zoom={initialZoom || 10}
        minZoom={6}
        maxZoom={18}
        maxBounds={[
          [45, 13],
          [49, 25]
        ]}
        scrollWheelZoom // ={drawingEnabled && false}
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <ChangeView
          center={checkValue(center)}
          zoom={initialZoom}
        />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <AttributionControl
          position="bottomright"
          prefix={false}
        />
        <FeatureGroup
          ref={editRef}
        >
          {drawingEnabled
            && (
              <EditControl
                position="topright"
                onCreated={onCreated}
                onEdited={onEdited}
                onDeleted={() => {
                  setNewShapes([]);
                  setShapes([]);
                  dispatch(setInitialShapesValue(null));
                }}
                draw={{
                  rectangle: false,
                  polyline: false,
                  circle: {
                    shapeOptions: {
                      color: '#01579b'
                    }
                  },
                  circlemarker: false,
                  marker: false,
                  // marker: {
                  //   icon: DefaultIcon,
                  // },
                  polygon: {
                    shapeOptions: {
                      color: '#01579b'
                    },
                    // color: 'red',
                    allowIntersection: false, // Restricts shapes to simple polygons
                    drawError: {
                      color: 'red', // Color the shape will turn when intersects
                      message: '<strong>Ne húzd a vonalakat át egymáson!<strong>' // Message that will show when intersect
                    },
                  },
                }}
              />
            )}
          {shapes && !isNewCreated && shapes.length < 3 && shapes.length > 0 && (
            <Circle
              center={{ lat: shapes[0][1], lng: shapes[0][0] }}
              color="#01579b"
              radius={shapes[1]}
            />
          )}
          {shapes && !isNewCreated && shapes.length >= 3 && polygonShapePositions.length > 0 && (
            <Polygon
              color="#01579b"
              positions={polygonShapePositions}
            />
          )}
        </FeatureGroup>
      </MapContainer>
    </>
  );
};

MapTest.propTypes = {
  label: PropTypes.string,
  marker: PropTypes.array,
  center: PropTypes.array,
  drawingEnabled: PropTypes.any,
  initialZoom: PropTypes.number,
  response: PropTypes.array,
  shapes: PropTypes.array,
  setShapes: PropTypes.func,
  setResponse: PropTypes.func,
  newShapes: PropTypes.array,
  setNewShapes: PropTypes.func,
  isNewCreated: PropTypes.bool,
  setIsNewCrated: PropTypes.func,
  pathname: PropTypes.any
};

export default MapTest;
