import { Grid, Typography } from '@mui/material';
import React from 'react';
import StyledRegularText from './StyledRegularText';
import PropTypes from 'prop-types';

const DidYouKnow = ({ text1, text2 }) => (
  <Grid
    item
    my={6}
    md={8}
    p={2}
    display="flex"
    justifyContent="center"
    flexDirection="column"
    alignItems="flex-start"
    sx={{
      boxShadow: 'rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px',
      borderRadius: '10px',
      backgroundColor: 'rgb(150, 47, 50, 1)',
      color: 'white',
    }}
  >
    <Typography
      variant="h5"
      sx={{
        mb: 3
      }}
    >
      {text1}
    </Typography>
    <StyledRegularText
      variant="h6"
    >
      {text2}
    </StyledRegularText>
  </Grid>
);

export default DidYouKnow;

DidYouKnow.propTypes = {
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
};
