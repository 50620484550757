import React, { useCallback } from "react";
import {
  Card,
  Grid,
  Button,
  Typography,
  Box,
  Container,
  FormControlLabel,
  Checkbox,
  Breadcrumbs,
  Link,
  FormHelperText,
} from "@mui/material";
import { Formik } from "formik";
import LidoBanner from "../utils/LidoBanner";
import * as Yup from "yup";
import partnerLogo from "../images/lidopartnerlogo.png";
import { useTranslation } from "react-i18next";
import AOCTextfield from "../utils/AOCTextfield";
import { useDispatch } from "../app/store";
import { MessagePartner } from "../app/slices/property";
import partnerHero from "../images/lhPartner/partnerHero.png";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import AOCPhoneInput from "../utils/AOCPhoneInput";
import { Link as RouterLink } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Helmet } from "react-helmet-async";

const initalValues = {
  contactName: "",
  contactEmail: "",
  contactPhone: "",
  contactCity: "",
  message: "",
  termsConditionsAccepted: true, // NOTE: do not need
  dataManagementAccepted: false,
  dataTransferAccepted: true, // NOTE: do not need
};

const PartnerForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmitForm = (values, newToken) => {
    const sendData = {
      ...values,
      googleVerificationToken: newToken,
    };
    dispatch(MessagePartner(sendData, "partner"));
  };

  const handleReCaptchaVerify = useCallback(
    async (values) => {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }

      const newToken = await executeRecaptcha("yourAction");
      // Do whatever you want with the token
      handleSubmitForm(values, newToken);
    },
    [executeRecaptcha]
  );

  return (
    <Grid mb={5}>
      <Helmet>
        <title>LidoHome | Partnerprogram</title>
      </Helmet>
      <LidoBanner image={partnerHero} height={375} text="Lido partner" />
      <Container maxWidth="md">
        <Box sx={{ my: 5, ml: 2 }}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<ChevronRightIcon fontSize="small" />}
            sx={{ mt: 1 }}
          >
            <Link
              component={RouterLink}
              to="/other/partner"
              sx={{ textDecoration: "none" }}
            >
              <Typography
                variant="h7"
                sx={{ textTransform: "uppercase", color: "#D9D9D9" }}
              >
                Lido partner
              </Typography>
            </Link>
            <Typography
              color="primary"
              variant="subtitle2"
              sx={{ textTransform: "uppercase", textDecoration: "underline" }}
            >
              LIDO HOME partnerprogram
            </Typography>
          </Breadcrumbs>
        </Box>
        <Card>
          <Formik
            initialValues={initalValues}
            validationSchema={Yup.object().shape({
              contactName: Yup.string()
                .min(6, "Legalább 6 karakter hosszúnak kell lennie")
                .required("Mező kitöltése kötelező"),
              contactCity: Yup.string().required("Mező kitöltése kötelező"),
              contactPhone: Yup.string().required("Mező kitöltése kötelező"),
              contactEmail: Yup.string()
                .required("Mező kitöltése kötelező")
                .email("Az e-mail cím formátuma nem megfelelő"),
              message: Yup.string()
                .min(10, "Legalább 10 karakter hosszúnak kell lennie")
                .required("Mező kitöltése kötelező"),
              dataManagementAccepted: Yup.boolean().oneOf(
                [true],
                "A mező kitöltése kötelező"
              ),
            })}
            onSubmit={async (values) => {
              handleReCaptchaVerify(values);
            }}
          >
            {({
              errors,
              handleBlur,
              setFieldValue,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mt={5} display="flex" justifyContent="center">
                  <img
                    style={{ width: "300px" }}
                    src={partnerLogo}
                    alt="partnerLogo"
                  />
                </Box>
                <Grid container justifyContent="space-between" p={5}>
                  <Grid
                    item
                    md={12}
                    display="flex"
                    flexDirection="column"
                    mb={5}
                  >
                    <Typography variant="h6" color="primary">
                      {t("partnerForm.1")}
                    </Typography>
                    <Typography>{t("partnerForm.2")}</Typography>
                  </Grid>
                  <Grid item md={12} xs={12} p={2}>
                    <AOCTextfield
                      onBlur={handleBlur}
                      onChange={handleChange}
                      values={values}
                      touched={touched}
                      errors={errors}
                      name="contactName"
                      label={t("partnerForm.3")}
                    />
                  </Grid>
                  <Grid item md={12} xs={12} p={2}>
                    <AOCTextfield
                      onBlur={handleBlur}
                      onChange={handleChange}
                      values={values}
                      touched={touched}
                      errors={errors}
                      name="contactCity"
                      label={t("partnerForm.5")}
                    />
                  </Grid>
                  <Grid item md={12} xs={12} p={2}>
                    <AOCPhoneInput
                      values={values}
                      touched={touched}
                      errors={errors}
                      name="contactPhone"
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item md={12} xs={12} p={2}>
                    <AOCTextfield
                      onBlur={handleBlur}
                      onChange={handleChange}
                      values={values}
                      touched={touched}
                      errors={errors}
                      name="contactEmail"
                      label={t("partnerForm.7")}
                    />
                  </Grid>
                  <Grid item md={12} xs={12} p={2}>
                    <AOCTextfield
                      rows={5}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      values={values}
                      touched={touched}
                      multiline
                      errors={errors}
                      name="message"
                      label={t("partnerForm.8")}
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    p={2}
                    display="flex"
                    flexDirection="column"
                  >
                    {/* <FormControlLabel
                      label={t('footer.checkTermsAndConditions')}
                      sx={{ color: 'black' }}
                      control={(
                        <Checkbox
                          color="primary"
                          name="termsConditionsAccepted"
                          onChange={handleChange}
                          sx={{ color: 'black' }}
                        />
                      )}
                    /> */}
                    <FormControlLabel
                      label={t("footer.checkDataManagement")}
                      sx={{ color: "black" }}
                      control={
                        <Checkbox
                          color="primary"
                          name="dataManagementAccepted"
                          onChange={handleChange}
                          sx={{ color: "black" }}
                        />
                      }
                    />
                    {errors.dataManagementAccepted &&
                      touched.dataManagementAccepted && (
                        <Box sx={{ mt: 3 }}>
                          <FormHelperText error>
                            {errors.dataManagementAccepted}
                          </FormHelperText>
                        </Box>
                      )}
                    {/* <FormControlLabel
                      label={t('footer.checkDataTransfer')}
                      sx={{ color: 'black' }}
                      control={(
                        <Checkbox
                          color="primary"
                          name="dataTransferAccepted"
                          onChange={handleChange}
                          sx={{ color: 'black' }}
                        />
                      )}
                    /> */}
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Button variant="contained" type="submit">
                      {t("send")}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Card>
      </Container>
    </Grid>
  );
};

export default PartnerForm;
