import { format, toDate } from 'date-fns';
import huLocale from 'date-fns/locale/hu';
import ref12 from '../images/references/moderna2.jpg';
import ref13 from '../images/references/moderna3.jpg';
import ref14 from '../images/references/moderna4.jpg';
import ref15 from '../images/references/moderna5.jpg';
import modernaParallax from '../images/references/modernaParallax.jpg';
import ref21 from '../images/references/szabadi1.png';
import ref22 from '../images/references/szabadi2.jpg';
import ref23 from '../images/references/szabadi3.jpg';
import ref24 from '../images/references/szabadi4.jpg';
import ref25 from '../images/references/szabadi5.jpg';
import szabadiParallax from '../images/references/szabadiParallax.png';
import ref31 from '../images/references/millgarden1.jpg';
import ref32 from '../images/references/millgarden2.jpg';
import ref33 from '../images/references/millgarden3.jpg';
import ref34 from '../images/references/millgarden4.jpg';
import ref35 from '../images/references/millgarden5.jpg';
import millgardenParallax from '../images/references/millgardenParallax.jpg';
import ref41 from '../images/references/romaihaz1.jpg';
import ref42 from '../images/references/romaihaz2.jpg';
import ref43 from '../images/references/romaihaz3.jpg';
import ref44 from '../images/references/romaihaz4.jpg';
import ref45 from '../images/references/romaihaz5.jpg';
import romaihazParallax from '../images/references/romaihazParallax.jpg';
import ref51 from '../images/references/prova1.jpg';
import ref52 from '../images/references/prova2.jpg';
import ref53 from '../images/references/prova3.jpg';
import ref54 from '../images/references/prova4.jpg';
import ref55 from '../images/references/prova5.jpg';
import provaParallax from '../images/references/provaParallax.jpg';
import ref61 from '../images/references/fenyves1.jpg';
import ref62 from '../images/references/fenyves2.jpg';
import ref63 from '../images/references/fenyves3.jpg';
import ref64 from '../images/references/fenyves4.jpg';
import ref65 from '../images/references/fenyves5.jpg';
import fenyvesParallax from '../images/references/fenyvesParallax.jpg';
import ref71 from '../images/references/siogarden1.jpg';
import ref73 from '../images/references/siogarden3.jpg';
import ref74 from '../images/references/siogarden4.jpg';
import ref75 from '../images/references/siogarden5.jpg';
import ref76 from '../images/references/siogarden6.jpg';
import siogardenParallax from '../images/references/siogardenParallax.jpg';
import ref81 from '../images/references/villan1.png';
import ref82 from '../images/references/villan2.png';
import ref83 from '../images/references/villan3.jpg';
import ref84 from '../images/references/villan4.jpg';
import ref85 from '../images/references/villan5.png';
import villanParallax from '../images/references/villanParallax.png';
import ref91 from '../images/references/vinceller1.jpg';
import ref92 from '../images/references/vinceller2.jpg';
import ref93 from '../images/references/vinceller3.jpg';
import ref94 from '../images/references/vinceller4.jpg';
import vincellerParallax from '../images/references/vincellerParallax.jpg';

export const findMyFolder = (folder, action) => {
  folder.folders.forEach((subFolder) => {
    if (subFolder.id === action.payload.id) {
      return subFolder.folders.push(action.payload.data);
    }
    if (subFolder.folders.length > 0) {
      findMyFolder(subFolder, action);
    }
    return null;
  });
};

export const refData = [
  {
    parallax: modernaParallax,
    image: ref15,
    image1: ref12,
    image2: ref13,
    image3: ref14,
    image4: ref15,
    cardWidth: 12,
    title: 'Moderna Beach Club',
    subTitle: 'Balatonboglár'
  },
  {
    parallax: szabadiParallax,
    image: ref21,
    image1: ref22,
    image2: ref23,
    image3: ref24,
    image4: ref25,
    cardWidth: 4,
    title: 'Szabadi Beach House',
    subTitle: 'Siófok'
  },
  {
    parallax: millgardenParallax,
    image: ref35,
    image1: ref32,
    image2: ref33,
    image3: ref34,
    image4: ref31,
    cardWidth: 8,
    title: 'The Mill Garden',
    subTitle: 'Balatonboglár'
  },
  {
    parallax: romaihazParallax,
    image: ref42,
    image1: ref41,
    image2: ref43,
    image3: ref44,
    image4: ref45,
    cardWidth: 6,
    title: 'Római ház',
    subTitle: 'Siófok'
  },
  {
    parallax: provaParallax,
    image: ref52,
    image1: ref51,
    image2: ref53,
    image3: ref54,
    image4: ref55,
    cardWidth: 6,
    title: 'Provance Apartmanok',
    subTitle: 'Fonyód'
  },
  {
    parallax: fenyvesParallax,
    image: ref62,
    image1: ref61,
    image2: ref63,
    image3: ref64,
    image4: ref65,
    cardWidth: 8,
    title: 'Fenyves I -II.',
    subTitle: 'Balatonfenyves'
  },
  {
    parallax: siogardenParallax,
    image: ref71,
    image1: ref76,
    image2: ref73,
    image3: ref74,
    image4: ref75,
    cardWidth: 4,
    title: 'Sió Garden',
    subTitle: 'Siófok'
  },
  {
    parallax: villanParallax,
    image: ref81,
    image1: ref82,
    image2: ref83,
    image3: ref84,
    image4: ref85,
    cardWidth: 5,
    title: 'Villa Novecento',
    subTitle: 'Siófok'
  },
  {
    parallax: vincellerParallax,
    image: ref91,
    image1: ref92,
    image2: ref93,
    image3: ref94,
    image4: vincellerParallax,
    cardWidth: 7,
    title: 'Vincellér Ház',
    subTitle: 'Balatonlelle'
  },
];

export const hintStates = [
  { label: 'Elfogadva', id: 1 },
  { label: 'Létrehozva', id: 2 },
  { label: 'Kiosztva', id: 3 },
  { label: 'Feldolgozás alatt', id: 4 },
  { label: 'Behozva', id: 5 },
  { label: 'Eladva', id: 6 },
  { label: 'Kifizetve', id: 7 },
  { label: 'Elutasítva', id: 8 },
];

// export const getHintState = (hint) => {
//   hintStates.map((state) => {
//     if (hint === state.id) {
//       console.log(state.label, hint);
//       return state;
//     }
//     return 'Nincs megadva';
//   });
// };

export const customerTypeOption = (index) => {
  switch (index) {
    case 1:
      return 'Természetes személy';
    case 2:
      return 'Jogi személy';
    case 3:
      return 'Meghatalmazott';
    default:
      return 'Nincs megadva';
  }
};

export const suggestionType = (index) => {
  switch (index) {
    case 1:
      return 'Ingatlan';
    case 2:
      return 'Kereső ügyfél';
    default:
      return 'Nincs megadva';
  }
};

export const callResultPretty = (result) => {
  switch (result) {
    case 'SIKERES':
      return 'Sikeres';
    case 'VISSZAHIVASTKER':
      return 'Vissza hívást kér';
    case 'NEMVETTEFEL':
      return 'Nem vette fel';
    case 'ELUTASITOTT':
      return 'Elutatsított';
    case 'HIBASTELSZAM':
      return 'Hibás telefon szám';
    case 'INGATLANOS':
      return 'Ingatlanos';
    case 'MARBENTVAN':
      return 'Már bent van';
    case 'MASIKIRODANALVAN':
      return 'Másik irodánál van';
    case 'MEGGONDOLKODIK':
      return 'Még gondolkodik';
    case 'EGYEB':
      return 'Egyéb';
    case 'ELADVA':
      return 'Eladva';
    default:
      return 'Nincs megadva';
  }
};

export const statusOptions = [
  {
    label: 'Kiválasztás alatt',
    value: 'pending',
    status: 1
  },
  {
    label: 'Felvéve',
    value: 'approved',
    status: 2
  },
  {
    label: 'Elutasítva',
    value: 'declined',
    status: 3
  },
];

export const randomID = () => {
  const randID = Math.floor(Math.random() * 10000000);
  return randID;
};

export const isDividable = (number, divNumber) => {
  const res = (number % divNumber === 0);
  return res;
};

export const dateFormatter = (date, formatting = 'yyyy.MM.dd HH:mm') => {
  if (date) {
    const ret = (format(toDate(date), formatting, { locale: huLocale }));
    return ret;
  }
  return null;
};

export const selectedEmployeeSelector = async (state, id) => {
  const { employees } = state.employee;
  if (id) {
    return employees.data.find((_employee) => _employee.id === id);
  }

  return null;
  // const { selectedEmployee } = state.employee;
  // console.log(selectedEmployee);
  // return selectedEmployee;
};
export const selectedClientSelector = (state, id) => {
  const { clients } = state.client;
  // console.log('sliceemp', clients);
  if (id) {
    return clients.data.find((_client) => _client.id === id);
  }
  return null;
};
export const toBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

/* eslint-disable-next-line */
export const convertEmptyStringsToNull = (data) => {
  return data = Object.keys(data).reduce((acc, key) => {
    acc[key] = data[key] === '' ? null : data[key]; return acc;
  }, {});
};

// export const isOfficeManager = (roles) => (roles.includes('ROLE_OFFICE_MANAGER') && !roles.includes('ROLE_COMPANY_MANAGER')); /* COMPANY_MANAGER has also OFFICE_MANAGER role as well, therefore it should check it. */

// export const isOfficeManagerOrAbove = (roles) => (roles.includes('ROLE_OFFICE_MANAGER')); /* COMPANY_MANAGER has also OFFICE_MANAGER role as well, therefore it should check it. */

// export const isOfficeUser = (roles) => (roles.includes('ROLE_OFFICE_USER') && !roles.includes('ROLE_OFFICE_MANAGER'));

// // export const isMentore = (roles) => (roles.includes('ROLE_OFFICE_MENTOR'));

// export const isCompanyManager = (roles) => (roles.includes('ROLE_COMPANY_MANAGER'));

// export const canIApproveProperty = (permissions) => (permissions.includes('PRIVILEGE_PROPERTY_ENDORSE'));

// export const canIArchiveProperty = (permissions) => (permissions.includes('PRIVILEGE_PROPERTY_ARCHIVE'));

export const getTransactionTypeById = (id) => {
  if (id) {
    if (id === 1) {
      return {
        id: 1,
        label: 'Eladó'
      };
    }
    if (id === 2) {
      return {
        id: 1,
        label: 'Kiadó'
      };
    }
  }
  return null;
};

export const transactionTypeOptions = () => [
  {
    id: 1,
    label: 'Eladó'
  },
  {
    id: 2,
    label: 'Kiadó'
  }
];

export const checkBoxTranslate = (value) => {
  if (value) {
    return true;
  }
  return false;
};
