import { Box } from '@mui/material';
import React, { useRef, useEffect } from 'react';
import { switchScrollLocation } from '../app/slices/property';
import { useDispatch, useSelector } from '../app/store';
import PropTypes from 'prop-types';

const ScrollHere = ({ scrollName }) => {
  const { scrollLocations } = useSelector((state) => state.property);
  const topRef = useRef(null);
  const dispatch = useDispatch();
  const forceScroll = () => {
    if (topRef !== null) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  // This component scrolls where it is imported when in the modals slice the scrollTotTop() function is called.

  useEffect(() => {
    if (scrollLocations.includes(scrollName)) {
      forceScroll();
      dispatch(switchScrollLocation(scrollName, false));
    }
  }, [scrollLocations]);

  return (
    <Box
      ref={topRef}
    />
  );
};

export default ScrollHere;

ScrollHere.propTypes = {
  scrollName: PropTypes.string.isRequired,
};
