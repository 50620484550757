import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  homeNavVisibility: false,
};

const slice = createSlice({
  name: 'helperSlice',
  initialState,
  reducers: {
    handleHomeNavVisibility(state, action) {
      state.homeNavVisibility = action.payload;
    },
  }
});

export const { reducer } = slice;

/* GET Axios request template */
// export const getOffices = (filters) => async (dispatch) => {
//   await axiosConfig.get(
//     '/offices'
//   ).then((response) => {
//     // handle success
//     dispatch(slice.actions.getOffices(response.data.data, filters));
//   }).catch((error) => {
//     // handle error
//     toast.error('Az irodák betöltése sikertelen');
//     console.error(error);
//   }).then(() => {
//     // always executed
//   });
// };
/* GET Axios request template */

export const handleHomeNavVisibility = (bool) => (dispatch) => {
  console.log('bool', bool);
  dispatch(slice.actions.handleHomeNavVisibility(bool));
};

export default slice;
