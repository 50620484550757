import { createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import axiosConfig from '../../axiosConfig';

const initialState = {
  blogCategories: {},
  blogs: {},
  years: [],
  categoryFilter: null,
  yearFilter: null
};

const slice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    getBlogCategories(state, action) {
      state.blogCategories = action.payload;
    },
    getBlogs(state, action) {
      state.blogs = action.payload;
    },
    getBlogById(state, action) {
      state.selectedBlog = action.payload;
    },
    setCategoryFilter(state, action) {
      state.categoryFilter = action.payload;
    },
    setYearFilter(state, action) {
      state.yearFilter = action.payload;
    },
    getYears(state, action) {
      state.years = action.payload;
    }
  }
});

export const { reducer } = slice;

export const getBlogCategories = () => async (dispatch) => {
  await axiosConfig.get(
    '/blog/categories'
  ).then((response) => {
    dispatch(slice.actions.getBlogCategories(response.data));
  }).catch((error) => {
    toast.error('A blog katergóriák lekérése sikertelen');
    console.error(error);
  });
};

export const getBlogs = (categoryId, year, pageNumber) => async (dispatch) => {
  const pageSize = 9;
  const paginationFilter = `?pageSize=${pageSize}&pageNumber=${Number(pageNumber)}`;

  let filter = { isPublic: true };
  if (categoryId) filter = { ...filter, categoryId };
  if (year) filter = { ...filter, year };

  await axiosConfig.post(`/blog/articles/search${paginationFilter}`, filter)
    .then((response) => {
      dispatch(slice.actions.getBlogs(response.data));
    }).catch((error) => {
      toast.error('A blog posztok lekérése sikertelen');
      console.error(error);
    });
};

export const getYears = () => async (dispatch) => {
  await axiosConfig.get('/blog/articles/years')
    .then((response) => {
      dispatch(slice.actions.getYears(response.data));
    }).catch((error) => {
      toast.error('Az évek lekérése sikertelen');
      console.error(error);
    });
};

export const setCategoryFilter = (categoryId) => async (dispatch) => {
  dispatch(slice.actions.setCategoryFilter(categoryId));
};

export const setYearFilter = (year) => async (dispatch) => {
  dispatch(slice.actions.setYearFilter(year));
};

export default slice;
